import ApplePayIcon from "assets/icons/ApplePayIcon";
import CardIcon from "assets/icons/CardIcon";
import { useTranslation } from "react-i18next";
import GooglePayIcon from "assets/icons/GooglePayIcon";
import React from "react";
import BitCoinIcon from "assets/icons/BitCoinIcon";
import CryptosIcon from "assets/icons/CryptosIcon";

function SelectPaymentMethod({
  handleClose,
  changePaymentType,
  availablePaymentOptions = [],
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="flex flex-col w-full bg-white rounded-t-2xl">
        <div className="w-[90%] mx-auto pt-5" />
        <div className="w-full border-[#d5d5d5] pb-8">
          <div className="mx-auto w-[90%] flex justify-between items-center">
            <p className="text-black-500 font-[600] text-lg">
              {t("paymentMethod")}
            </p>
            <button onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.15"
                height="13.15"
                viewBox="0 0 13.15 13.15"
              >
                <path
                  id="close"
                  d="M12,13.4,7.1,18.3a.99.99,0,0,1-1.4-1.4L10.6,12,5.7,7.1A.99.99,0,0,1,7.1,5.7L12,10.6l4.9-4.9a.99.99,0,0,1,1.4,1.4L13.4,12l4.9,4.9a.99.99,0,0,1-1.4,1.4Z"
                  transform="translate(-5.425 -5.425)"
                  fill="#3f2c65"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="w-[90%] mx-auto pb-8 flex flex-col gap-4">
          {availablePaymentOptions.includes("apple") && (
            <div
              className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-sm"
              style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
            >
              <button
                className="h-[30px]"
                onClick={() => {
                  changePaymentType("apple");
                }}
              >
                <ApplePayIcon />
                <p>Apple Pay</p>
              </button>
            </div>
          )}
          {availablePaymentOptions.includes("gpay") && (
            <div
              className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-sm"
              style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
            >
              <button
                className="h-[30px]"
                onClick={() => {
                  changePaymentType("gpay");
                }}
              >
                <GooglePayIcon />
                <p>Google Pay</p>
              </button>
            </div>
          )}

          {availablePaymentOptions.includes("card") && (
            <div
              className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-sm"
              style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
            >
              <button
                className="h-[30px]"
                onClick={() => {
                  changePaymentType("card");
                }}
              >
                <CardIcon />
                <p>{t("description.payzePayWithCard")}</p>
              </button>
            </div>
          )}

          {availablePaymentOptions.includes("binance") && (
            <div
              className="flex w-full p-5 rounded-2xl [&_svg]:min-w-[40px] [&_button]:inline-flex [&_button]:items-center [&_button]:w-full [&_p]:ml-4 [&_p]:text-black-500 [&_p]:text-sm"
              style={{ backgroundColor: "rgba(116, 116, 128, 0.08)" }}
            >
              <button
                className="h-[30px]"
                onClick={() => {
                  changePaymentType("binance");
                }}
              >
                <div className="flex justify-between w-[100%]">
                  <div className="flex  items-center">
                    <BitCoinIcon width={"45px"} height={"40px"} />
                    <p className="text-left ">
                      <span
                        className="text-[#000000] text-[16px]"
                        style={{
                          fontFamily: "sf-pro-rounded",
                          fontWeight: 400,
                        }}
                      >
                        Crypto
                      </span>
                      <br />
                      <span
                        className="text-[12px]  text-[#170738ab] "
                        style={{
                          fontFamily: "sf-pro-rounded",
                        }}
                      >
                        Powerd by CityPay.io
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center gap-[20px]">
                    <CryptosIcon width={"90"} />
                  </div>
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SelectPaymentMethod;
