import JSEncrypt from "jsencrypt";

export const encrypt = (data, options = {}) => {
  const { shouldEncode = false, stringify = true } = options;
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
  const encryptedData = encrypt.encrypt(
    stringify ? JSON.stringify(data) : data
  );
  return shouldEncode ? encodeURIComponent(encryptedData) : encryptedData;
};

export const decrypt = (encryptedData, options = {}) => {
  const { shouldDecode = false } = options;
  const encrypt = new JSEncrypt();
  encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
  const decryptedData = JSON.parse(
    encrypt.decrypt(
      shouldDecode ? decodeURIComponent(encryptedData) : encryptedData
    )
  );
  return decryptedData;
};
