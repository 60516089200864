import React from "react";

function En() {
  return (
    <div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p className="text-center font-bold">
            TERMS AND CONDITIONS OF USING THE ELECTRONIC PLATFORM OF KEEPZ.ME
            LLC
          </p>
          <p>
            <b>1. GENERAL PROVISIONS</b>
          </p>
          <p>
            1.1. These Terms and Conditions constitute a binding legal agreement
            concluded between you (hereinafter &#8211; <b>You</b> or the{" "}
            <b>User</b>) and Keepz.Me LLC (a Company organized under the laws of
            Georgia with the identification code: 405618915, the registration
            number of the Payment Sytem Provider - 0099 &#8211; 7704,
            registration date &#8211; 22 March, 2024) (the <b>Company</b>) (the{" "}
            <b>Terms and Conditions</b>), which sets forth and regulates Terms
            and Conditions for using the Company web page and application (the{" "}
            <b>Platform</b>)
          </p>
          <p>
            1.2. Accepting the Terms and Conditions remotely and electronically
            and/or accessing the Platform of the Company in any way or means,
            including registration of your account and/or receiving the service
            from the Company is and equals to your consent to the Terms and
            Conditions.
          </p>
          <p>
            1.3. The agreement to use the Company's Platform is considered
            concluded between the User and the Company by your acceptance of
            these Terms and Conditions.
          </p>
          <p>
            1.4. By expressing your consent to the Terms and Conditions, you
            confirm that you have read and understood the Terms and Conditions
            and that you comply with the terms specified in these Terms and
            Conditions, otherwise, the User must stop using the Company's
            Platform or its products/ services.
          </p>
          <p>
            1.5. When accessing, using the Company's Platform and/or purchasing
            a product/service through the Company's Platform, the version of the
            Terms and Conditions posted on the Company's Platform at the time of
            purchase shall apply. The current version of the Terms and
            Conditions is available on the Company's Platform.
          </p>
          <p>
            1.6. The Company has the right at any time unilaterally, through
            public publication/posting on the Company's platform, to make
            changes to the Terms and Conditions, without prior notice and
            obtaining additional consent from you.
          </p>
          <p>
            1.7. By continuing to use the Company's Platform after a change to
            the Terms and Conditions, including by accessing the Company's
            Platform through any means, using the Company's Platform services,
            and/or receiving products/services through the Company's Platform,
            you acknowledge and agree to the changes to the Terms and
            Conditions.
          </p>
          <p>
            1.8. The processing of your personal data, which the Company
            platform obtains from you or from third parties when you use the
            Company platform, is governed by the following link:
            <a href="https://app.keepz.me/privacy" className="text-[#0000EE]">
              privacy policy
            </a>
            (hereinafter - <b>Personal Data Processing Policy</b>) and is an
            integral part of the Terms and Conditions of the Company platform.
            By agreeing to the Terms and Conditions of the Company's platform,
            the User simultaneously agrees to the Personal Data Processing
            Policy.
          </p>
          <p>
            1.9. If the Terms and Conditions on the Company's Platform are
            available in another language in addition to the Georgian language,
            in case of inconsistency or contradiction between the texts of the
            Terms and Conditions presented in another language, the text of the
            Terms and Conditions in the Georgian language will take precedence.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            1.10. 1 Terms and/or words used in the singular in the Terms and
            Conditions shall be construed in the plural (and vice versa), unless
            the context of the Terms and Conditions itself clearly indicates
            otherwise.
          </p>
          <p>
            1.11. Male pronouns refer to female pronouns and vice versa (in the
            case of a translation of these Terms and Conditions in a language in
            which gender pronouns are used).
          </p>
          <p>
            1.12. Access to the Company's platform is free for anyone with an
            internet connection.
          </p>
          <p>
            1.13. The Company does not guarantee that the Company's Platform or
            any of its constituent parts, including the Company's Platform
            Content, as well as any products/services offered through the
            Company's Platform, will always be available to the User and will
            not be subject to any interruptions , limitations or defects.
          </p>
          <p>
            1.14. The Company shall not be liable to you in any way if the
            Company Platform, any of its constituent parts, including the
            Company Platform Content, as well as any products/services offered
            through the Company Platform become unavailable for any reason, at
            any time or for any period.
          </p>
          <p>
            1.15. The Company is entitled, without prior notice and your
            consent, to suspend, cancel, terminate and/or change the Company
            Platform or any part thereof, any feature of the Company Platform,
            the Terms and Conditions of purchase or use of any products/services
            offered through the Company Platform.
          </p>
          <p>
            1.16. The Company is entitled at any time to prohibit/restrict any
            person's access to the Company's platform, including at any time to
            limit the provision of services, block or cancel the account of any
            registered User, if the person violates the applicable laws of
            Georgia and/or the requirements of these Terms and Conditions.
          </p>
          <p>
            1.17. The Company is authorized, but not obligated, to monitor or
            check the content of messages, comments or any other information
            posted on the Company Platform or created, sent and/or received
            through the Company Platform by any person other than the Company.
          </p>
          <p>
            <b>2. DEFINITION OF TERMS</b>
          </p>
          <p>
            2.1. The terms and definitions used in the Terms and Conditions have
            the meaning set out below, unless the context of the Terms and
            Conditions clearly indicates otherwise:
          </p>
          <p>
            2.1.1. <b>The Company</b> or <b>Us</b> or the{" "}
            <b>Service Provider</b> - for the purposes of the Terms and
            Conditions, means the following legal person: Keepz Me LLC,
            registered in accordance with the laws of Georgia with an
            identification code: 405618915, address: Georgia, Tbilisi, Saburtalo
            district, Mikheil Tamarashvili avenue, N13h, office space N1, Floor
            1, Block &quot;A&quot;
          </p>
          <p>
            2.1.2. <b>Company E-Commerce Platform</b> or <b>Company Platform</b>{" "}
            &#8211; Website{" "}
            <a style={{ color: "blue" }} href="https://app.keepz.me/">
              https://app.keepz.me/
            </a>{" "}
            its subpages, mobile version and/or mobile application and all other
            websites, mobile versions of websites, mobile applications,
            software, internet portals, e-commerce platforms owned, managed,
            controlled and/or accessible by the Company and which at the same
            time refer to the present on Terms and Conditions.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.1.3. <b>Company Platform Use Agreement</b> or{" "}
            <b>Terms and Conditions</b> - these Terms and Conditions together
            with the Acquiring and/or any other type of service agreement,
            together with any other rules, conditions and/or agreements that
            establish and regulate the Terms and Conditions of access to and use
            of the Company Platform (including the terms of use of the services
            offered through the Company's platform and/or the purchase of
            products placed on the Company's platform), which these Terms and
            Conditions are deemed to be part of these Terms and Conditions by
            appropriate reference.
          </p>
          <p>
            2.1.4. <b>Product</b> - any product/service placed or offered for
            sale by the Company on the Company's platform.
          </p>
          <p>
            2.1.5. <b>User</b> - a natural person who has reached the age of 18
            who accesses the Company's platform/User of the Company's platform,
            who meets the requirements set forth in these Terms and Conditions
            and wants to use the Company's platform to use the services offered
            and/or purchase the Product placed on the Company's platform. User
            refers to both registered and non-registered Users, including
            buyers, unless the context clearly indicates otherwise.{" "}
          </p>
          <p>
            2.1.6. <b>Registered User</b> - in accordance with the Terms and
            Conditions, a User registered on the Company's platform who has a
            personal account on the Company's platform.
          </p>
          <p>
            2.1.7. <b>Unregistered User, Visitor</b> or <b>Guest</b> - a User
            who uses the Company's platform and has not been authenticated.
          </p>
          <p>
            2.1.8. <b>User Account</b> or <b>Account</b> - a personal account
            registered by a Registered User on the Company's Platform, which is
            a confidential part of the Company's Platform and access requires
            knowledge of the Registered User's corresponding e-mail address or
            telephone number and password. The account is created by the User
            after registration. The report reflects the information specified by
            the User on the Company's platform, as well as contains various
            information about the operations and actions performed by the
            registered User and the Company's platform, which is provided by the
            registered User himself or is automatically registered/displayed
            through the Company's platform (transfer history, invoices, etc.) .
          </p>
          <p>
            2.1.9. <b>Registration</b> - in accordance with the rules and
            conditions, the User's going through special procedures on the
            Company's platform, which means the User's indication of his name,
            surname, e-mail, mobile phone number and/or account password and the
            e-mail and/or mobile specified by the User on the Company's platform
            After verifying the phone number, to open the User's personal
            account on the Company's platform, which gives him the opportunity
            to pass authorization on the Company's platform.
          </p>
          <p>
            2.1.10. <b>Authorization</b> - in accordance with the rules and
            conditions, entering the User's personal account on the Company's
            platform based on the data required for authorization. Authorization
            is a function of the right of special access to the information
            resources available on the Company's platform, related to computer
            and information security and other rights and privileges, giving the
            User the right to use the Company's platform to enter his personal
            account, as well as to manage and edit his account and the data
            reflected in the account.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.1.11. <b>Agreement</b> - these Terms and Conditions regulate the
            conditions for providing the acquiring, money transfers, acess to
            the bank account and other services on the Company's platform,
            including the terms of providing the service between the Company as
            a service provider and the customer as a merchant in a remote form,
            using electronic means, and, if possible, concluding a remote
            agreement.
          </p>
          <p>
            2.1.12. <b>Content</b> - any part of the Company's platform, any
            graphic image, drawing, print, photo, image, sound, music, video
            material, audio material, text, logo, symbol, database, design,
            software, posted, displayed or available , files and any other
            items.
          </p>
          <p>
            2.1.13. <b>Password</b> - a combination of characters that a person
            creates during registration on the Company's platform, to access/use
            the personal account and to ensure its protection and security from
            other persons.
          </p>
          <p>
            2.1.14. <b>Acquiring Service Fee </b> -A fee calculated from each
            payment made. The Service Fee on each payment is not less than 1%
            and not more than 3% and the general (default) Service Fee on each
            payment is 2.5%. The User has the right to determine for themselves
            which party (the User or his client) will be charged the Service Fee
            for each transaction.
          </p>
          <p>
            <b>3. USER CONSENTS AND WARRANTIES</b>
          </p>
          <p>3.1. User warrants and represents the following:</p>
          <p>
            3.1.1. uses the Company's platform and the products/services placed
            on it, including making payments and/or receiving services only of
            his own free will, based on his free choice and considered risk,
            without coercion or pressure from the Company or other third
            parties;
          </p>
          <p>
            3.1.2. has full (unlimited) capacity, is not under the influence of
            narcotic, alcoholic, psychotropic or toxic drugs, is not under the
            influence of error, fraud, pressure, threats or any other unlawful
            influence and is not the object of violence, threats, deception,
            misrepresentation or any other prohibited action on the part of the
            Company or other third party, is fully aware of the content of the
            expression of his will, the content of the Terms and Conditions and
            the legal consequences and responsibility arising from it;
          </p>
          <p>
            3.1.3. has full authority to enter into and perform the obligations
            set forth in these Terms and Conditions, including entering into the
            Company's Platform Usage Agreement and Distance Purchase Agreement;
          </p>
          <p>
            3.1.4. Any information, data and documentation provided to the
            Company is true, fair, accurate, current, valid and complete. As
            necessary, the User will update it from time to time;
          </p>
          <p>
            3.1.5. is not engaged in or participating in any illegal activity
            under the laws of any jurisdiction (including money laundering, arms
            trafficking, terrorism or other illegal activities);
          </p>
          <p>
            3.2. Violation of the guarantees and obligations stipulated by these
            Terms and Conditions on the part of the User is sufficient grounds
            for unilaterally terminating the agreement to use the Company's
            platform, including the remote acquiring agreement, or for refusing
            (suspending) the Company's obligations under the agreement to use
            the Company's platform, including the remote acquiring agreement.
          </p>
          <p>
            <b>4. USER OBLIGATIONS</b>
          </p>
          <p>
            4.1. When accessing the Company's platform, using the Company's
            platform and/or purchasing a product through the Company's platform,
            the User shall:{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>4.1.1. follow the current legislation of Georgia;</p>
          <p>
            4.1.2. follow and fulfill the requirements provided for in the
            present rules and conditions diligently and in good faith;
          </p>
          <p>
            4.1.3. not interfere with, limit and/or hinder the use of the
            Company's platform by another person;
          </p>
          <p>
            4.1.4. not intentionally transmit and/or upload to the Company's
            platform any data/material that contains a virus, the so-called
            &quot;Trojans&quot;, spyware or any other harmful programs, code
            and/or data to adversely affect the Company, third parties, computer
            programs and devices;
          </p>
          <p>
            4.1.5. not distribute on or through the Company's platform any
            advertising or encouraging or any other form of inciting electronic
            message (spam) that is against the law and/or harms the Company or
            third parties;
          </p>
          <p>
            4.1.6. not copy, reproduce, frame, publish, download, print, place,
            broadcast, record, transmit, without the prior written consent of
            the Company , publicize, distribute, link, deep link, distribute or
            use in any form or by any means, directly or indirectly, for
            commercial and/or non-personal purposes in any way;
          </p>
          <p>
            4.1.7. not take any action on or through the Company Platform that
            infringes the copyright, patent, trademark, trade name, intellectual
            property, proprietary or other rights of the Company or any third
            party;
          </p>
          <p>
            4.1.8. not post publicly, directly or indirectly, on the Company's
            platform or any part of it (including, if any, comments, forums,
            groups) links to its or other third parties' or related
            websites/social networks with competing or similar activities of the
            Company and/or advertise it or other third parties;
          </p>
          <p>
            4.1.9. not damage, disrupt or affect in any way the proper operation
            and security of the Company's platform, its content and/or the
            network, program or system related to its operation;
          </p>
          <p>
            4.1.10. not carry out the so-called &quot;Phishing&quot;, including
            using the Company's Platform or any part thereof or not to carry out
            on or through the Company's Platform the distribution of spam,
            viruses, malicious codes or other such data that will damage or in
            any way endanger the operation and functionality of the Company's
            Platform, the Company's Platform , other Users, third parties or
            their devices, software and/or data;
          </p>
          <p>
            4.1.11. not take any action that would damage the integrity and
            security of the system, network and/or data necessary for the smooth
            functioning of the Company's platform;{" "}
          </p>
          <p>
            4.1.12. not improperly access the computer systems, networks and/or
            data of the Company's platform and its Users;
          </p>
          <p>
            4.1.13. not perform illegal or illegal actions on the Company's
            platform, through the Company's platform or in relation to it;
          </p>
          <p>
            4.1.14. immediately notify the Company of all circumstances(s) that
            may conflict with these Terms and Conditions and/or lead to a breach
            of obligations under these Terms and Conditions;
          </p>
          <p>
            4.1.15. periodically, as necessary, to update any information, data
            or documents submitted to enter into an agreement to use the
            Company's platform or based on it and perform it, including during
            User registration or when using services through it, placing an
            order on the Company's platform, accessing the Company's platform or
            purchasing a product through it.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            4.1.16. to post and/or distribute offensive, obscene,
            discriminatory, slanderous and other improper information of the
            Company or other third parties on the Company's platform, in any of
            its constituent parts or through it, in any form or by said means,
            promote and/or encourage any improper actions;
          </p>
          <p>
            4.1.17. not purposely and unfairly create clearly false
            representations, including negative comments and evaluations, of
            another User, device, or software on the Company's platform or other
            Users, or create a positive representation of yourself, including
            relevant comments or evaluations;
          </p>
          <p>
            4.1.18. in case of request from the Company, within 10 (ten) days at
            the latest, to submit a written consent (if necessary, certified in
            accordance with the law) or an electronic form of consent, with an
            electronic signature executed in the manner prescribed by the law,
            to confirm the agreement to these Terms and Conditions.
          </p>
          <p>
            <b>5. USING THE PLATFORM</b>
          </p>
          <p>
            5.1. Registration and account creation on the Company's platform is
            carried out by the following methods: on the registration page of
            the Company's platform, the User specifies his name, surname, e-mail
            address and/or mobile phone number and password. To complete the
            registration process, it is necessary to verify the User's e-mail
            address and/or mobile phone number specified on the Company's
            platform, which means checking the correctness and validity of the
            User's e-mail address and/or mobile phone number by means of a
            link/code sent to the User's e-mail address and/or mobile phone
            number . After completing the registration process for the User's
            e-mail address and/or mobile phone number, the User becomes the
            owner of the account on the Company's platform.
          </p>
          <p>
            5.2. In case the User is granted the QR code generated by the
            Company, the availability of such single QR code is subject to
            monthly payment of GEL 5. The Company is entitled to terminate the
            availability of the QR code in case the person responsible for the
            payment delays such payment by 1 (one) day.
          </p>
          <p>
            5.3. In terms of providing a number of services, the Company is
            authorized and the User agrees to provide the Company with
            additional information and identifying documentation for full
            verification.
          </p>
          <p>
            5.4. The Company is entitled to change the mandatory data required
            for User registration and/or authorization on the Company's platform
            at any time.
          </p>
          <p>
            5.5. For registration/authorization on the Company's platform,
            regardless of the User's data specified in the present rules and
            conditions, it will be mandatory to specify all the data and fulfill
            the conditions that are specified as such at the time of
            registration/authorization directly on the Company's platform.
          </p>
          <p>
            5.6. In order to ensure the security of the account and
            authorization on the Company's platform, the password specified by
            the User during registration on the Company's platform is stored
            encrypted and known only to the User.
          </p>
          <p>
            5.7. The User is obliged to ensure that the data specified during
            the registration on the Company's platform, including personal data,
            are true, accurate and complete.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            5.8. The User guarantees that the data specified during registration
            on the Company's platform are his personal data and belong directly
            to him and not to another person.{" "}
          </p>
          <p>
            5.9. The User can at any time update or make changes to the data
            indicated on the Company's platform by accessing his account. Please
            see our privacy policy for more information on how your personal
            data is collected, processed and stored.
          </p>
          <p>
            5.10. The Company advises all Users to exercise due caution and not
            to disclose their account password to other third parties. In any
            case, the User is responsible for maintaining the confidentiality of
            his account password and any actions taken through the account. The
            Company is not responsible for any damage that may occur to any
            person based on the User's disclosure of his account password. If
            the User possesses information or suspects that the security of his
            or another User's account or account password has been compromised
            or is being misused, please notify the Company immediately.
          </p>
          <p>
            5.11. The recovery of the data required for authorization on the
            Company's platform by the registered User is carried out by means of
            the information sent to the User's e-mail address and/or mobile
            phone number stored on the Company's platform or by connecting to
            the contact/communication channels specified in the Company's rules
            and conditions.
          </p>
          <p>
            5.12. The Company's platform identifies the User through universal
            identifiers, namely email, password and/or mobile phone number.
          </p>
          <p>
            <b>6. CONTENT AND RELATED RIGHTS</b>
          </p>
          <p>
            6.1. The Company has the authority to modify, create, limit or
            cancel the content of the Company Platform or any part thereof at
            any time.
          </p>
          <p>
            6.2. The Company tries to provide Users with accurate, complete and
            up-to-date content, but does not make any guarantees or promises in
            this regard.
          </p>
          <p>
            6.3. The Company will endeavor to ensure that the Company's Platform
            is free of viruses and other harmful or harmful content, however, it
            does not make any guarantees that the use of the Company's Platform
            will not result in damage to the User's computer or other technical
            equipment or loss of data.
          </p>
          <p>
            6.4. The Company shall not be liable for any damages that the User
            or other persons may suffer while accessing the Company's Platform
            as a result of a virus or other malicious or harmful content.
          </p>
          <p>
            6.5. The content of the Company's platform, including the design of
            the Company's platform, the software package, the main software
            codes, is the property of the Company or the manufacturer/supplier
            of the relevant product and/or property and is protected by
            copyright and related rights in accordance with Georgian and
            international laws.{" "}
          </p>
          <p>
            <b>7. THIRD PARTY LINKS AND SOCIAL NETWORKS</b>
          </p>
          <p>
            7.1. Other third-party websites, content, products and/or services
            outside the Company's control may be available through the Company's
            Platform through links (text links, banners, feeds, etc.),
            applications or in any other form that establish a connection with
            third-party websites, applications or / and software.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            7.2. The User accesses and uses third-party information and/or links
            solely at his/her own risk, and the Company does not assume any
            responsibility or liability for the content, accuracy, use or
            availability of such third-party information and/or websites,
            including any liability for any other related to the Company's
            platform. on the content of third-party websites and the damage that
            may occur to the User or another third party in case of their use.{" "}
          </p>
          <p>
            7.3. The responsibility for the content of messages, comments or any
            other information posted on the Company's platform or created, sent
            and/or received through the Company's platform by any person other
            than the Company, and for any actions taken in relation to this
            information, is not the responsibility of the Company, but only the
            relevant person individually and personally.
          </p>
          <p>
            7.4. The Terms and Conditions, together with the regulation of other
            matters, regulate the posting of statements, comments, evaluations,
            reviews, notes or other information by the User on the Company's
            platform or on any related website, application, software, official
            pages of the Company's Facebook, Twitter, YouTube or any other
            social network. Terms, which must be consistent with these Terms and
            Conditions.
          </p>
          <p>
            <b>8. RESPONSIBILITY</b>
          </p>
          <p>
            8.1. Violation of the obligations defined by the rules and
            conditions leads to the responsibility provided for by the same
            rules and conditions and the current legislation of Georgia.
          </p>
          <p>
            8.2. The parties undertake to compensate each other for the damage
            (losses) caused as a result of their partial or complete
            non-fulfillment of the terms or improper fulfillment in accordance
            with the law and/or rules and conditions.
          </p>
          <p>
            8.3. The Company shall not be liable to the User or any other person
            for damages arising out of, or in any way related to, the User's use
            of any link posted on the Company's Platform.
          </p>
          <p>
            8.4. The Company is not responsible for the content of any other
            website link to which it is redirected from the Company's Platform.
          </p>
          <p>
            8.5. The Company shall not be liable for any damages resulting from
            the inability to use the Company's Platform, the restriction or
            termination of access to the Company's Platform.
          </p>
          <p>
            8.6. The Company does not provide any kind of guarantee regarding
            the service, product and/or quality of the Company's platform,
            except for what is in accordance with the requirements of the
            legislation in force in Georgia.
          </p>
          <p>
            8.7. The Company is not responsible for the accuracy and validity of
            the information entered by the User during registration.
          </p>
          <p>
            8.8. The Company uses all reasonable measures to protect the
            security of User data, however, it is not responsible for cases
            where third parties unlawfully gain unauthorized access to the data
            of other Users.
          </p>
          <p>
            <b>9. ADDITIONAL TERMS AND CONDITIONS FOR ACQUIRING AGREEMENT</b>
          </p>
          <p>
            {" "}
            9.1. The present additional contractual terms and conditions
            regulate the existing contractual relations between Keepz.me LLC and
            the users, are a constituent part of the agreements concluded
            between the parties and determine the additional contractual terms
            of the parties.
          </p>
          <p>
            {" "}
            9.2. The regulatory provisions contained in this document are common
            to all agreements to which this document applies.
          </p>
          <p>
            {" "}
            9.3. In the event of a change in the personal or any other
            information (including brand name and/or other personal/business
            data, contact information and data, etc.) about the user,
            available/recorded with the Service Provider, the customer is
            obliged to notify the Service Provider in writing of the said
            change(s). The Service Provider is entitled to act in accordance
            with the information stored/recorded by the User before the written
            notification of the change.
          </p>
          <p>
            {" "}
            9.4. The User cannot refuse to fulfill the terms and obligations
            provided for in this document only on the grounds that they did not
            sign (in physical and/or electronic form) directly on this document
            (on the electronic and/or physical version of this document).
          </p>
          <p>
            {" "}
            9.5. The cancellation/invalidation of any clause of the contract or
            this document between the Service Provider and the User does not
            lead to the cancellation/invalidity of the entire contract/this
            document and/or their other clause(s)
          </p>
          <p>9.6. Keepz.Me is authorized:</p>
          <p>9.6.1. to impose an Acquiring Service Fee.</p>
          <p>
            9.6.2. To use the User's brand name and trademarks for placement on
            advertising and information media and/or placement in payment
            channels, based on the prior consent of the User.
          </p>
          <p>9.7. Keepz.Me is obliged:</p>
          <p>
            9.7.1. That in order to receive the amount of the card operation,
            the relevant settlement account specified in the Acquiring Agreement
            must be permanently active;
          </p>
          <p>9.8.User is obliged:</p>
          <p>
            9.8.1. Through the Internet portal, to sell only the products
            corresponding to the business activity category assigned to the
            trade/service facility. The products sold/for sale by the
            trade/service facility through the internet portal of the
            trade/service facility must be relevant to the business activity
            category assigned to the trade/service facility and permitted by the
            current legislation;
          </p>
          <p>
            9.8.2. to immediately provide a refund to the service provider or to
            a person designated by the service provider, if: a) the amount was
            credited to the account of the trade/service facility by mistake or
            incorrectly; b) the card operation is performed in violation of the
            conditions of card use, legislation and/or the requirements of the
            bank's instructions, which includes card operations carried out by
            an unauthorized person and/or such card operations that do not
            express the will of the card owner; c) violates the obligations
            stipulated in the agreement; d) The card holder declares that he did
            not receive the product or that the product is defective.
          </p>
          <p>
            9.8.3. Within 3 (three) banking days from the oral or written
            request of the service provider, provide the following information:
            documentation/information regarding the card operation, which,
            according to the rules and legislation of the payment systems, there
            is a reasonable suspicion that may become/become the subject of a
            dispute;
          </p>
          <p>
            9.8.4. The main acquiring agreement signed between Keepz.Me and the
            user is considered to be extended each time with the same term and
            conditions, if neither party has notified the other party of the
            desire to terminate or change the essential terms of the agreement
            30 (thirty) working days before the expiration of the Acquiring
            Agreement;
          </p>
          <p>
            <b>10. GOVERNING LAW AND DISPUTES</b>{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            10.1. The Terms and Conditions are regulated and interpreted in
            accordance with the laws of Georgia.
          </p>
          <p>
            10.2. Any disputes or disagreements related to the Terms and
            Conditions or their interpretation/interpretation shall be resolved
            by negotiation between the parties.
          </p>
          <p>
            10.3. If the parties fail to reach an agreement through negotiation
            within 30 (thirty) calendar days, each party has the right to apply
            and the dispute will be considered by the general courts of Georgia.
          </p>
          <p>
            10.4. In order to settle the dispute through negotiation or judicial
            settlement, notifications, letters or other documentation of the
            parties related to the dispute will be considered delivered to the
            Company if it is delivered to the following address: Georgia,
            Tbilisi, Tbilisi, Tamarashvili St. N13t.
          </p>
          <p>
            <b>11. FORCE MAJEURE</b>
          </p>
          <p>
            11.1. Neither party shall be liable for the total or partial breach
            of the obligations under the Terms and Conditions, if the said is
            caused by force majeure (caused by force majeure circumstances)
            direct and immediate impact.
          </p>
          <p>
            11.2. For the purposes of the Terms and Conditions, circumstances of
            force majeure (circumstances of force majeure) are considered to be
            insurmountable, unforeseen and independent of the parties' control
            after the entry into force of the Terms and Conditions, such as:
            disconnection of the Company's technical systems, impossibility or
            failure of data transmission through the Company's platform,
            communication terminals or telecommunications equipment failure,
            power and/or internet service interruption, GPRS outage, cyber
            attack on Company platform, Company platform updates, Company
            contractor third party failure or suspension or interruption of
            logistics/business operations, natural disasters, war and
            hostilities, Prohibitions and restrictions imposed by government,
            state, local and/or other administrative bodies, the spread of a
            pandemic and/or epidemic or other similar circumstances.
          </p>
          <p>
            11.3. The party, for which it is impossible to fulfill the
            obligations imposed by the rules and conditions, is obliged to
            inform the other party about the occurrence of force majeure
            circumstances and its elimination within a reasonable time.
          </p>
          <p>
            11.4. The fulfillment of the obligations assumed by the parties
            under the Terms and Conditions will be postponed until the force
            majeure circumstances are eliminated.{" "}
          </p>
          <p>
            <b>12. COMMUNICATION</b>
          </p>
          <p>
            12.1. For the fulfillment of the obligations assumed by the parties
            under the Terms and Conditions or for the release or protection of
            the rights and interests granted by the same rules or the applicable
            legislation, including in connection with the issues regulated by
            the Terms and Conditions, the communication between the User and the
            Company is mainly carried out electronically, recorded by the User
            on the Company's platform and/or to the e-mail address and/or mobile
            phone number provided to the Company, by sending e-mails and/or
            short text messages, however, the Company is entitled to use the
            oral telephone connection to the corresponding mobile phone number
            of the User, to deliver &quot;push&quot; messages to the User on the
            Company's platform, to use the User's social networks chat&quot;
            correspondence and other communication channels specified and/or
            which the User agrees to use.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            12.2. Communication between the User and the Company is carried out
            in compliance with the privacy policy, taking into account the
            legitimate purposes and grounds of personal data processing.
          </p>
          <p>
            <b>13. ACCOUNT CANCELLATION AND ACCOUNT ACCESS RESTRICTION</b>{" "}
          </p>
          <p>
            13.1. The User is entitled to cancel his account on the Company's
            platform at any time. To cancel the account, the User must contact
            the Company through the contact/communication channels specified in
            the Terms and Conditions.
          </p>
          <p>
            13.2. To cancel the account, the User must go through the
            identification procedure specified by the Company and confirm that
            he owns this account.
          </p>
          <p>
            13.3. The Company has the right to unilaterally, without prior
            notice, terminate or limit the right of the User to use the
            Company's platform and/or services if it believes that the
            requirements of the rules and conditions and/or the applicable
            legislation of Georgia have been violated.
          </p>
          <p>
            13.4. In the event that the Company cancels the User's account or
            restricts its use, due to the fact that the User violated the Terms
            and Conditions and/or the applicable legislation of Georgia, the
            User is not authorized to create a new account on his own behalf or
            on behalf of another person.
          </p>
          <p>
            <b>14. OTHER CONDITIONS</b>
          </p>
          <p>
            14.1. The agreement to use the Company's platform is valid for life.{" "}
          </p>
          <p>
            14.2. The article headings mentioned in the Terms and Conditions are
            used for convenience only and do not determine and/or affect the
            content, interpretation and interpretation of the article.
          </p>
          <p>
            14.3. If any part or provision of the Terms and Conditions is
            revoked, invalidated or terminated for any reason, that part or
            provision will no longer apply, which will not affect the validity
            of the other parts/provisions of the Terms and Conditions.
          </p>
          <p>
            14.4. The User, without the written consent of the Company, is not
            authorized to transfer the rights and requirements arising from
            these Terms and Conditions or assign obligations to another person.
          </p>
          <p>
            14.5. The Company is authorized to transfer all of its rights and
            obligations under the Terms and Conditions to any person without the
            written consent of the User, provided that this third party provides
            the same or better guarantees and responsibility to the User.
          </p>
          <p>
            14.6. Before the effective date of these terms, in the acquiring
            agreement executed between the company and the user, with the
            implementation of these term, a change will be executed and the
            company will be appointed as the service provider, which represents
            a party to any contractual relationship before the effective date of
            this agreement, the company and BKN301 Georgia LLC (I/N: 405545227),
            the legall sucessor of BKN301 Georgia LLC and the latter is no
            longer a party to the agreement.
          </p>
          <p>
            14.7. Any withholding or delay by the Company from exercising any
            right or remedy under the Terms and Conditions shall not be
            construed as a waiver of such right or remedy and shall not affect
            any subsequent exercise of such right or remedy by the Company.
          </p>
        </div>
      </div>
    </div>
  );
}

export default En;
