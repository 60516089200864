import { getPendingStatus, postTransactionAction } from "api";
import PendingIcon from "assets/icons/ProccessingIcon.png";
import axios from "axios";
import { BOG_URL } from "config";
import { useQueryString } from "hooks";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "utils/encryption";

const Pending = () => {
  const { parseQuery: parsedQuery } = useQueryString();
  const {
    acquiringTransactionId,
    ratingEnabled,
    amount,
    receiverName,
    receiverId,
    receiverType,
    tipsEnabled,
    token = "",
  } = parsedQuery;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const acquiringType = localStorage.getItem("acquiring");

    if (acquiringType == "tbc") {
      const encryptedData = encrypt({
        id: acquiringTransactionId,
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/payment-service/api/external/tbc/transaction/info`,
          {
            identifier: process.env.REACT_APP_INTEGRATOR_ID,
            encryptedData: encryptedData,
          }
        )
        .then((data) => {
          console.log(data?.data, "daraa");
          const {
            data: { encryptedData },
          } = data;
          const decryptedData = decrypt(encryptedData);
          console.log(decryptedData);
          if (decryptedData?.tbcTransactionStatus == "Succeeded") {
            const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}&receiverType=${receiverType}`;
            navigate(successUrl);
          } else {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
            );
          }
        });
      return;
    }

    if (token) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/gc/transaction/info?providerId=${token}&fromMobile=false`
        )
        .then((data) => {
          if (data?.result?.status == "FAILED") {
            navigate(
              `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
            );
          } else if (data?.result?.status == "SUCCESS") {
            const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}`;
            navigate(successUrl);
          }
        });

      return;
    }
    const sessionId = localStorage.getItem("sessionId");
    if (sessionId) {
      async function handleStatusRequest() {
        const options = {
          method: "POST",
          url: `${BOG_URL}/payment/${sessionId}`,
        };

        const {
          data: { url, post = {} },
        } = await axios.request(options);
        console.log(post, "სტატუსის დატა");

        if (!url) {
          throw new Error("Something went wrong");
        }

        formRef.current.action = url;
        if ("threeDSMethodData" in post) {
          inputRef.current.name = "threeDSMethodData";
          inputRef.current.value = post.threeDSMethodData;
        } else {
          inputRef.current.name = "creq";
          inputRef.current.value = post.creq;
        }

        localStorage.removeItem("sessionId");
        formRef.current.submit();
        return;
      }

      handleStatusRequest();
    }
    (async () => {
      const data = await getPendingStatus(acquiringTransactionId);
      const decryptedData = decrypt(data.data.encryptedData);
      const integratorOrderId = localStorage.getItem("integratorOrderId");
      const { redirectRequired, successRedirectUri, failRedirectUri } =
        await postTransactionAction(integratorOrderId);
      if (decryptedData?.status === "SUCCESS" && tipsEnabled) {
        const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}&tipsEnabled=${tipsEnabled}`;
        navigate(successUrl);
        return;
      }
      if (redirectRequired) {
        if (decryptedData?.status === "SUCCESS") {
          window.location.href = successRedirectUri;
        } else {
          window.location.href = failRedirectUri;
        }
      } else {
        if (decryptedData?.status === "SUCCESS") {
          const successUrl = `/success?acquiringTransactionId=${acquiringTransactionId}&amount=${amount}&receiverName=${receiverName}&ratingEnabled=${ratingEnabled}`;

          navigate(successUrl);
        } else {
          navigate(
            `/payment-failed?acquiringTransactionId=${acquiringTransactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
          );
        }
      }
    })();
  }, []);

  return (
    <div className="w-[100%] h-[100%] flex justify-center items-center flex-col">
      <img src={PendingIcon} alt={"Pending icon"} />
      <p className="text-[20px] text-[#170738] mt-[40px]">
        Payment is processing
      </p>
      <form ref={formRef} method="post" action="">
        <input type="hidden" name="" ref={inputRef} value="" />
        <noscript>
          <center>
            <br />
            Please click the submit button below.
            <br />
            <input type="submit" value="Submit" />
          </center>
        </noscript>
      </form>
    </div>
  );
};

export default Pending;
