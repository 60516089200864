import React from "react";

function Ge() {
  return (
    <div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p className="font-bold text-center">
            <b>
              შპს “ქიფზ.მი”-ს ელექტრონული პლატფორმით სარგებლობის წესები და
              პირობები
            </b>{" "}
          </p>
          <p>
            <b>1. ზოგადი დებულებები </b>{" "}
          </p>
          <p>
            1.1. წინამდებარე წესები და პირობები წარმოადგენს თქვენსა (
            <b>„თქვენ“</b> ან <b>„მომხმარებელი“</b>) და შპს “ქიფზ.მი”-ს
            (კომპანია ორგანიზებული საქართველოს კანონმდებლობის შესახბამისად
            საიდენტიფიკაციო კოდით: 405618915, საგადახდო სისტემის პროვაიდერის
            სარეგისტრაციო ნომერი 0099 – 7704, რეგისტრაციის თარიღი 22 მარტი, 2024
            ) (<b>„კომპანია“</b>) შორის დადებულ სავალდებულო იურიდიული ძალის
            მქონე შეთანხმებას (<b>„წესები და პირობები“</b>) რომელიც ადგენს და
            არეგულირებს კომპანიის ვებ-გვერდითა და აპლიკაციით (<b>“პლატფორმა”</b>
            ) სარგებლობის წესებსა და პირობებს.{" "}
          </p>
          <p>
            1.2. წესებსა და პირობებზე დისტანციურად, ელექტრონული გზით თანხმობის
            გამოხატვა ან/და კომპანიის პლატფორმაზე ნებისმიერი ფორმითა და
            საშუალებით წვდომა, კომპანიის პლატფორმით სარგებლობა, მათ შორის,
            კომპანიის პლატფორმაზე თქვენი ანგარიშის რეგისტრაცია ან/და კომპანიის
            პლატფორმის საშუალებით მომსამხურების მიღება, მიიჩნევა და უთანაბრდება
            თქვენს თანხმობას წინამდებარე წესებსა და პირობებზე.{" "}
          </p>
          <p>
            1.3. კომპანიის პლატფორმით სარგებლობის შეთანხმება მომხმარებელსა და
            კომპანის შორის დადებულად მიიჩნევა თქვენ მიერ წინამდებარე წესებსა და
            პირობებზე თანხობის გამოხატვით.{" "}
          </p>
          <p>
            1.4. წესებსა და პირობებზე თანხმობის გამოხატვით მომხმარებელი
            ადასტურებთ, რომ წაკითხული და გაცნობიერებული გაქვთ წესები და პირობები
            და კისრულობთ ამ წესებითა პირობებით განსაზღვრული პირობების
            შესრულებასაა და დაცვას, წინააღმდეგ შემთხვევაში, მომხმარებელმა უნდა
            შეწყვიტოს კომპანიის პლატფორმით ან მისი პროდუქტებით/მომსახურებით
            სარგებლობა.{" "}
          </p>
          <p>
            1.5. კომპანიის პლატფორმაზე წვდომის, სარგებლობისას ან/და კომპანიის
            პლატფორმის საშუალებით პროდუქტის/მომსახურების შეძენისას იმოქმედებს
            კომპანიის პლატფორმაზე შეძენის დროისათვის განთავსებული წესებისა და
            პირობების ვერსია. წესებისა და პირობების მოქმედი ვერსია
            ხელმისაწვდომია კომპანიის პლატფორმაზე.{" "}
          </p>
          <p>
            1.6. კომპანის აქვს უფლება ნებისმიერ დროს ცალმხრივად, ამავე კომპანიის
            პლატფორმაზე საჯაროდ გამოქვეყნების/განთავსების გზით, შეიტანოს
            ცვლილებები წესებსა და პირობებში, წინასწარი შეტყობინებისა და თქვენი
            მხრიდან დამატებითი თანხმობის მიღების გარეშე.{" "}
          </p>
          <p>
            1.7. წესებსა და პირობებში ცვლილების განხორციელების შემდეგ, კომპანიის
            პლატფორმით სარგებლობის გაგრძელებით, რაც მათ შორის გამოიხატება
            კომპანიის პლატფორმაზე ნებისმიერი საშუალებით წვდომით, კომპანიის
            პლატფორმის მომსახურებებით სარგებლობით, ან/და კომპანიის პლატფორმის
            საშუალებით პროდუქტის/მომსახურების მიღებით, თქვენ ადასტურებთ და
            ეთანხმებით წესებსა და პირობებში შეტანილ ცვლილებებს.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            1.8. . თქვენი პერსონალური მონაცემების დამუშავება, რომელსაც თქვენ
            მიერ კომპანიის პლატფორმის სარგებლობისას თქვენგან ან მესამე
            პირებისგან მოიპოვებს კომპანიის პლატფორმა რეგულირდება შემდეგ ბმულზე:
            <a href="https://app.keepz.me/privacy" className="text-[#0000EE]">
              კონფიდენციალურობის პოლიტიკა
            </a>{" "}
            (შემდგომში <b>„პერსონალური მონაცემების დამუშავების პოლიტიკა“</b>) და
            წარმოადგენს კომპანიის პლატფორმის წესებისა და პირობების განუყოფელ
            ნაწილს. კომპანიის პლატფორმის წესებსა და პირობებზე თანხმობის
            გამოხატვით მომხმარებელი ამავდროულად ეთანხმება პერსონალურ მონაცემთა
            დამუშავების პოლიტიკას.{" "}
          </p>
          <p>
            1.9. თუ კომპანიის პლატფორმაზე წესები და პირობები ქართული ენის გარდა,
            ხელმისაწვდომია სხვა ენაზეც, სხვა ენაზე წარმოდგენილი წესებისა და
            პირობების ტექსტებს შორის შეუსაბამობის ან წინააღმდეგობის შემთხვევაში,
            უპირატესობა მიენიჭება წესებისა და პირობების ქართულ ენაზე შესრულებულ
            ტექსტს.{" "}
          </p>
          <p>
            1.10. წესებსა და პირობებში მხოლობით რიცხვში გამოყენებული ტერმინები
            ან/და სიტყვები უნდა იყოს აღქმული მრავლობით რი ცხვშიც (და პირიქით),
            თუ თავად წესებისა და პირობების კონტექსტიდან აშკარად სხვა რამ არ
            გამომდინარეობს.{" "}
          </p>
          <p>
            1.11. მამრობითი სქესის ნაცვალსახელები გულისხმობს მდედრობითი სქესის
            ნაცვალსახელებსაც და პირიქით (წინამდებარე წესებისა და პირობების იმ
            ენაზე შესრულებული თარგმანის შემთხვევში, რომელშიც გამოიყენება სქესის
            აღმნიშვნელი ნაცვალსახელები).{" "}
          </p>
          <p>
            1.12. ინტერნეტკავშირის მქონე ნებისმიერი პირისათვის კომპანიის
            პლატფორმზე წვდომა უფასოა.{" "}
          </p>
          <p>
            1.13. კომპანია არ იძლევა გარანტიას, რომ კომპანიის პლატფორმა ან მისი
            რომელიმე შემადგენელი ნაწილი, მათ შორის, კომპანიის პლატფორმის
            კონტენტი, ასევე, კომპანიის პლატფორმის საშუალებით შემოთავაზებული
            ნებისმიერი პროდუქტი/მომსახურება ყოველთვის იქნება ხელმისაწვდომი
            მომხმარებლისათვის და ადგილი არ ექნება რაიმე სახის შეფერხებას,
            შეზღუდვას ან ნაკლს.{" "}
          </p>
          <p>
            1.14. კომპანის არ დაეკისრება რაიმე სახის პასუხისმგებლობა თქვენ
            წინაშე თუ კომპანიის პლატფორმა, მისი რომელიმე შემადგენელი ნაწილის,
            მათ შორის, კომპანიის პლატფორმის კონტენტი, ასევე, კომპანიის
            პლატფორმის საშუალებით შემოთავაზებული ნებისმიერი პროდუქტი/მომსახურება
            ნებისმიერი შესაძლო მიზეზით, ნებისმიერ დროს ან ნებისმიერი ვადით
            გახდება მიუწვდომელი.{" "}
          </p>
          <p>
            1.15. კომპანია უფლებამოსილია წინასწარი შეტყობინებისა და თქვენი
            თანხმობის გარეშე, შეაჩეროს, გააუქმოს, შეწყვიტოს ან/და შეცვალოს
            კომპანიის პლატფორმა ან მისი ნებისმიერი ნაწილი, კომპანიის პლატფორმის
            ნებისმიერი ფუნქცია, კომპანიის პლატფორმის საშუალებით შემოთავაზებული
            ნებისმიერი პროდუქტის/მომსახურების შეძენის ან სარგებლობის წესები და
            პირობები.{" "}
          </p>
          <p>
            1.16. კომპანია უფლებამოსილია ნებისმიერ დროს აუკრძალოს/შეუზღუდოს
            ნებისმიერ პირს კომპანიის პლატფორმაზე წვდომა, მათ შორის, ნებისმირ
            დროს შეზღუდოს მომსახურების მიწოდება, დაბლოკოს ან გააუქმოს ნებისმიერი
            რეგისტრირებული მომხმარებლის ანგარიში, თუ პირი არღვევს საქართველოს
            მოქმედ კანონმდებლობას ან/და ამ წესებისა და პირობების მოთხოვნებს.{" "}
          </p>
          <p>
            1.17. კომპანია უფლებამოსილია, მაგრამ არ არის ვალდებული გააკონტროლოს
            ან შეამოწმობს კომპანიისაგან განსახვავებული სხვა ნებისმიერი პირის
            მიერ კომპანიის პლატფორმაზე განთავსებული ან კომპანიის პლატფორმის
            საშუალებით შექმნილი, გაგზავნილი ან/და მიღებული შეტყობინებების,
            კომენტარების ან სხვა ნებისმიერი ინფორმაციის შინაარსი.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>2. ტერმინთა განმარტება</b>{" "}
          </p>
          <p>
            2.1. წესებსა და პირობებში გამოყენებულ ტერმინებს და განმარტებებს აქვთ
            ქვემოთ განსაზღვრული მნიშვნელობა, გარდა იმ შემთხვევისა, თუ წესებისა
            და პირობების კონტექსტიდან აშკარად სხვა რამ არ გამომდინარეობს:{" "}
          </p>
          <p>
            2.1.1. „კოპანია“ ან „ჩვენ“ ან “სერვისის მიმწოდებელი” - წესებსა და
            პირობების მიზნებისათვის წარმოადგენს შემდეგ პირს: შპს ქიფზ.მი,
            რეგისტრირებული საქართველოს კანონმდებლობის შესაბამისად,
            საიდენტიფიკაციო კოდი: 405618915, მისამართი: საქართველო, თბილისი,
            საბურთალოს რაიონი, მიხეილ თამარაშვილის გამზირი, N13თ, საოფისე ფართი
            N1, სართული 1, ბლოკი "ა"{" "}
          </p>
          <p>
            2.1.2. „კომპანიის ელექტრონული კომერციის პლატფორმა“ ან „კომპანიის
            პლატფორმა“ - ვებგვერდი{" "}
            <a style={{ color: "blue" }} href="https://app.keepz.me/">
              https://app.keepz.me/
            </a>
            , მისი ქვეგვერდები, მობილური ვერსია ან/და მობილური აპლიკაცია და
            ყველა სხვა ვებგვერდი, ვებგვერდების მობილური ვერსია, მობილური
            აპლიკაციები, პროგრამული უზრუნველყოფები, ინტერნეტ პორტალები,
            ელექტრონული კომერციის პლატფორმები, რომელსაც ფლობს, მართავს,
            აკონტროლებს ან/და წვდომას უზრუნველყოფს კომპანია და რომლებიც
            ამავდროულად აკეთებენ მითითებას წინამდებარე წესებსა და პირობებზე.{" "}
          </p>
          <p>
            2.1.3. “კომპანიის პლატფორმით სარგებლობის შეთანხმება“ ან „წესები და
            პირობები“ - წინამდებარე წესები და პირობები ექვაირინგის ან/და რაიმე
            სხვა სახის მომსახურების ხელშეკრულებასთან, ნებისმიერ სხვა წესთან,
            პირობასთან ან/და შეთანხმებასთან ერთად, რომლებიც ადგენენ და
            არეგულირებენ კომპანიის პლატფორმაზე წვდომისა და სარგებლობის წესებსა
            და პირობებს (მათ შორის, კომპანიის პლატფორმის საშუალებით
            შემოთავაზებული მომსახურებით სარგებლობისა ან/და კომპანიის პლატფორმაზე
            განთავსებული პროდუქტის შეძენის პირობებს), რომლებსაც წინამდებარე
            წესები და პირობები შესაბამისი მითითებით მიიჩნევენ ამ წესებისა და
            პირობების ნაწილად.{" "}
          </p>
          <p>
            2.1.4. „პროდუქტი“ ან „პროდუქცია“ - კომპანიის მიერ კომპანიის
            პლატფორმაზე სარეალიზაციო მიზნით განთავსებული ან შემოთავაზებული
            ნებისმიერი პროდუქტი/მომსახურება.{" "}
          </p>
          <p>
            2.1.5. „მომხმარებელი“ ან “კლიენტი” – კომპანიის პლატფორმაზე წვდომის
            განმახორციელებელი/კომპანიის პლატფორმით მოსარგებლე 18 წელს მიღწეული
            ფიზიკური პირი, რომელიც აკმაყოფილებს წინამდებარე წესებითა და
            პირობებით გათვალისწინებულ მოთხოვნებს და სურს კომპანიის პლატფორმის
            საშუალებით ისარგებლოს შემოთავაზებული მომსახურებით ან/და შეიძინოს
            კომპანიის პლატფორმაზე განთავსებული პროდუქტი. მომხმარებელი გულისხმობს
            როგორც რეგისტრირებულ, ისე არარეგისტრირებულ მომხმარებელსაც და მათ
            შორის მყიდველსაც, თუ კონტექსტის შინაარსიდან აშკარად სხვა რამ არ
            გამომდინარეობს.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.1.6. „რეგისტრირებული მომხმარებელი“ - წესებისა და პირობების
            შესაბამისად, კომპანიის პლატფორმაზე რეგისტრირებული მომხმარებელი,
            რომელსაც კომპანიის პლატფორმაზე გააჩნია პირადი ანგარიში.{" "}
          </p>
          <p>
            2.1.7. „არარეგისტრირებული მომხმარებელი“, “ვიზიტორი” ან „სტუმარი“ –
            მომხმარებელი, რომელიც სარგებლობს კომპანიის პლატფორმით და არ აქვს
            გავლილი ავტორიზაცია.{" "}
          </p>
          <p>
            2.1.8. „მომხმარებლის ანგარიში“ ან „ანგარიში“ - რეგისტრირებული
            მომხმარებლის მიერ კომპანიის პლატფორმზე რეგისტრირებული პირადი
            ანგარიში, რომელიც წარმოადგენს კომპანიის პლატფორმის კონფიდენციალურ
            ნაწილს და წვდომისათვის საჭიროა რეგისტრირებული მომხმარებლის
            შესაბამისი ელექტრონული ფოსტის მისამართის ან ტელეფონის ნომრისა და
            პაროლის ცოდნა. ანგარიში იქმნება მომხმარებლის მიერ რეგისტრაციის
            შემდგომ. ანგარიში ასახავს მომხმარებლის მიერ კომპანიის პლატფორმაზე
            მითითებულ ინფორმაციას, ასევე, შეიცავს რეგისტრირებული მომხმარებლისა
            და კომპანიის პლატფორმაზე მის მიერ განხორციელებული ოპერაციებისა და
            ქმედებების შესახებ სხვადასხვა ინფორმაციას, რომელიც მოწოდებულია თვით
            რეგისტრირებული მომხმარებლის მიერ ან ავტომატურად არის
            რეგისტრირებული/ასახული კომპანიის პლატფორმის საშუალებით
            (გადარიცხვების ისტორია, ინვოისები და სხვა).{" "}
          </p>
          <p>
            2.1.9. „რეგისტრაცია“ – წესებისა და პირობების შესაბამისად, კომპანიის
            პლატფორმაზე მომხმარებლის მიერ სპეციალური პროცედურების გავლა, რაც
            გულისხმობს მომხმარებლის მიერ კომპანიის პლატფორმაზე მისი სახელის,
            გვარის, ელექტრონული ფოსტის, მობილური ტელეფონის ნომრისა ან/და
            ანგარიშის პაროლის მითითებისა და მომხმარებლის მიერ მითითებული
            ელექტრონული ფოსტის ან/და მობილური ტელეფონის ნომრის ვერიფიცირების
            შემდეგ, მომხმარებლის პირადი ანგარიშის გახსნას კომპანიის პლატფორმაზე,
            რომელიც აძლევს მას კომპანიის პლატფორმაზე ავტორიზაციის გავლის
            შესაძლებლობას.{" "}
          </p>
          <p>
            2.1.10. „ავტორიზაცია“ - წესებისა და პირობების შესაბამისად, კომპანიის
            პლატფორმაზე მომხმარებლის მიერ პირად ანგარიშში შესვლა
            ავტორიზაციისათვის საჭირო მონაცემების საფუძველზე. ავტორიზაცია
            წარმოადგენს კომპანიის პლატფორმაზე არსებულ ინფორმაციულ რესურსებზე
            სპეციალური წვდომის უფლების ფუნქციას, რომელიც დაკავშირებულია
            კომპიუტერულ და ინფორმაციულ უსაფრთხოებასთან და სხვა უფლებებთან და
            პრივილეგიებთან ერთად, ანიჭებს მომხმარებელს უფლებას კომპანიის
            პლატფორმის სარგებლობის მიზნით, შევიდეს მის პირად ანგარიშზე, ასევე,
            მართოს და დაარედაქტიროს მისი ანგარიში და ანგარიშში ასახული
            მონაცემები.{" "}
          </p>
          <p>
            2.1.11. „ექვაირინგის ხელშეკრულება“ - წინამდებარე დებულებები და
            პირობები არეგულირებს კომპანიის პლატფორმაზე, მათ შორის ექვაირინგის,
            ფულადი გზავნილების ან გადახდის ინციირების, ან ანგარიშის ინფორმაციაზე
            წვდომის მომსახურების მიზნით კომპანის, როგორც სერვისის მიმწოდებელსა
            და მომხმარებელს, როგორც სავაჭრო ობიექტს შორის დისტანციურ ფორმით,
            ელექტრონული საშულებების გამოყენებით მომსახურების გაწევის პირობებსა
            და, შესაძლებლობის შემთხვევაში, დისტანციური ხელშეკრულების დადებას.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            2.1.12. „კონტენტი“ - კომპანიის პლატფორმის შემადგენელი ნებისმიერი
            ნაწილი, განთავსებული, გამოსახული ან ხელმისაწვდომი ნებისმიერი
            გრაფიკული გამოსახულება, ნახატი, ნაბეჭდი, ფოტო, გამოსახულება, ხმა,
            მუსიკა, ვიდეო მასალა, აუდიო მასალა, ტექსტი, ლოგო, სიმბოლო, მონაცემთა
            ბაზა, დიზაინი, პროგრამული უზრუნველყოფა, ფაილები და ნებისმიერი სხვა
            ელემენტი.{" "}
          </p>
          <p>
            2.2.1. „პაროლი“ – სიმბოლოთა კომბინაცია, რომელსაც პირი ქმნის
            კომპანიის პლატფორმაზე რეგისტრაციის დროს, პირად ანგარიშზე
            წვდომისათვის/სარგებლობისათვი და სხვა პირებისაგან მისი დაცვისა და
            უსაფრთხოების უზრუნველყოფის მიზნით.{" "}
          </p>
          <p>
            2.2.2. "ექვაირინგის მომსახურების საკომისიო გადასახადი" - საკომისიო
            გადასახადი, რომელიც გამოითვლება თითოეული გატარებული გადახდიდან.
            საკომისიო გადასახადის ზოგადი საზღვრებია - 1% -დან 3%-მდე, ხოლო
            მომხმარებლის ძირითადი (დეფოლტ) საკომისიო გადასახადი არის 2.5%.
            მომხმარებელი უფლებამოსილია თავად განსაზღვროს, რომელ მხარეს
            (მომხმარებლის ან მისი კლიენტის) ჩამოეჭრება საკომისიო გადასახადი
            თითოეულ ტრანზაქციაზე.{" "}
          </p>
          <p>
            <b>3. მომხმარებლის თანხმობები და გარანტიები</b>{" "}
          </p>
          <p>3.1. მომხმარებელი იძლევა გარანტიას და ადასტურებს შემდეგს: </p>
          <p>
            3.1.1. სარგებლობს კომპანიის პლატფორმით და მასზე განთავსებული
            პროდუქტით/ მომსახურებით, მათ შორის, ახორციელებს გადახდების
            განთავსებას ან/და მომსახურების მიღებას მხოლოდ საკუთარი ნებით, მისი
            თავისუფალი არჩევნისა და გააზრებული რისკის საფუძველზე, კომპანიის და
            სხვა მესამე პირთა იძულებისა და ზეწოლის გარეშე;{" "}
          </p>
          <p>
            3.1.2. გააჩნია სრული (შეუზღუდავი) ქმედუნარიანობა, არ იმყოფება
            ნარკოტიკული, ალკოჰოლური, ფსიქოტროპული ან ტოქსიკური პრეპარატების
            ზემოქმედების ქვეშ, არ იმყოფება შეცდომის, თაღლითობის, ზეწოლის,
            მუქარისა ან ნებისმიერი მართლსაწინააღმდეგო ზემოქმედების ქვეშ და არ
            წარმოადგენს ძალადობის, მუქარის, მოტყუების, შეცდომაში შეყვანისა თუ
            სხვა აკრძალული მოქმედების ობიექტს კომპანიის ან სხვა მესამე პირის
            მხრიდან, სრულად აცნობიერებს საკუთარი ნების გამოვლენის შინაარსს,
            წესებისა და პირობების შინაარს და მისგან გამომდინარე სამართლებრივ
            შედეგებსა და პასუხისმგებლობას;{" "}
          </p>
          <p>
            3.1.3. აქვს სრული უფლებამოსილება იმისათვის, რომ იკისროს და შეასრულოს
            ამ წესებითა და პირობებით განსაზღვრული ვალდებულებები, მათ შორის,
            დადოს კომპანიის პლატფორმით სარგებლობის შეთანმხმება და დისტანციური
            ექვაირინგის ხელშეკრულება;{" "}
          </p>
          <p>
            3.1.4. კომპანიისათვის წარმოდგენილი ნებისმიერი ინფორმაცია, მონაცემები
            და დოკუმენტაცია არის უტყუარი, მართლზომიერი, ზუსტი, აქტუალური,
            ვალიდური და სრული. აუცილებლობის შესაბამისად, მომხმარებელი დრო და დრო
            მოახდენს მის განახლებას;{" "}
          </p>
          <p>
            3.1.5. არ არის ჩაბმული ან არ მონაწილეობს არც ერთი იურისდიქციის
            კანონმდებლობით გათვალისწინებულ რაიმე სახის უკანონო საქმიანობაში (მათ
            შორის, ფულის გათეთრება, იარაღით ვაჭრობა, ტერორიზმი ან სხვა სახის
            არამართლზომიერ საქმიანობა);{" "}
          </p>
          <p>
            3.2. მომხმარებლის მხრიდან ამ წესებითა და პირობებით გათვალისწინებული
            გარანტიებისა და ნაკისრი ვალდებულებების დარღვევა საკმარისი საფუძველია
            კომპანიის პლატფორმით სარგებლობის შეთანხმების, მათ შორის, დისტანციური
            ექვაირინგის ხელშეკრულების ცალხრივად შეწყვეტისათვის ან კომპანიის
            პლატფორმით სარგებლობის შეთანხმებით, მათ შორის, დისტანციური
            ექვაირინგის ხელშეკრულებით კომპანიის მიერ ნაკისრი ვალდებულებების
            შესრულებაზე უარის თქმისათვის (შეჩერებისათვის).{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>4. მომხმარებლის ვალდებულებები</b>{" "}
          </p>
          <p>
            4.1. კომპანიის პლატფორმაზე წვდომისას, კომპანიის პლატფორმით
            სარგებლობის ან/და კომპანიის პლატფორმის საშუალებით პროდუქტის
            შეძენისას მომხმარებელი ვალდებულია:{" "}
          </p>
          <p>
            4.1.1. დაიცვას საქართველოს მოქმედი კანონმდებლობა; 4.1.2. დაიცვას და
            შეასრულოს წინამდებარე წესებითა და პირობებით გათვალისწინებულ
            მოთხოვნები ჯეროვნად და კეთილსინდიერად;{" "}
          </p>
          <p>
            4.1.3. არ ხელყოს, შეზღუდავს ან/და შეაფერხებს სხვა პირის მიერ
            კომპანიის პლატფორმით სარგებლობას/მის გამოყენებას;{" "}
          </p>
          <p>
            4.1.4. მიზანმიმართულად არ გადასცეს, არ გადაგზავნოს ან/და არ ატვირთოს
            კომპანიის პლატფორმაზე ნებისმიერ მონაცემი/ მასალას, რომელიც შეიცავს
            ვირუსს, ე.წ. „ტროიანებს“, ჯაშუშ პროგრამებს ან ნებისმიერ სხვა საზიანო
            პროგრამას, კოდს ან/და მონაცემს კომპანიზე, მესამე პირებზე,
            კომპიუტერულ პროგრამებსა და მოწყობილობებზე უარყოფითი ზეგავლენის
            მოხდენის მიზნით;{" "}
          </p>
          <p>
            4.1.5. კომპანიის პლატფორმაზე ან მისი საშუალებით არ გაავრცელოს
            ნებისმიერი რეკლამა ან წამახალისებელ ან ნებისმიერი სხვა ფორმის
            წამაქეზებელი ელექტრონული გზავნილს (სპამი), რომელიც ეწინააღმდეგება
            კანონს ან/და ზიანს აყენებს კომპანის ან მესამე პირებს;{" "}
          </p>
          <p>
            4.1.6. კომპანიის წინასწარი წერილობითი თანხმობის გარეშე არ
            განახორციელოს კომპანიის პლატფორმის, მისი კონტენტის და ნებისმიერი
            ფორმით გამოხატული შემადგენელი ნაწილების, ელემენტების, ინფორმაციის,
            მათ შორის, კომპიუტერული კოდებისა და ელემენტების კოპირებას,
            რეპროდუქციას, კადრირებას, გამოქვეყნებას, ჩამოტვირთვას, ბეჭდვას,
            განთავსებას, მაუწყებლით გადაცემას, ჩაწერას, გადაცემას, გასაჯაროებას,
            გავრცელებას, ბმულით დაკავშირებას, ჰაპერბმულით (deep link)
            დაკავშირებს, დისტრიბუციას ან სარგებლობას ნებისმიერი ფორმით ან
            საშუალებით, პირდაპირ ან ირიბად, კომერციული ან/და არაპირადი
            მიზნებისათვის ნებისმიერი სახით;{" "}
          </p>
          <p>
            4.1.7. კომპანიის პლატფორმაზე ან მისი საშუალებით არ განხორცელოს
            ნებისმიერი ქმედება, რომელიც არღვევს კომპანიის ან სხვა მესამე პირთა
            საავტორო უფლებებს, პატენტებს, სავაჭრო ნიშნებს, საფირმო სახელწოდებას,
            ინტელექტუალური საკუთრების, საკუთრების ან სხვა უფლებებს;{" "}
          </p>
          <p>
            4.1.8. საჯაროდ, პირდაპირი ან ირიბი გზით, კომპანიის პლატფორმაზე ან
            მის ნებისმიერ ნაწილში (არსებობის შემთხვევაში, მათ შორის,
            კომენტარებში, ფორუმზე, ჯგუფებში) არ განათავსოს კომპანიის კონკურენტი
            ან მსგავსი საქმიანობის მქონე მისი ან სხვა მესამე პირთა ან მათთან
            დაკავშირებული ვებგვერდების/სოციალური ქსელების ბმულები ან/და
            მოახდინოს მისი ან სხვა მესამე პირთა რეკლამირება;{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            4.1.9. არ დააზიანოს, არ შეაფერხოს ან არ მოახდინოს რაიმე სახით
            ზემოქმედება კომპანიის პლატფორმის, მისი კონტენტის ან/და მის
            ფუნქციონირებასთან დაკავშირებული ქსელის, პროგრამის ან სისტემის
            გამართულ მუშაობასა და უსაფრთხოებაზე;{" "}
          </p>
          <p>
            4.1.10. არ განახორციელოს ე.წ. “ფიშინგი“, მათ შორის, კომპანიის
            პლატფორმის ან მისი რომელიმე ნაწილის გამოყენებით ან არ განახორციელოს
            კომპანიის პლატფორმზე ან მისი საშუალებით სპამების, ვირუსების,
            საფრთხის შემცველი კოდების ან სხვა ისეთ მონაცემების გავრცელება,
            რომელიც დააზიანებს ან რაიმე სახით საფრთხეს შეუქმნის კომპანიის
            პლატფორმის მუშაობას და ფუნქციონირებას, კომპანიის პლატფორმას, სხვა
            მომხმარებლებს, მესამე პირებს ან მათ მოწყობილობებს, პროგრამულ
            უზრუნველყოფებსა ან/და მონაცემებს;{" "}
          </p>
          <p>
            4.1.11. არ განახორციელოს ისეთ ქმედება, რაც დააზიანებს კომპანიის
            პლატფორმის ჯეროვანი ფუნციონირებისათვის საჭირო სისტემის, ქსელის ან/და
            მონაცემთა ერთიანობას და უსაფრთხოებას;{" "}
          </p>
          <p>
            4.1.12. არ განახორციელოს კომპანიის პლატფორმისა და მის მომხმარებელთა
            კომპიუტერულ სისტემებზე, ქსელებზე ან/და მონაცემებზე არამართზლზომიერ
            წვდომას;{" "}
          </p>
          <p>
            4.1.13. კომპანიის პლატფორმაზე, კომპანიის პლატფორმის საშულებით ან მის
            მიმართ არ განახორციელოს არამართლზომიერ და კანონსაწინააღმდეგო
            ქმედებას;{" "}
          </p>
          <p>
            4.1.14. დაუყოვნებლივ აცნობოს კომპანის ყველა იმ გარემოებ(ებ)ის
            შესახებ, რომელიც შეიძლება წინააღმდეგობაში მოვიდეს მის წინამდებარე
            წესებთან და პირობებთან ან/და გამოიწვიოს ამ წესებითა და პირობებით
            ნაკისრი ვალდებულებების დარღვევა;{" "}
          </p>
          <p>
            4.1.15. პერიოდულად, აუცილებლობის შესაბამისად გაანაახლოს კომპანიის
            პლატფორმით სარგებლობის შეთანხმების დასადებად ან მის საფუძველზე და
            შესასრულებლად, მათ შორის, მომხმარებლის რეგისტრაციის დროს, ან მისი
            საშუალებით მომსახურებით სარგებლობისას, კომპანიის პლატფორმაზე
            შეკვეთის განთავსებისას, კომპანიის პლატფორმაზე წვდომის ან მისი
            საშუალებით პროდუქტის შეძენისათვის წარდგენილი ნებისმიერი ინფორმაცია,
            მონაცემები ან დოკუმენტი.{" "}
          </p>
          <p>
            4.1.16. კომპანიის პლატფორმაზე, მის რომელიმე შემადგენელ ნაწილში ან
            მისი საშუალებით განათავსოს ან/და გაავრცელოს კომპანიის ან სხვა მესამე
            პირთა შეურაცხმყოფელი, უხამსი, დისკრიმინაციის ნიშნების შემცველი,
            ცილისმწამებლური და სხვა არამართლზომიერი ინფორმაცია ნებისმიერი ფორმით
            ან აღნიშნული საშულებით მოახდინოს ნებისიმიერი არამართზომიერი ქმედების
            ხელშეწყობა ან/და მოწოდება;{" "}
          </p>
          <p>
            4.1.17. სხვა მომხმარებლის, მოწყობილობის ან პროგრამული უზრუნველყოფის
            საშუალებით კომპანიის პლატფორმაზე ან სხვა მომხმარებელზე
            მიზანმიმართულად და არამართლზომიერად, არ შექმნას აშკარად არასწორი
            წარმოდგენები, მათ შორის, ნეგატიური კომენტარებით და შეფასებებით ან
            საკუთარ თავზე- დადებითი წარმოდგენა, მათ შორის, შესაბამისი
            კომენტარებით ან შეფასებებით;{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            4.1.18. კომპანიის მხრიდან მოთხოვნის შემთხვევაში, არაუგვიანეს 10
            (ათი) დღის ვადაში წარმოადგინოს ამ წესებსა და პირობებზე თანხმობის
            დასადასტურებლად წერილობითი თანხმობა (საჭიროების შემთხვევაში,
            დამოწმებული კანონმდებლობით დადგენილი წესით) ან ელექტრონული ფორმით
            შესრულებული თანხმობა, კანონმდებლობით განსაზღვრული წესით შესრულებული
            ელექტრონული ხელმოწერით.{" "}
          </p>
          <p>
            <b>5. პლატფორმით სარგებლობა</b>
          </p>
          <p>
            5.1. კომპანიის პლატფორმაზე რეგისტრაცია და ანგარიშის შექმნა
            ხორციელდება შემდეგი მეთოდებით: კომპანიის პლატფორმის რეგისტრაციის
            გვერდზე მომხმარებელი უთითებს საკუთარ სახელს, გვარს, ელექტრონული
            ფოსტის მისამართს ან/და მობილური ტელეფონის ნომერს და პაროლს.
            რეგისტრაციის პროცესის დასრულებისათვის საჭიროა კომპანიის პლატფორმაზე
            მითითებული მომხმარებლის ელექტრონული ფოსტის მისამართის ან/და მობილური
            ტელეფონის ნომრის ვერიფიცირებას, რაც გულისხმობს მომხმარებლის
            ელექტრონული ფოსტის მისამართის ან/და მობილური ტელეფონის ნომრის
            სისწორისა და ნამდვილობის შემოწმებას მომხმარებლის ელექტრონული ფოსტის
            მისამართზე ან/და მობილური ტელეფონის ნომერზე გაგზავნილი ბმულის/კოდის
            საშუალებით. მომხმარებლის ელექტრონული ფოსტის მისამართზე ან/და
            მობილური ტელეფონის ნომრის რეგისტრაციის პროცესის დასრულების შემდეგ
            მომხმარებელი ხდება კომპანიის პლატფორმაზე ანგარიშის მფლობელი.{" "}
          </p>
          <p>
            5.2 იმ შემთხვევაში, თუ მომხმარებელს მინიჭებული აქვს კომპანიის მიერ
            დაგენერირებული QR კოდი, ასეთი ერთეული QR კოდის ფუნქციონირების
            გადასახადია ფიქსირებული 5 ლარი ყოველთვიურად. კომპანია უფლებამოსილია
            შეაჩეროს QR კოდის მოქმედება იმ შემთხვევაში, თუ პასუხისმგებელი პირი
            გადააცილებს ყოველთვიური გადასახადის გადახდის თარიღს 1 (ერთი) დღით.
          </p>
          <p>
            5.3. რიგი მომსახურების გაწევის ჭრილში კომპანია უფლებამოსილია და
            მომხმარებელი თანახმაა კომპანიას მიაწოდოს დამატებითი ინფორმაცია და
            მაიდენტიფიცირებელი დოკუმენტაცია სრული ვერიფიცირებისათვის.{" "}
          </p>
          <p>
            5.4. კომპანია უფლებამოსილია ნებისმიერ დროს შეცვალოს კომპანიის
            პლატფორმაზე მომხმარებლის რეგისტრაციისათვის ან/და ავტორიზაციისათვის
            საჭირო სავალდებულო მონაცემები.{" "}
          </p>
          <p>
            5.5. კომპანიის პლატფორმაზე რეგისტრაციისათვის/ავტორიზაციისათვის
            წინამდებარე წესებსა და პირობებში მითითებული მომხმარებლის მონაცემების
            მიუხედავად, სავალდებულო იქნება ყველა იმ მონაცემის მითითება და იმ
            პირობის შესრულება, რაც რეგისტრაციის /ავტორიზაციის მომენტში ასეთად
            არის მითითებული უშუალოდ კომპანიის პლატფორმაზე.{" "}
          </p>
          <p>
            5.6. კომპანიის პლატფორმაზე ავტორიზაციის და ანგარიშის უსაფრთხოების
            უზრუნველყოფის მიზნით, მომხმარებლის მიერ კომპანიის პლატფორმაზე
            რეგისტრაციის დროს მითითებული პაროლი ინახება დაშიფრულად და მხოლოდ
            მომხმარებელისთვის არის ცნობილი.{" "}
          </p>
          <p>
            5.7. მომხმარებელი ვალდებულია უზრუნველყოს, რომ კომპანიის პლატფორმაზე
            რეგისტრაციის დროს მითითებული მონაცემები, მათ შორის, პერსონალური
            მონაცემი იყოს უტყუარი, ზუსტი და სრული.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            5.8. მომხმარებელი იძლევა გარანტიას, რომ კომპანიის პლატფორმაზე
            რეგისტრაციის დროს მითითებული მონაცემები არის მისი პირადი მონაცემები
            და ეკუთვნის უშუალოდ მას და არა სხვა პირს.{" "}
          </p>
          <p>
            5.9. მომხმარებელს ნებისმიერ დროს შესუძლია განაახლოს ან ცვლილება
            შეიტანოს კომპანიის პლატფორმაზე მითითებულ მონაცემებში მის ანგარიშზე
            წვდომის გზით. გთხოვთ, იხილოთ ჩვენი კონფიდენციალობის პოლიტიკა და
            გაეცნოთ დამატებით ინფორმაციას, თუ როგორ მოხდება თქვენი პერსონალური
            მონაცემების შეგროვება, დამუშავება და შენახვა.{" "}
          </p>
          <p>
            5.10. კომპანია ურჩევს ყველა მომხმარებელს გამოიჩინონ სათანდო
            სიფრთხილე და არ გაანდონ სხვა მესამე პირებს საკუთარი ანგარიშის
            პაროლი. ნებისმიერ შემთხვევაში, მომხმარებლი თავად არის პასუხისმგებელი
            მისი ანგარიშის პაროლის კონფიდენციალობის დაცვაზე და ანგარიშის
            მეშვეობით განხორციელებულ ნებისმიერ ქმედებაზე. კომპანია არ იღებს
            პასუხისმგებლობას იმ ზიანზე, რომელიც შესაძლოა წარმოიშვას ნებისმიერ
            პირს მომხმარებელს მიერ მისი ანგარიშის პაროლის გახმაურების
            საფუძველზე. თუ მომხმარებელი ფლობს ინფორმაციას ან გაუჩნდა ეჭვი, რომ
            საფრთხე შექმნა ან შესაძლოა საფრთხე შეექმნას მისი მისი ან სხვა
            მომხმარებლის ანგარიშის ან ანგარიშის პაროლის უსაფრთხოებას ან
            ხორციელდება მათი არამართლზომიერი გამოყენება, გთხოვთ, დაუყოვნებლივ
            შეატყობინოთ ამის შესახებ კომპანის.{" "}
          </p>
          <p>
            5.11. რეგისტრირებული მომხმარებლის მიერ კომპანიის პლატფორმაზე
            ავტორიზაციისათვის საჭირო მონაცემების აღდგენა ხორციელდება კომპანიის
            პლატფორმაზე დაცული მომხმარებლის ელექტრონული ფოსტის მისამართზე ან/და
            მობილური ტელეფონის ნომერზე მონაცემთა აღდგენისათვის საჭირო გაგზავნილი
            ინფორმაციის საშუალებით ან კომპანიისათვის წესებსა და პირობებში
            მითითებულ საკონტაქტო/საკომუნიკაციო არხებზე დაკავშირების გზით.{" "}
          </p>
          <p>
            5.12. კომპანიის პლატფორმა ახორციელებს მომხმარებლის იდენტიფიცირებას
            უნივერსალური იდენტიფიკატორების საშუალებით, კერძოდ, ელ.ფოსტის,
            პაროლის ან/და მობილური ტელეფონის ნომრით.{" "}
          </p>
          <p>
            <b>6. კონტენტი და მასთან დაკავშირებული უფლებები</b>{" "}
          </p>
          <p>
            6.1. კომპანის აქვს უფლებამოსილება ნებისმიერ დროს შეცვალოს, შექმნას,
            შეზღუდოს ან გააუქმოს კომპანიის პლატფორმის კონტენტი ან მისი ნაწილი.{" "}
          </p>
          <p>
            6.2. კომპანია ცდილოს უზრუნველყოს მომხმარებლისათვის ზუსტი, სრული და
            განახლებული კონტენტის მოწოდება, თუმცა არ იძლევა რაიმე სახის
            გარანტიას ან დაპირებას აღნიშნულთან დაკავშირებით.{" "}
          </p>
          <p>
            6.3. კომპანია ეცდება უზრუნველყოს, რომ კომპანიის პლატფორმა თავისუფალი
            იყოს ვირუსებისა და სხვა მავნე ან ნებისმიერი საზიანო კონტენტისაგან,
            თუმცა, არ იძლევა რაიმე სახის გარანტიას, რომ კომპანიის პლატფორმით
            სარგებლობა არ გამოიწვევს მომხმარებლის კომპიუტერის ან სხვა ტექნიკური
            მოწყობილობის დაზიანებას ან მონაცემთა დაკარგვას.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            6.4. კომპანის არ დაეკისრება პასუხისმგებლობა იმ ზიანზე, რომელიც
            მომხმარებელს ან სხვა პირებს შესაძლოა მიადგეთ კომპანიის პლატფორმაზე
            წვდომისას ვირუსის ან სხვა მავნე ან საზიანო კონტენტის მიზეზის
            შედეგად.{" "}
          </p>
          <p>
            6.5. კომპანიის პლატფორმის კონტენტი, მათ შორის, კომპანიის პლატფორმის
            დიზაინი, პროგრამული უზრუნველყოფის ნაკრები, ძირითადი პროგრამული
            კოდები, წარმოადგენს კომპანის ან შესაბამისი პროდუქტის ან/და ქონების
            მწარმოებელის/მომწოდებლის საკუთრებას და დაცულია საავტორო და მომიჯნავე
            უფლებებით საქართველოსა და საერთაშორისო კანონმდებლობის შესაბამისად.{" "}
          </p>
          <p>
            <b>7. მესამე პირთა ბმულები და სოციალური ქსელები</b>{" "}
          </p>
          <p>
            7.1. კომპანიის პლატფორმის საშუალებით შესაძლოა ხელმისაწვდომი იყოს
            კომპანიის კონტროლს მიღმა არსებული სხვა მესამე პირთა ვებგვერდები,
            კონტენტები, პროდუქტები ან/და მომსახურება ბმულების (ტექსტუალური
            ბმულები, ბანერები, არხები და ა.შ), აპლიკაციების ან სხვა ფორმით,
            რომლებიც ამყარებენ კავშირს მესამე პირთა ვებგვერდებთან, აპლიკაციებთან
            ან/და პროგრამულ უზრუნველყოფებთან.{" "}
          </p>
          <p>
            7.2. მომხმარებელი მესამე პირთა ინფორმაციით ან /და ბმულით სარგებლობს
            და იყენებს მხოლოდ საკუთარი რისკით და კომპანია არ იღებს რაიმე სახის
            პასუხისმგებლობას ან ვალდებულებას ამ მესამე პირთა ინფორმაციის ან/და
            ვებგვერდების შინაარსის, სიზუსტის, გამოყენების ან ხელმისაწვდომობასთან
            მიმართებით, მათ შორის, რაიმე სახის პასუხისმგებლობას კომპანიის
            პლატფორმასთან დაკავშირებული სხვა მესამე პირთა ვებგევრდების კონტენტზე
            და იმ ზიანზე, რომელიც შეიძლება წარმოეშვას მომხმარებელს ან სხვა
            მესამე პირს მათი სარგებლობის შემთხვევაში.{" "}
          </p>
          <p>
            7.3. კომპანიის გარდა სხვა ნებისმიერი პირის მიერ, კომპანიის
            პლატფორმაზე განთავსებული ან კომპანიის პლატფორმის საშუალებით
            შექმნილი, გაგზავნილი ან/და მიღებული შეტყობინებების, კომენტარების ან
            სხვა ნებისმიერი ინფორმაციის შინაარსზე და ამ ინფორმაციასთან
            მიმართებით განხორციელებულ ნებისმიერ ქმედებაზე პასუხისმგებლობა
            ეკისრება არა კომპანის, არამედ იდივიდუალურად და პერსონალურად მხოლოდ
            შესაბამის პირს.{" "}
          </p>
          <p>
            7.4. წესები და პირობები, სხვა საკითხების რეგულირებასთან ერთად
            არეგულირებს კომპანიის პლატფორმაზე ან მასთან დაკავშირებულ ნებისმიერ
            ვებგვერდზე, აპლიკაციაში, პროგრამულ უზრუნველყოფაში, კომპანიის
            ფეისბუქის, ტვიტერის, იუთუბის ან სხვა ნებისმიერი სოციალური ქსელის
            ოფიციალურ გვერდებზე მომხმარებლის მხრიდან განცხადების, კომენტარის,
            შეფასების, მიმოხილვის, შენიშვნების ან სხვა ინფორმაციის განთავსების
            პირობებს, რომელიც შესაბამისობაში უნდა მოდიოდეს წინამდებარე წესებსა
            და პირობებთან.{" "}
          </p>
          <p>
            <b>8. პასუხისმგებლობა</b>
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            8.1. წესებითა და პირობებით განსაზღვრული ვალდებულებების დარღვევა
            იწვევს ამავე წესებითა და პირობებით და საქართველოს მოქმედი
            კანონმდებლობით გათვალისწინებულ პასუხისმგებლობას.{" "}
          </p>
          <p>
            8.2. მხარეები იღებენ ვალდებულებას აუნაზღაურონ ერთმანეთს მათ მიერ
            პირობების სრულად ან ნაწილობრივ შეუსრულებლობის ან არაჯეროვნად
            შესრულების შედეგად მიყენებული ზიანი (ზარალი) კანონმდებლობით ან/და
            წესებითა და პირობებით დადგენილი წესით.{" "}
          </p>
          <p>
            8.3. კომპანია არ არის პასუხისმგებელი მომხმარებლის ან ნებისმიერი სხვა
            პირის წინაშე იმ ზიანისთვის, რომელიც წარმოიშვა, ან რაიმე ფორმით
            დაკავშირებული არის, მომხმარებლის მიერ კომპანიის პლატფორმაზე
            განთავსებული ნებისმიერი ბმულის გამოყენებასთან.{" "}
          </p>
          <p>
            8.4. კომპანია არ არის პასუხისმგებელი სხვა ვებგვერდის ბმულის
            შინაარსზე, რომელზეც ხდება გადამისამართება კომპანიის პლატფორმიდან.{" "}
          </p>
          <p>
            8.5. კომპანია არ არის პასუხისმგებელი რაიმე სახის ზარალზე, რომელიც
            გამოწვეულია კომპანიის პლატფორმის სარგებლობის შეუძლებლობით, კომპანიის
            პლატფორმაზე წვდომის შეზღუდვით ან შეწყვეტით.{" "}
          </p>
          <p>
            8.6. კომპანია არ იძლევა რაიმე სახის გარანტიას კომპანიის პლატფორმის
            მომსახურებასთან, პროდუქტთან ან/და მის ხარისხთან დაკავშირებით, გარდა
            იმის რაც შეესაბამება საქართველოში მოქმედი კანონმდებლობის მოთხოვნებს.{" "}
          </p>
          <p>
            8.7. კომპანიას არ ეკისრება პასუხისმგებლობა რეგისტრაციის დროს
            მომხმარებლის მიერ შეყვანილი ინფორმაციის სიზუსტესა და ნამდვილობაზე.{" "}
          </p>
          <p>
            8.8. კომპანია იყენებს ყველა გონივრულ ზომას მომხმარებელთა მონაცემების
            უსაფრთხოების დაცვის მიზნით, თუმცა ამის მიუხედავად არ არის
            პასუხისმგებელი ისეთი შემთხვევებზე, როცა მესამე პირები
            არამართლზომიერად ახორციელებენ სხვა მომხმარებელთა მონაცემებზე
            არასანქცირებული წვდომას.{" "}
          </p>
          <p>
            <b>
              9. დისტანციური ექვაირინგის ხელშეკრულების დამატებითი სახელშეკრულებო
              პირობები
            </b>
          </p>
          <p>
            {" "}
            9.1. წინამდებარე დამატებითი სახელშეკრულებო პირობები არეგულირებს შპს
            „ქიფზ.მი“- სა და მომხმარებლებს შორის არსებულ სახელშეკრულებო
            ურთიერთობებს, წარმოადგენს მხარეთა შორის დადებული ხელშეკრულებების
            შემადგენელ ნაწილს და განსაზღვრავს მხარეთა დამატებით სახელშეკრულებო
            პირობებს.
          </p>
          <p>
            {" "}
            9.2. წინამდებარე დოკუმენტიში მოცემული მარეგულირებელი დებულებები
            საერთოა ყველა იმ ხელშეკრულებისათვის, რომელზეც ვრცელდება წინამდებარე
            დოკუმენტი.
          </p>
          <p>
            {" "}
            9.3. კლიენტის შესახებ სერვისის მიმწოდებელთან არსებული/დაფიქსირებული
            პერსონალური ან ნებისმიერი სხვა ინფორმაციის (მათ შორის: საფირმო
            სახელწოდების ან/და სხვა პირადი/სამეწარმეო მონაცემების, საკონტაქტო
            ინფორმაციის და მონაცემების და ა.შ.) ცვლილებისას კლიენტი ვალდებულია
            წერილობით აცნობოს სერვისი მიმწოდებელს აღნიშნული ცვლილებ(ებ)ის
            შესახებ. კლიენტის მიერ ცვლილების შესახებ წერილობით შეტყობინებამდე
            სერვისის მიმწოდებელი უფლებამოსილია იმოქმედოს მასთან
            დაცული/დაფიქსირებული ინფორმაციის შესაბამისად.
          </p>
          <p>
            {" "}
            9.4. მომხმარებელს არ შეუძლია უარი განაცხადოს წინამდებარე დოკუმენტით
            გათვალისწინებული პირობების და ვალდებულებების შესრულებაზე მხოლოდ იმ
            მოტივით, რომ მას ხელმოწერა (მატერიალური და/ან ელექტრონული ფორმით) არ
            განუხორციელებია უშუალოდ წინამდებარე დოკუმენტზე (წინამდებარე
            დოკუმენტის ელექტრონულ და/ან მატერიალურ ვერსიაზე).
          </p>
          <p>
            {" "}
            9.5. სერვისის მიმწოდებელსა და მომხმარებელს შორის გაფორმებული
            ხელშეკრულების ან წინამდებარე დოკუმენტის რომელიმე პუნქტის
            გაუქმება/ბათილად ცნობა არ იწვევს მთლიანად ხელშეკრულების/წინამდებარე
            დოკუმენტის და/ან მათი სხვა პუნქტ(ებ)ის გაუქმებას/ბათილობას.
          </p>
          <p>
            9.6. <i>ქიფზ.მი უფლებამოსილია:</i>
          </p>
          <p>9.6.1. დააწესოს ექვაირინგის მომსახურების საკომისიო გადასახადი.</p>
          <p>
            9.6.2. გამოიყენოს მომხმარებლის საფირმო სახელწოდება და სასაქონლო
            ნიშნები სარეკლამო-საინფორმაციო საშუალებებზე განთავსების ან/და
            საგადამხდელო არხებში განთავსების მიზნით, მომხმარებლის წინასწარი
            თანხმობის საფუძველზე.
          </p>
          <p>
            9.7. <i>ქიფზ.მი ვალდებულია:</i>
          </p>
          <p>
            9.7.1. საბარათე ოპერაციის თანხის მისაღებად, ექვაირინგის მომსახურების
            ხელშეკრულებაში დაფიქსირებული შესაბამისი ანგარიშსწორების ანგარიში
            იქონიოს მუდმივად აქტიური;
          </p>
          <p>
            9.8. <i>მომხმარებელი ვალდებულია:</i>
          </p>
          <p>
            9.8.1. ინტერნეტ პორტალის საშუალებით გაყიდოს მხოლოდ
            სავაჭრო/მომსახურების ობიექტისთვის მინიჭებული ბიზნეს საქმიანობის
            კატეგორიის შესაბამისი პროდუქცია. სავაჭრო/მომსახურების ობიექტის მიერ
            სავაჭრო/მომსახურების ობიექტის ინტერნეტ პორტალის მეშვეობით
            გაყიდული/გასაყიდი პროდუქცია უნდა იყოს სავაჭრო/მომსახურების
            ობიექტისთვის მინიჭებული ბიზნეს საქმიანობის კატეგორიის შესაბამისი და
            მოქმედი კანონმდებლობით ნებადართული;
          </p>
          <p>
            9.8.2. დაუყონებლივ უზრუნველყოს სერვისის მიმწოდებლისთვის ან სერვისის
            მიმწოდებლის მიერ განსაზღვრული პირისთვის თანხის დაბრუნება, თუ: ა)
            თანხის ჩარიცხვა სავაჭრო/მომსახურების ობიექტის ანგარიშზე მოხდა
            შეცდომით ან არასწორად; ბ) საბარათე ოპერაცია შესრულებულია ბარათით
            სარგებლობის პირობების, კანონმდებლობის ან/და ბანკის ინსტრუქციების
            მოთხოვნების დარღვევით, რაც მოიცავს არაუფლებამოსილი პირის მიერ
            განხორციელებულ საბარათე ოპერაციებს ან/და ისეთ საბარათე ოპერაციებს,
            რომელიც არ გამოხატავს ბარათის მფლობელის ნებას; გ) არღვევს
            ხელშეკრულებით გათვალისწინებულ ვალდებულებებს; დ) ბარათის მფლობელი
            აცხადებს, რომ პროდუქცია არ მიუღია ან პროდუქცია ნაკლის მქონეა.
          </p>
          <p>
            9.8.3. სერვისის მიმწოდებლის ზეპირი ან წერილობითი მოთხოვნიდან 3
            (სამი) საბანკო დღის ვადაში უზრუნველყოს შემდეგი ინფორმაციის მიწოდება:
            დოკუმენტაცია/ინფორმაცია, იმ საბარათე ოპერაციასთან დაკავშირებით,
            რომელიც საგადახდო სისტემების წესებისა და კანონმდებლობის მიხედვით
            არსებობს გონივრული ეჭვი, რომ შეიძლება გახდეს/გახდა დავის საგანი;
          </p>
          <p>
            9.9. ქიფზ.მი-სა და მომხმარებელს შორის გაფორმებული ძირითადი
            ექვაირინგის ხელშეკრულება ითვლება გაგრძელებულად ყოველ ჯერზე იგივე
            ვადით და პირობებით, თუ არც ერთმა მხარემ არ აცნობა მეორე სმისი
            შეწყვეტის ან ხელშეკრულების არსებითი პირობების შეცვლის სურვილის
            შესახებ ხელშეკრულების მოქმედების ვადის გასვლამდე 30 (ოცდაათი)
            სამუშაო დღით ადრე;
          </p>
          <p>
            <b>10. მარეგულირებელი კანონმდებლობა და დავა</b>
          </p>
          <p>
            10.1. წესები და და პირობები რეგულირდება და განიმარტება საქართველოს
            კანონმდებლობის შესაბამისად.{" "}
          </p>
          <p>
            10.2. წესებსა და პირობებთან ან მის ინტერპრეტაციასთან/განმარტებასთან
            დაკავშირებული ნებისმიერი დავა ან უთანხმოება გადაწყდება მხარეებს
            შორის მოლაპარაკების გზით.{" "}
          </p>
          <p>
            10.3. თუ მხარეები 30 (ოცდაათი) კალენდარული დღის განმავლობაში
            შეთანხმებას ვერ მიაღწევენ მოლაპარაკების გზით, თითოეული მხარე
            უფლებამოსილია მიმართოს და დავას განიხილავს საქართველოს საერთო
            სასამართლოები.{" "}
          </p>
          <p>
            10.4. დავის მოლაპარაკების გზით დარეგულირების ან სასამართლო წესით
            გადაწყვეტის მიზნით, დავასთან დაკავშირებული მხარეთა შეტყობინებები,
            წერილები ან სხვა დოკუმენტაცია კომპანიისათვის ჩაბარებულად ჩაითვლება,
            თუ იგი მიწოდებული იქნება შემდეგ მისამართზე: საქართველო, ქ.თბილისი,
            ქ.თბილისი, თამარაშვილის ქ. N13თ.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>11. ფორს-მაჟორი</b>{" "}
          </p>
          <p>
            11.1. არც ერთ მხარეს არ დაეკისრება პასუხისმგებლობა წესებითა და
            პირობებით ნაკისრი ვალდებულებების მთლიანი ან ნაწილობრივი
            დარღვევისათვის, თუ აღნიშნული გამოწვეული იქნება ფორს-მაჟორული
            (დაუძლეველი ძალის გარემოებების გამო) გარემოებების გამო პირდაპირი და
            უშუალო ზემოქმედებით.{" "}
          </p>
          <p>
            11.2. წესებისა და პირობების მიზნებისათვის ფორს-მაჟორულ გარემოებებად
            (დაუძლეველი ძალის გარემობებად) მიიჩნევა წესებითა და პირობების ძალაში
            შესვლის შემდეგ წარმოშობილი მხარეებისათვის გადაულახავი, წინასწარ
            გაუთვალისწინებელი და მათი კონტროლისაგან დამოუკიდებელი ისეთი
            გარემოებები როგორიცაა: კომპანიის ტექნიკური სისტემების გათიშვა,
            კომპანიის პლატფორმის საშუალებით მონაცემთა გადაცემის შეუძლებლობა ან
            გაუმართაობა, საკომუნიკაციო ტერმინალების ან სატელეკომუნიკაციო
            აღჭურვილობის გაუმართაობა, ელექტროენერგიის ან/და ინტერნეტსერვისის
            მიწოდების შეწყვეტა, GPRS-ის გათიშვა, კომპანიის პლატფორმაზე
            განხორციელებული კიბერ შეტევა, კომპანიის პლატფორმის განახლებები,
            კომპანიის კონტრაქტორი მესამე მხარის გაუმართაობა ან
            ლოგისტიკის/საქმიანი ოპერაციების შეჩერება ან შეწყვეტა , სტიქიური
            უბედურებანი, ომი და საომარი მოქმედებანი, მთავრობის, სახელმწიფო,
            ადგილობრივი ან/და სხვა ადმინისტრაციული ორგანოების მიერ დაწესებული
            აკრძალვები და შეზღუდვები, პანდემის ან/და ეპიდემიის გავრცელა ან სხვა
            მსგავსი გარემოებანი.{" "}
          </p>
          <p>
            11.3. მხარე, რომლისთვისაც გამო შეუძლებელია წესებისა და პირობებით
            დაკისრებული ვალდებულებების შესრულება, ვალდებულია ფორს-მაჟორულ
            გარემოებების წარმოშობისა და მისი აღმოფხრის თაობაზე გონივრულ ვადაში
            შეატყობინოს მეორე მხარეს.{" "}
          </p>
          <p>
            11.4. მხარეების მიერ წესებითა და პირობებით ნაკისრი ვალდებულებების
            შესრულება გადაიდება ფორს-მაჟორული გარემოებების აღმოფხვრამდე.{" "}
          </p>
          <p>
            <b>12. კომუნიკაცია</b>{" "}
          </p>
          <p>
            12.1. მხარეთა მიერ წესებისა და პირობებით ნაკისრი ვალდებლებების
            შესრულების ან ამავე წესებით ან მოქმედი კანონმდებლობით მინიჭებული
            უფლებებისა და ინტერესების რელიზების ან დაცვისათვის, მათ შორის,
            წესებისა და პირობებით დარეგულირებულ საკითხებთან დაკავაშირებით,
            მომხმარებელსა და კომპანიის შორის კომუნიკაცია, ძირითადად ხორციელდება
            ელექტრონულად, მომხმარებლის მიერ კომპანიის პლატფორმაზე დაფიქსირებული
            ან/და კომპანიისათვის მიწოდებული ელექტრონული ფოსტისა მისამართზე ან/და
            მობილური ტელეფონის ნომერზე, ელექტრონული წერილებისა ან/და მოკლედ
            ტექსტური შეტყობინებების გაგზავნის გზით, თუმცა, კომპანია
            უფლებამოსილია გამოიყენოს მომხმარებლის შესაბამის მობილური ტელეფონის
            ნომერზე ზეპირი სატელეფონო კავშირი, მომხმარებლისათვის კომპანიის
            პლატფორმაზე “push” შეტყობინებები მიწოდება, მომხმარებლის სოციალური
            ქსელების „ჩათში“ მიმოწერა და სხვა საკომუნიკაციო არხები, რომელიც
            მითითებულია ან/და რომელის გამოყენებასაც ეთანხმება მომხმარებელი.{" "}
          </p>
          <p>
            12.2. მომხმარებელსა და კომპანიის შორის კომუნიკაცია ხორციელდება
            კონფიდენციალობის პოლიტიკის დაცვით, პერსონალურ მონაცემთა დამუშავების
            მართლზომიერი მიზნებისა და საფუძვლების გათვალისწინებით.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            <b>13. ანგარიშის გაუქმება და ანგარიშზე წვდომის შეზღუდვა</b>{" "}
          </p>
          <p>
            13.1. მომხმარებელი უფლებამოსილია ნებისმიერ დროს გააუქმოს მისი
            ანგარიში კომპანიის პლატფრომაზე. ანგარიშის გაუქმებისათვის
            მომხმარებელი უნდა დაუკავშირდეს კომპანის,წესებსა და პირობებში
            მითითებულ საკონტაქტო/ საკომუნიკაციო არხების საშუალებით.{" "}
          </p>
          <p>
            13.2. ანგარიშის გაუქმებისათვის მომხმარებელემა უნდა გაიაროს კომპანიის
            მიერ მითითებული იდენტიფიცირების პროცედურა და დაადასტუროს მის მიერ ამ
            ანგარიშის ფლობა.{" "}
          </p>
          <p>
            13.3. კომპანია უფლებამოსილია მომხმარებელს ცალმხრივად, წინასწარი
            შეტყობინების გარეშე, შეუწყვიტოს ან შეუზღუდოს უფლება, ისარგებლოს
            კომპანიის პლატფორმით ან/და მომსახურებით თუ მიიჩნევს, რომ დაირღვა
            წესებისა და პირობების ან/და საქართველოს მოქმედი კანონმდებლობის
            მოთხოვნები.{" "}
          </p>
          <p>
            13.4. იმ შემთხვევაში, თუ კომპანია გააუქმებს მომხმარებლის ანგარიშს ან
            შეზღუდავს მის გამოყენებას, იმ მიზეზით რომ მომხმარებელმა დაარღვია
            წესები და პირობები ან/და საქართველოს მოქმედი კანონმდებლობა,
            მომხმარებელი არ არის უფლებამოსილი თავისი ან სხვისი სახელით შექმნას
            ახალი ანგარიში.{" "}
          </p>
          <p>
            <b>14. სხვა პირობები</b>{" "}
          </p>
          <p>
            14.1. კომპანიის პლატფორმით სარგებლობის შეთანხმება მოქმედებს უვადოდ.{" "}
          </p>
          <p>
            14.2. წესებსა და პირობებში აღნიშნული მუხლის სათაურები გამოიყენება
            მხოლოდ მოხერხებულობისთვის და არ განსაზღვრავს ან/და ახდენს ზეგავლენას
            მუხლის შინაარსზე, განმარტებასა და ინტერპრეტაციაზე.{" "}
          </p>
          <p>
            14.3. თუ წესებისა და პირობების რომელიმე ნაწილი ან დებულება რაიმე
            მიზეზით იქნა გაუქმებული, ბათილად ცნობილი ან შეწყვეტილი, აღნიშნული
            ნაწილი ან დებულება აღარ იქნება გამოყენებული, რაც არ იქონიებს
            გავლენას წესებისა და პირობების სხვა დანარჩენი ნაწილების/დებულებების
            ნამდვილობაზე.{" "}
          </p>
          <p>
            14.4. მომხმარებელი, კომპანიის წერილობითი თანხმობის გარეშე, არ არის
            უფლებამოსილი ამ წესებიდან და პირობებიდან გამომდინარე უფლებები და
            მოთხოვნები გადასცეს ან ვალდებულებები გადააკისროს სხვა პირს.{" "}
          </p>
          <p>
            14.5. კომპანიის უფლებამოსილია წესებითა და პირობებით განსაზღვრული
            მისი ყველა უფლება და ვალდებულება გადასცეს ნებისმიერ პირს,
            მომხმარებლის წერილობითი თანხმობის გარეშე, იმ პირობით, რომ ეს მესამე
            პირი უზრუნველყოფს მომხმარებლის მიმართ იგივე ხარისხის ან უკეთესი
            გარანტიების გაცემასა და პასუხისმგებლობის აღებას.{" "}
          </p>
        </div>
      </div>
      <div style={{ pageBreakBefore: "always", pageBreakAfter: "always" }}>
        <div>
          <p>
            14.6. წინამდებარე წესების ამოქმედებამდე, კომპანიასა და მომხმარებელს
            შორის გაფორმებულ ექვაირინგის ხელშეკრულებაში, ამ წესების ამოქმედებით
            განხორციელდეს ცვლილება და სერვისის მიმწოდებლად განისაზღვროს
            კომპანია, რომელიც წარმოადგენს ამ წესის გამოქვეყნების თარიღამდე
            ნებისმიერ სახელშეკრულებო ურთიერთობებში, რომელშიც მხარე იყო
            მომხმარებელი, კომპანია და შპს ბიქეიენ301 ჯორჯიას (საიდენტიფიკაციო
            ნომერი: 405545227), შპს ბიქეიენ301 ჯორჯიას უფლებამონაცვლეს და ეს
            უკანასკნელი აღარ არის სახელშეკრულებო ურთიერთობების მხარე.
          </p>
          <p>
            14.7. წესებითა და პირობებით გათვალისწინებული ნებისმიერი უფლების ან
            ანაზღაურების საშუალების გამოყენებაზე კომპანიის მიერ თავის შეკავება
            ან გადავადება არ უნდა იქნას აღქმული, როგორც ამ უფლებაზე ან
            ანაზღაურებაზე უარის თქმად და აღნიშნულმა გავლენა არ უნდა მოახდინოს
            შემდგომში კომპანიის მიერ ამ უფლების გამოყენებაზე ან ანაზღაურების
            მიღებაზე.{" "}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Ge;
