import ReactDOM from "react-dom";

const EcommerceModal = ({ onClose, Icon, desc, subDesc }) => {
  return ReactDOM.createPortal(
    <>
      <div
        className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-50 "
        onClick={(e) => {
          if (e.currentTarget !== e.target) {
            return;
          }
          onClose();
        }}
      >
        <div className="w-[532px] h-[310px] mt-[164px] p-[40px] bg-[#fff] flex flex-col  items-center font-sans rounded-[15px]">
          <Icon />
          <p className="mt-[20px] font-[600] text-[24px] text-[#170738]">
            {desc}
          </p>
          <p className="text-center text-[16px] text-[#453960] mt-[15px]">
            {subDesc}
          </p>
          <button
            className="w-[223px] h-[50px] text-[#fff] bg-[#170738] rounded-[16px] mt-[40px]"
            onClick={onClose}
          >
            Ok
          </button>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default EcommerceModal;
