import { useQueryString } from "hooks";
import React, { useEffect, useRef, useState } from "react";
import Rating from "react-rating";
import JSEncrypt from "jsencrypt";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import {
  addReview,
  tipWithApplePay,
  tipWithCard,
  tipWithCardTbc,
  tipWithGooglePay,
} from "api";
import CustomInput from "components/CustomInput";
import { useNavigate } from "react-router-dom";
import TipModal from "./TipModal";
// import CustomInputModal from "./CustomTipModal";
import {
  baseCardPaymentMethod,
  baseRequest,
  cardPaymentMethod,
} from "features/bog/config";
import { BOG_URL } from "config";
import { acceptGooglePayPayment } from "features/bog/api";
import { MERCHANT_NAME } from "config";
import { GPAY_MERCHANT_ID } from "config";
import startApplePaySession from "features/apple/utils/startApplePaySession";
import axios from "axios";

const MERCHANT_IDENTIFIER = "merchant.bog.keepz";

export default function RateMerchant({
  onReviewDone,
  amount,
  tipsEnabled,
  receiverType,
  commisionAmount,
  receiverName,
}) {
  const [ratingDone, setRatingDone] = useState(false);
  // const [inputModal, setInputModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [tip, setTip] = useState();
  const [ratingStatus, setRatingStatus] = useState();

  const navigate = useNavigate();
  const [rating, setRating] = useState({
    stars: null,
    comment: null,
  });
  const { parseQuery: parsedQuery } = useQueryString();
  const {
    acquiringTransactionId: transactionId,
    paymentProviderEnum = "BOG",
    // receiverName = "",
    receiverId = "",
  } = parsedQuery;

  const gpayRef = useRef();
  const formRef = useRef(null);
  const inputRef = useRef(null);

  const paymentMethod = localStorage.getItem("paymentMethod");
  const { t } = useTranslation();

  const handleReview = async () => {
    try {
      const crypt = new JSEncrypt();
      crypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
      const payload = crypt.encrypt(
        JSON.stringify({
          acquiringTransactionId: transactionId,
          paymentProviderEnum: paymentProviderEnum,
          stars: rating.stars,
          comment: rating.comment,
        })
      );
      await addReview({
        encryptedData: payload,
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setRatingDone(true);
    }
  };

  const commisionPerncet = commisionAmount;

  function roundDecimalToNextHundredth(number) {
    // Multiply the number by 100, round it up to the nearest integer, then divide it by 100
    const errNum = (number * 100).toFixed(2);

    return Math.ceil(errNum) / 100;
  }

  const tipAmount = tip ? (amount * tip) / 100 : null;
  const roundedTip = tip ? roundDecimalToNextHundredth(tipAmount) : null;
  const commison =
    commisionPerncet && tip
      ? (roundedTip * Number(commisionPerncet)) / 100
      : null;
  const roundedCommision =
    commison && tip ? roundDecimalToNextHundredth(commison) : 0.0;

  const totalWithCommision =
    commisionPerncet && tip ? (roundedTip + roundedCommision).toFixed(2) : null;

  const handlTips = async () => {
    try {
      const acquiringType = localStorage.getItem("acquiring");
      if (paymentMethod === "card") {
        if (acquiringType == "tbc") {
          const { data } = await tipWithCardTbc(
            transactionId,
            roundedTip,
            tip,
            roundedCommision
          );

          const { links } = data;
          const [, secondElement] = links;
          const { uri } = secondElement;
          window.open(uri, "_self");
        } else {
          const { data } = await tipWithCard(
            transactionId,
            roundedTip,
            tip,
            roundedCommision
          );
          window.open(data._links.redirect.href, "_self");
        }
      } else if (paymentMethod === "googlePay") {
        const paymentDataRequest = Object.assign({}, baseRequest);
        paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
        paymentDataRequest.transactionInfo = {
          totalPriceStatus: "FINAL",
          totalPrice: (((Math.ceil(amount * tip) / 100) * 100) / 100).toFixed(
            2
          ),
          currencyCode: "GEL",
          countryCode: "GE",
        };
        paymentDataRequest.merchantInfo = {
          merchantName: MERCHANT_NAME,
          merchantId: GPAY_MERCHANT_ID,
        };
        let googlePayResponse;
        try {
          googlePayResponse = await tipWithGooglePay(
            transactionId,
            roundedTip,
            tip,
            roundedCommision
          );

          // googlePayResponse = await startGooglePayPayment({
          //   encryptedData: result,
          //   identifier: process.env.REACT_APP_INTEGRATOR_ID,
          // });
        } catch (err) {
          console.log(err);
          // dispatch({
          //   type: SET_PAYMENT_RESULT,
          //   payload: {
          //     show: true,
          //     type: err?.response?.data?.status,
          //   },
          // });
          // dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          return;
        }
        gpayRef.current
          .loadPaymentData(paymentDataRequest)
          .then(async (paymentData) => {
            try {
              const token =
                paymentData.paymentMethodData.tokenizationData.token;
              const cardNetwork =
                paymentData.paymentMethodData.info.cardNetwork;
              const {
                sessionId,
                params: { transaction_id: merchantTransactionId } = {},
              } = googlePayResponse;

              const {
                state,
                url = "",
                result: { status = "" } = {},
              } = await acceptGooglePayPayment(token, {
                sessionId,
                merchantTransactionId,
                cardBrand: cardNetwork,
              });
              if (status === "SUCCESS") {
                const redirectURL = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&amount=${(
                  (amount * tip) /
                  100
                ).toFixed(
                  2
                )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false`;
                window.open(redirectURL, "_self");
                return;
              } else if (state === "3ds2_prepare") {
                const version =
                  cardNetwork === "MASTERCARD" ? "2.2.0" : "2.1.0";
                // const iframeReturnUrl = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent((merchant || data)?.name)}&ratingEnabled=${!!(merchant?.reviewRequired || data?.reviewRequired)}`
                const options = {
                  method: "POST",
                  url: `${BOG_URL}/payment/${sessionId}/3ds2-prepare/accept`,
                  params: {
                    version,
                    "device.channel": "BRW",
                    ...(version === "2.1.0" && {
                      "device.browserAcceptHeader":
                        "application/json, text/javascript, /; q=0.01",
                    }),
                    "device.browserIP": "127.0.0.1",
                    "device.browserLanguage":
                      version === "2.1.0" ? "en-US" : "EN",
                    ...(version === "2.1.0" && {
                      "device.browserJavaEnabled": false,
                    }),
                    "device.browserColorDepth":
                      version === "2.2.0" ? "32" : "24",
                    "device.browserScreenHeight": "800",
                    "device.browserScreenWidth": "480",
                    "device.browserTZ": "180",
                    "device.browserUserAgent":
                      version === "2.1.0"
                        ? "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Mobile Safari/537.36"
                        : "Gecko",
                    ...(version === "2.2.0" && {
                      "device.browserJavascriptEnabled": false,
                    }),
                    challengeWindowSize: version === "2.2.0" ? "02" : "03",
                    // iframeReturnUrl,
                  },
                };
                const {
                  data: { url, post: { creq } = {} },
                } = await axios.request(options);
                if (!url || !creq) {
                  throw new Error("Something went wrong");
                }
                inputRef.current.value = creq;
                formRef.current.action = url;
                formRef.current.submit();
              } else if (state === "redirect") {
                window.open(url, "_self");
              } else {
                throw new Error("Unknow state");
              }
            } catch (err) {
              console.log(err);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (paymentMethod === "applePay") {
        const transactionIdPromise = tipWithApplePay(
          transactionId,
          roundedTip,
          tip,
          roundedCommision
        );

        startApplePaySession(
          {
            amount: totalWithCommision
              ? Number(totalWithCommision)
              : roundedTip,
            transactionIdPromise: transactionIdPromise,
          },
          (res) => {
            if (res?.status === 0) {
              const url = `https://app.keepz.me/success?acquiringTransactionId=${
                res.transactionId
              }&amount=${((amount * tip) / 100).toFixed(
                2
              )}&receiverName=${receiverName}&ratingEnabled=false&tipsEnabled=false`;
              window.open(url, "_self");
            } else {
              navigate(
                `/payment-failed?acquiringTransactionId=${res.transactionId}&receiverId=${receiverId}&receiverType=${receiverType}`
              );
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (!ratingDone) {
      const rateValues = ["Terrible", "Bad", "Normal", "Good", "Great"];
      const stars = Array.from(document.querySelector(".ratings").children);
      stars?.forEach((star, index) => {
        // star.setAttribute("data-value", t(`ratings.${rateValues[index]}`))
      });
    }
  }, [ratingDone, t]);

  const rateValues = ["Terrible", "Bad", "Normal", "Good", "Great"];

  //Initialize google pay
  useEffect(() => {
    if (paymentMethod !== "googlePay") {
      return;
    }
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      gpayRef.current = new google.payments.api.PaymentsClient({
        environment: "PRODUCTION", // Change to 'PRODUCTION' for production use
      });
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
      gpayRef.current
        .isReadyToPay(isReadyToPayRequest)
        .then(({ result }) => {
          if (result) {
            console.log(result);
          } else {
            throw new Error("Gpay not available");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  //Initialize apple pay
  useEffect(() => {
    if (window.ApplePaySession) {
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          MERCHANT_IDENTIFIER
        );
      promise
        .then((canMakePayments) => {
          if (canMakePayments) {
            console.log("can make apple payment");
          } else {
            throw new Error("can't make payments");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Apple pay not available");
    }
  }, []);

  return (
    <div className="flex flex-col gap-[10px]">
      <form ref={formRef} method="post" action="">
        <input type="hidden" name="creq" ref={inputRef} value="" />
        <noscript>
          <center>
            <br />
            Please click the submit button below.
            <br />
            <input type="submit" value="Submit" />
          </center>
        </noscript>
      </form>
      {showModal ? (
        <TipModal
          isSuccess={isSuccess}
          onClose={() => {
            setShowModal(false);
          }}
        />
      ) : null}
      <div
        className={`${styles.rateContainer}  text-center mx-auto rounded-lg h-44 relative `}
      >
        <div
          className="h-full py-6"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div>
            <span className="text-[#340E7E] text-lg">
              {t(`ratings.pleaseRate`)}
            </span>
            <p
              className="text-[#6C63FF] text-lg truncate"
              style={{ maxWidth: "70vw" }}
            >
              {receiverName}
            </p>
          </div>
          <div className="flex justify-center ">
            <Rating
              fractions={1}
              initialRating={rating.stars}
              onChange={(e) => {
                setRatingStatus(e);
                setRating({ ...rating, stars: e });
              }}
              className={`${styles.ratingWrapper} ratings  `}
              emptySymbol={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37.234"
                  height="35.636"
                  viewBox="0 0 37.234 35.636"
                >
                  <path
                    id="Path_29095"
                    data-name="Path 29095"
                    d="M10.441,5.188Q9.023,4.141,9.813,1.869L13-7.638,4.85-13.487q-2.014-1.45-1.434-3.078.548-1.66,2.965-1.627l10.006.081,3.045-9.571Q20.174-30,21.9-30q1.74,0,2.481,2.32l3.045,9.571,9.99-.081q2.433-.032,2.981,1.627.564,1.644-1.434,3.078L30.792-7.638,34,1.869q.79,2.272-.628,3.319-1.386,1.047-3.384-.419L21.9-1.176,13.809,4.77Q11.827,6.252,10.441,5.188Z"
                    transform="translate(-3.286 30.003)"
                    fill="#6c63ff"
                    opacity="0.2"
                  />
                </svg>
              }
              fullSymbol={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="37.234"
                  height="35.636"
                  viewBox="0 0 37.234 35.636"
                >
                  <path
                    id="Path_29091"
                    data-name="Path 29091"
                    d="M10.441,5.188Q9.023,4.141,9.813,1.869L13-7.638,4.85-13.487q-2.014-1.45-1.434-3.078.548-1.66,2.965-1.627l10.006.081,3.045-9.571Q20.174-30,21.9-30q1.74,0,2.481,2.32l3.045,9.571,9.99-.081q2.433-.032,2.981,1.627.564,1.644-1.434,3.078L30.792-7.638,34,1.869q.79,2.272-.628,3.319-1.386,1.047-3.384-.419L21.9-1.176,13.809,4.77Q11.827,6.252,10.441,5.188Z"
                    transform="translate(-3.286 30.003)"
                    fill="#fdcd2d"
                  />
                </svg>
              }
            />
          </div>
          {ratingStatus ? (
            <p className="mt-[-35px] text-[15px] text-[#8A8A8E]">
              {t(`ratings.${rateValues[ratingStatus - 1]}`)}
            </p>
          ) : null}
        </div>
      </div>
      {tipsEnabled ? (
        <>
          <div className="flex flex-col justify-center items-center  gap-[10px] ">
            <p className="text-[#6C63FF] text-[16px] font-sans">
              {t("tip")}:{" "}
              {tip ? (roundedTip + roundedCommision).toFixed(2) : 0.0} ₾
            </p>

            <div className="flex items-center  sm:w-[100%] md:w-[450px]  px-[20px] justify-between  flex-wrap">
              <button
                onClick={() => setTip(5)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 5 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                5%
              </button>
              <button
                onClick={() => setTip(10)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 10 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                10%
              </button>
              <button
                onClick={() => setTip(15)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 15 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                15%
              </button>
              <button
                onClick={() => setTip(20)}
                className={`py-[10px] px-[20px] h-[40px] ${
                  tip === 20 ? "bg-[#6C63FF] text-white" : "bg-[#FAFAFA]"
                } rounded-[16px] font-sans`}
              >
                20%
              </button>
              {/* <button
                disabled={isSuccess}
                // onClick={() =>
                //   navigate("/custom-tip", {
                //     state: { transactionId: transactionId },
                //   })
                // }
                onClick={() => setInputModal(true)}
                className={`py-[10px] px-[20px] h-[40px] bg-[#170738]/5 rounded-[16px] font-sans disabled:cursor-not-allowed`}
              >
                other
              </button> */}
            </div>
          </div>
          {/* <button
            className="w-[200px] p-[10px] rounded-[16px] bg-[#6C63FF] text-[#fff] m-auto disabled:cursor-not-allowed disabled:opacity-50  "
            disabled={isSuccess || !tip}
            onClick={handlTips}
          >
            Confirm tip
          </button> */}
        </>
      ) : null}
      {!ratingDone && (
        <div className={`${styles.commentContainer} mx-auto `}>
          <CustomInput
            placeholder={t("comment")}
            onChange={(e) => setRating({ ...rating, comment: e })}
            value={rating.comment}
            backgroundColor="#FAFAFA"
            width="100%"

            // marginTop="30px"
          />
        </div>
      )}
      {/* {!ratingDone && (
        <button
          className={styles.submitBTN}
          onClick={handleReview}
          disabled={!rating.stars && !rating.comment}
          style={{ opacity: !rating.stars && !rating.comment ? "50%" : "100%" }}
        >
          Send
        </button>
      )} */}

      <button
        className={`${styles.submitBTN} rounded-[16px]`}
        onClick={() => {
          if (rating.stars || rating.comment) {
            handleReview();
          }
          if (tip) {
            handlTips();
          }
        }}
        // style={{ opacity: !rating.stars && !rating.comment ? "50%" : "100%" }}
      >
        {t("buttons.send")}
      </button>

      <button
        onClick={onReviewDone}
        className="text-[#6C63FF] text-base pb-12 mt-[15px]"
      >
        {!ratingDone ? t("description.skip") : t("description.done")}
      </button>
      {/* {inputModal && (
        <CustomInputModal
          tip={tip}
          setTip={setTip}
          setShowModal={setShowModal}
          transactionId={transactionId}
          setIsSuccess={setIsSuccess}
          isSuccess={isSuccess}
          onClose={() => setInputModal(false)}
        />
      )} */}
    </div>
  );
}
