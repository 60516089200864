import { useEffect, useMemo, useRef, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { JSEncrypt } from "jsencrypt";
import useSWR from "swr";
import Loader from "components/Loader";
import useQueryString from "hooks/useQueryString";
import {
  bogTransaction,
  commonServiceInstance,
  orderCityPay,
  orderCityPayStatus,
  paymentServiceInstance,
  postTransactionAction,
  startApplePayment,
  tbcTransaction,
} from "api";
import TippingUser from "components/TippingUser";
import Select from "components/select/Select";
import SelectPaymentMethod from "components/modals/SelectPaymentMethod";
import Modal from "components/modals/TestModal";
import { Link, Navigate, useLocation } from "react-router-dom";
import CustomInput from "components/CustomInput";
import { useNavigate } from "react-router-dom";
import { Spinner } from "components/Spinner";
import PaymentFeedback from "components/PaymentFeedback";
import { MERCHANT_NAME, GPAY_MERCHANT_ID, BOG_URL } from "config";
import { acceptGooglePayPayment, startGooglePayPayment } from "./api";
import UserNotFound from "components/UserNotFound";
import {
  LAST_ACTIVE_ORDER,
  MERCHANT_PROFILE_URL,
  PRODUCT_URL,
  SYSTEM_STATUS_URL,
  TRANSACTION_STATUS,
  USER_PROFILE_URL,
} from "helpers/fetcher/fetcher.config";
import axios from "axios";
import paymentReducer from "./reducer/paymentReducer";
import {
  SET_AMOUNT,
  SET_APPLE_PAY_STATE,
  SET_GPAY_STATE,
  SET_INPUT_ERROR,
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_TYPE,
  SET_TOGGLE_PAYMENT_MODAL,
} from "./reducer/actions";
import {
  AVAILABLE_PAYMENT_TYPES,
  INITIAL_STATE,
  PAYMENT_SERVICE_STATE,
  QR_TYPE,
  baseCardPaymentMethod,
  baseRequest,
  cardPaymentMethod,
} from "./config";
import startApplePaySession from "features/apple/utils/startApplePaySession";
import fail from "../../assets/images/Fail2.svg";
import { isAndroid } from "helpers/deviceHelper/deviceHelper";
import PoweredByKeepz from "assets/PoweredByKeepz";
import { RATING_ALLOWED_STATUSES } from "constants";
import { decrypt, encrypt } from "utils/encryption";
import "../../index.css";
const MERCHANT_IDENTIFIER = "merchant.bog.keepz";

const BogPayment = () => {
  const [
    {
      amount,
      loadingPayment,
      gpayState,
      applePayState,
      inputError,
      paymentResult,
      showPaymentModal,
      paymentType,
    },
    dispatch,
  ] = useReducer(paymentReducer, INITIAL_STATE);

  const [description, setDescription] = useState("");
  const [acquiringType, setAcquiringType] = useState({});
  const [commisonAmount, setCommisionAmount] = useState();
  const formRef = useRef(null);
  const inputRef = useRef(null);
  const descriptionRef = useRef(null);

  localStorage.removeItem("integratorOrderId");

  const { pathname, search } = useLocation();

  const navigate = useNavigate();

  const { parseQuery } = useQueryString();
  const {
    toUserId = "",
    status = "",
    transactionId = "",
    amount: requestedAmount = "",
    qrType = "DEFAULT",
    receiverId = "",
    receiverType = "USER",
    integratorId = "",
    integratorOrderId: integratorCheckOrderId,
    productId,
  } = parseQuery;
  const sanitizeUUID = (uuid) =>
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      uuid
    )
      ? uuid
      : null;
  const receiverUserId = sanitizeUUID(toUserId || receiverId);
  localStorage.setItem("receiverUserId", receiverUserId);

  const { data: systemData } = useSWR(SYSTEM_STATUS_URL, async (url) => {
    const { data } = await commonServiceInstance.get(url);
    return data;
  });
  localStorage.setItem("url", pathname + search);

  const { data: { amount: requestedAmount1 } = {} } = useSWR(
    !!productId
      ? `${PRODUCT_URL}?ownerId=${receiverUserId}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}&productId=${productId}&ownerType=${receiverType}`
      : null,
    async (url) => {
      try {
        const {
          data: { encryptedData },
        } = await paymentServiceInstance.get(url);
        return decrypt(encryptedData);
      } catch (err) {
        console.log(err);
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.response?.status || "",
          },
        });
      }
    }
  );

  const { data: { integratorOrderId = integratorCheckOrderId } = {} } = useSWR(
    useMemo(() => {
      if (integratorId.length && qrType !== QR_TYPE.REQUEST) {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
        const encryptedData = encodeURIComponent(
          encrypt.encrypt(
            JSON.stringify({
              integratorId: integratorId,
              receiverId: receiverUserId,
            })
          )
        );

        return `${LAST_ACTIVE_ORDER}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&encryptedData=${encryptedData}`;
      } else {
        return null;
      }
    }, [integratorId, qrType, receiverUserId]),
    async (url) => {
      try {
        const {
          data: { encryptedData },
        } = await paymentServiceInstance.get(url);
        const encrypt = new JSEncrypt();
        encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
        const decryptedData = JSON.parse(encrypt.decrypt(encryptedData));
        const { amount: orderedAmount } = decryptedData;
        dispatch({ type: SET_AMOUNT, payload: orderedAmount });
        return decryptedData;
      } catch (err) {
        // if (integratorCheckOrderId || integratorOrderId) {
        //   const { redirectRequired, failRedirectUri } =
        //     await postTransactionAction(integratorOrderId);
        //   if (redirectRequired && failRedirectUri) {
        //     window.location.href = failRedirectUri;
        //     return;
        //   }
        // }
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.response?.data?.status,
          },
        });
      }
    }
  );
  const { data: { result: { status: transactionStatus } = {} } = {} } = useSWR(
    transactionId
      ? `${TRANSACTION_STATUS}?providerId=${transactionId}&fromMobile=false`
      : null
  );

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const gpayRef = useRef();

  const availablePaymentOptions = useMemo(
    () => [
      ...(acquiringType?.availableAcquiringTypes?.STANDARD
        ? [AVAILABLE_PAYMENT_TYPES.CARD]
        : []),
      ...(gpayState === PAYMENT_SERVICE_STATE.AVAILABLE &&
      acquiringType?.availableAcquiringTypes?.GOOGLE_PAY
        ? [AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY]
        : []),
      ...(applePayState === PAYMENT_SERVICE_STATE.AVAILABLE &&
      acquiringType?.availableAcquiringTypes?.APPLE_PAY
        ? [AVAILABLE_PAYMENT_TYPES.APPLE_PAY]
        : []),
      ...(acquiringType?.availableAcquiringTypes?.CRYPTO
        ? [AVAILABLE_PAYMENT_TYPES.BINANCE_PAY]
        : []),
    ],
    [gpayState, applePayState, acquiringType]
  );

  const { data, error: userError } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const { data: merchant, error: merchantError } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const isAmountInputDisabled =
    data?.amountForDefaultQR ||
    merchant?.amountForDefaultQR ||
    qrType === QR_TYPE.CHECK ||
    qrType === QR_TYPE.REQUEST ||
    qrType === QR_TYPE.CUSTOM;

  const fixedAmount = useMemo(
    () =>
      amount
        ? Number(String(amount).replace(/,/g, ".")).toFixed(2)
        : requestedAmount ||
          requestedAmount1 ||
          data?.amountForDefaultQR ||
          merchant?.amountForDefaultQR,
    [amount, requestedAmount, requestedAmount1, data, merchant]
  );

  const { defaultMin, defaultMax } = { defaultMin: 0.1, defaultMax: 999.99 };
  const minValDB =
    data?.transactionMinAmount || merchant?.transactionMinAmount || defaultMin;
  const maxValDB =
    data?.transactionMaxAmount || merchant?.transactionMaxAmount || defaultMax;

  const { data: commisonUsers, error: commisonUserError } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}/commissions?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const { data: commisonMerchant, error: commisonMerhcantError } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}/commission?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const senderCommissionPercentApple =
    commisonUsers?.find((item) => item.acquiringType === "APPLE_PAY") ||
    commisonMerchant?.find((item) => item.acquiringType === "APPLE_PAY");

  const senderCommissionPercentGoogle =
    commisonUsers?.find((item) => item.acquiringType === "GOOGLE_PAY") ||
    commisonMerchant?.find((item) => item.acquiringType === "GOOGLE_PAY");

  const senderCommissionPercentStandard =
    commisonUsers?.find((item) => item.acquiringType === "STANDARD") ||
    commisonMerchant?.find((item) => item.acquiringType === "STANDARD");

  // const rangedStandardCommission =
  //   commisonUsers?.filter((item) => item.acquiringType == "STANDARD") ||
  //   commisonMerchant?.filter((item) => item.acquiringType === "STANDARD");

  // const senderCommissionPercentStandard = rangedStandardCommission?.find(
  //   (item) => item.startAmount <= amount
  // );

  const senderCommissionPercentCrypto =
    commisonUsers?.find((item) => item.acquiringType === "CRYPTO") ||
    commisonMerchant?.find((item) => item.acquiringType === "CRYPTO");

  const handleInputChange = (e) => {
    const inputMax = 9999.99;
    const val = e.target.value.trim();

    const originalValue = val.startsWith("₾") ? val.slice(1, val.length) : val;
    let value = originalValue.replace(/[^0-9.,]*/g, "");
    value = value.replace(/\.{2,}/g, ".");
    value = value.replace(/\.,/g, ",");
    value = value.replace(/\,\./g, ",");
    value = value.replace(/\,{2,}/g, ",");
    value = value.replace(/\.[0-9]+\./g, ".");
    value = value.replace(/,/g, ".");

    if (
      value === "." ||
      (val.replaceAll(",", ".").match(/\./g) || []).length > 1 ||
      value.length > String(inputMax).length
    ) {
      return;
    }
    if (value.includes(".")) {
      const secondPortion = value.split(".")[1] || "";
      value =
        secondPortion.length > 2
          ? value.toString().slice(0, value.length - (secondPortion.length - 2))
          : value;
    }
    dispatch({
      type: SET_INPUT_ERROR,
      payload: Number(value) < minValDB || Number(value) > maxValDB,
    });
    if (val === "₾" || !val.length) {
      dispatch({ type: SET_AMOUNT, payload: "" });
      return;
    }
    if (Number(value) > inputMax) {
      return;
    }
    dispatch({ type: SET_AMOUNT, payload: value });
  };

  const changePaymentType = (type) => {
    dispatch({ type: SET_PAYMENT_TYPE, payload: type });
    dispatch({ type: SET_TOGGLE_PAYMENT_MODAL });
  };

  const handlePaymentResulClose = () => {
    if (status || transactionId) {
      navigate({
        pathname,
        search: window.location.search.replace("&status=FAIL", ""),
      });
    }
    dispatch({
      type: SET_PAYMENT_RESULT,
      payload: {
        show: false,
      },
    });
  };
  useEffect(() => {
    localStorage.removeItem("binance");
  }, []);

  useEffect(() => {
    const handlePopState = async () => {
      if (!localStorage.getItem("binance")) {
        return;
      }

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
      const payment_url = localStorage.getItem("binance");
      const payload = {
        externalId: payment_url,
      };
      const encryptedData = encodeURIComponent(
        encrypt.encrypt(JSON.stringify(payload))
      );
      const { encryptedData: res } = await orderCityPayStatus(
        `encryptedData=${encryptedData}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      );
      encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const { status, id } = JSON.parse(encrypt.decrypt(res));
      if (status) {
        if (RATING_ALLOWED_STATUSES.includes(status)) {
          const redirectURL = `/success?acquiringTransactionId=${id}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
            (merchant || data)?.name
          )}&ratingEnabled=${!!(
            merchant?.reviewRequired || data?.reviewRequired
          )}`;
          navigate(redirectURL);
        } else {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
            },
          });
        }
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      localStorage.removeItem("binance");
    };
  }, [merchant, data]);

  const standardPriorityArray =
    acquiringType?.availableAcquiringTypes?.STANDARD?.sort(
      (a, b) => a.priority - b.priority
    );

  const handlePayment = async (e) => {
    e.preventDefault();
    if (orderNotFound) {
      window.location.reload();
    }

    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }
    if (
      (data || merchant)?.descriptionRequired &&
      !description.length &&
      descriptionRef?.current
    ) {
      return descriptionRef.current.focus();
    }
    const lang = language === "ge" ? "KA" : "EN";
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);

    dispatch({ type: SET_LOADING_PAYMENT, payload: true });

    if (paymentType === AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY) {
      localStorage.setItem("acquiring", "bog");
      localStorage.setItem("paymentMethod", "googlePay");
      const paymentDataRequest = Object.assign({}, baseRequest);
      paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
      paymentDataRequest.transactionInfo = {
        totalPriceStatus: "FINAL",
        totalPrice: fixedAmount,
        currencyCode: "GEL",
        countryCode: "GE",
      };
      paymentDataRequest.merchantInfo = {
        merchantName: MERCHANT_NAME,
        merchantId: GPAY_MERCHANT_ID,
      };
      const payload = {
        amount: Number(fixedAmount),
        receiverType: receiverType || "User",
        ...(totalWithCommision?.comissionGoogle && {
          senderCommissionAmount: totalWithCommision.comissionGoogle,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId: integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        qrType: qrType,
        currency: "GEL",
        fromMobile: false,
        receiverId: receiverUserId,
        lang,
      };

      const result = encrypt.encrypt(JSON.stringify(payload));
      let googlePayResponse;
      try {
        googlePayResponse = await startGooglePayPayment({
          encryptedData: result,
          identifier: process.env.REACT_APP_INTEGRATOR_ID,
        });
      } catch (err) {
        if (err?.response?.data?.status === "PRECONDITION_FAILED") {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: "PRECONDITION_FAILED",
            },
          });
          return;
        }
        if (err?.response?.data?.status === "GONE") {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: "GONE",
            },
          });
          return;
        }
        if (err?.response?.status == 425) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: 425,
            },
          });
          return;
        }
        if (err?.response?.status == 429) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: 429,
            },
          });
          return;
        }
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.response?.data?.status,
          },
        });

        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        return;
      }

      gpayRef.current
        .loadPaymentData(paymentDataRequest)
        .then(async (paymentData) => {
          try {
            const token = paymentData.paymentMethodData.tokenizationData.token;
            const cardNetwork = paymentData.paymentMethodData.info.cardNetwork;
            const {
              sessionId,
              params: { transaction_id: merchantTransactionId } = {},
            } = googlePayResponse;

            const {
              state,
              url = "",
              result: { status = "" } = {},
            } = await acceptGooglePayPayment(token, {
              sessionId,
              merchantTransactionId,
              cardBrand: cardNetwork,
            });
            if (status === "SUCCESS") {
              const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(merchant?.tipsEnabled || data?.tipsEnabled)}`;
              navigate(redirectURL);
              return;
            } else if (state === "in_progress") {
              navigate(
                `/pending?token=${sessionId}&acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
                  (merchant || data)?.name
                )}&ratingEnabled=${!!(
                  merchant?.reviewRequired || data?.reviewRequired
                )}&tipsEnabled=${!!(
                  merchant?.tipsEnabled || data?.tipsEnabled
                )}&receiverId=${receiverUserId}`
              );
            } else if (state === "3ds2_prepare") {
              const version = cardNetwork === "MASTERCARD" ? "2.2.0" : "2.1.0";
              // const iframeReturnUrl = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent((merchant || data)?.name)}&ratingEnabled=${!!(merchant?.reviewRequired || data?.reviewRequired)}`
              const options = {
                method: "POST",
                url: `${BOG_URL}/payment/${sessionId}/3ds2-prepare/accept`,
                params: {
                  version,
                  "device.channel": "BRW",
                  ...(version === "2.1.0" && {
                    "device.browserAcceptHeader":
                      "application/json, text/javascript, /; q=0.01",
                  }),
                  "device.browserIP": "127.0.0.1",
                  "device.browserLanguage":
                    version === "2.1.0" ? "en-US" : "EN",
                  ...(version === "2.1.0" && {
                    "device.browserJavaEnabled": false,
                  }),
                  "device.browserColorDepth": version === "2.2.0" ? "32" : "24",
                  "device.browserScreenHeight": "800",
                  "device.browserScreenWidth": "480",
                  "device.browserTZ": "180",
                  "device.browserUserAgent":
                    version === "2.1.0"
                      ? "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Mobile Safari/537.36"
                      : "Gecko",
                  ...(version === "2.2.0" && {
                    "device.browserJavascriptEnabled": false,
                  }),
                  challengeWindowSize: version === "2.2.0" ? "02" : "03",
                  // iframeReturnUrl,
                },
              };

              const {
                data: { url, post = {} },
              } = await axios.request(options);

              if (!url) {
                throw new Error("Something went wrong");
              }

              formRef.current.action = url;
              if ("threeDSMethodData" in post) {
                localStorage.setItem("sessionId", sessionId);
                inputRef.current.name = "threeDSMethodData";
                inputRef.current.value = post.threeDSMethodData;
              } else {
                inputRef.current.name = "creq";
                inputRef.current.value = post.creq;
              }

              formRef.current.submit();
            } else if (state === "redirect") {
              window.open(url, "_self");
            } else {
              throw new Error("Unknow state");
            }
          } catch (err) {
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: err?.response?.data?.status,
              },
            });
          }
        })
        .catch(() => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    } else if (paymentType === AVAILABLE_PAYMENT_TYPES.APPLE_PAY) {
      localStorage.setItem("acquiring", "bog");
      localStorage.setItem("paymentMethod", "applePay");
      try {
        const startApplePayload = {
          amount: Number(fixedAmount),
          receiverType: receiverType || "User",
          ...(totalWithCommision?.comissionApple && {
            senderCommissionAmount: totalWithCommision.comissionApple,
          }),
          ...(integratorId && { integratorId }),
          ...((integratorOrderId || productId) && {
            integratorOrderId: integratorOrderId || productId,
          }),
          ...(!!description.length && { description }),
          qrType: qrType,
          currency: "GEL",
          fromMobile: false,
          receiverId: receiverUserId,
          lang,
        };
        const result = encrypt.encrypt(JSON.stringify(startApplePayload));
        const transactionIdPromise = startApplePayment({
          encryptedData: result,
          identifier: process.env.REACT_APP_INTEGRATOR_ID,
        });
        startApplePaySession(
          {
            amount: Number(totalWithCommision?.totalApple || fixedAmount),
            transactionIdPromise: transactionIdPromise,
            receiverType: receiverType,
            receiverName: (merchant || data)?.name,
            ratingEnabled: !!(merchant?.reviewRequired || data?.reviewRequired),
            tipsEnabled: !!(merchant?.tipsEnabled || data?.tipsEnabled),
            receiverId: receiverUserId,
            navigate: navigate,
          },
          (res) => {
            if (res?.status === 0) {
              const url = `/success?acquiringTransactionId=${
                res.transactionId
              }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
                (merchant || data)?.name
              )}&ratingEnabled=${!!(
                merchant?.reviewRequired || data?.reviewRequired
              )}&tipsEnabled=${!!(merchant?.tipsEnabled || data?.tipsEnabled)}`;
              navigate(url);
            } else {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
              if (res?.status == "PRECONDITION_FAILED") {
                dispatch({
                  type: SET_PAYMENT_RESULT,
                  payload: {
                    show: true,
                    type: "PRECONDITION_FAILED",
                  },
                });
                return;
              }
              if (res?.status == "GONE") {
                dispatch({
                  type: SET_PAYMENT_RESULT,
                  payload: {
                    show: true,
                    type: "GONE",
                  },
                });
                return;
              }

              async function failRedirct() {
                if (integratorCheckOrderId || integratorOrderId) {
                  const { redirectRequired, failRedirectUri } =
                    await postTransactionAction(integratorOrderId);
                  if (redirectRequired && failRedirectUri) {
                    window.location.href = failRedirectUri;
                    return;
                  }
                }
              }
              failRedirct();
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: "",
                },
              });
            }
          }
        );
      } catch (err) {
        async function failRedirct() {
          if (integratorCheckOrderId || integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
        }
        failRedirct();
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.response?.data?.status,
          },
        });
      }
    } else if (paymentType === AVAILABLE_PAYMENT_TYPES.BINANCE_PAY) {
      localStorage.setItem("acquiring", "bog");
      localStorage.setItem("paymentMethod", "card");
      const encryptedData = encrypt.encrypt(
        JSON.stringify({
          amount: Number(fixedAmount),
          toBenefitId: receiverUserId,
          beneficialType: receiverType,
          currency: "GEL",
          ...(totalWithCommision?.comissionCrypto && {
            senderCommissionAmount: totalWithCommision.comissionCrypto,
          }),
          ...(integratorId && { integratorId }),
          ...((integratorOrderId || productId) && {
            integratorOrderId: integratorOrderId || productId,
          }),
          ...(!!description.length && { description }),
          qrType: qrType,
        })
      );
      orderCityPay({
        encryptedData: encryptedData,
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
      })
        .then(({ encryptedData }) => {
          encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
          const { payment_url } = JSON.parse(encrypt.decrypt(encryptedData));
          localStorage.setItem("binance", payment_url);
          window.open(payment_url, "_self");
        })
        .catch(async (err) => {
          if (err?.response?.status == 425) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 425,
              },
            });
            return;
          }
          if (err?.response?.status == 429) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 429,
              },
            });
            return;
          }
          if (err?.response?.data?.status == "PRECONDITION_FAILED") {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: "PRECONDITION_FAILED",
              },
            });
            return;
          }
          if (err?.response?.data?.status == "GONE") {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: "GONE",
              },
            });
            return;
          }

          if (integratorCheckOrderId || integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.response?.data?.status,
            },
          });
        })
        .finally(() => {
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        });
    } else {
      localStorage.setItem("paymentMethod", "card");
      const payload = {
        amount: fixedAmount,
        receiverId: receiverUserId,
        receiverType,
        qrType: qrType,
        ...(totalWithCommision?.comissionStandard && {
          senderCommissionAmount: totalWithCommision.comissionStandard,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId: integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
      };
      const result = encrypt.encrypt(JSON.stringify(payload));

      if (standardPriorityArray[0]?.acquiringName == "BOG") {
        localStorage.setItem("acquiring", "bog");
        bogTransaction({
          identifier: process.env.REACT_APP_INTEGRATOR_ID,
          encryptedData: result,
        })
          .then(({ _links: links, id }) => {
            const { redirect: { href = "" } = {} } = links || {};
            window.open(href, "_self");
          })
          .catch(async (err) => {
            if (err?.response?.status == 425) {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: 425,
                },
              });
              return;
            }
            if (err?.response?.status == 429) {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: 429,
                },
              });
              return;
            }

            if (err?.response?.data?.status === "PRECONDITION_FAILED") {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: "PRECONDITION_FAILED",
                },
              });
              return;
            }

            if (err?.response?.data?.status === "GONE") {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: "GONE",
                },
              });
              return;
            }

            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: err?.response?.data?.status,
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            }, 3000);
          });
      } else if (standardPriorityArray[0]?.acquiringName == "TBC") {
        localStorage.setItem("acquiring", "tbc");
        tbcTransaction({
          identifier: process.env.REACT_APP_INTEGRATOR_ID,
          encryptedData: result,
        })
          .then((data) => {
            console.log(data, "dataaa");
            const { links } = data;
            const [, secondElement] = links;
            const { uri } = secondElement;
            window.open(uri, "_self");
          })
          .catch(async (err) => {
            if (err?.response?.status == 425) {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: 425,
                },
              });
              return;
            }
            if (err?.respons?.status == 429) {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: 429,
                },
              });
              return;
            }
            if (integratorCheckOrderId || integratorOrderId) {
              const { redirectRequired, failRedirectUri } =
                await postTransactionAction(integratorOrderId);
              if (redirectRequired && failRedirectUri) {
                window.location.href = failRedirectUri;
                return;
              }
            }
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: err?.response?.data?.status,
              },
            });
          })
          .finally(() => {
            setTimeout(() => {
              dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            }, 3000);
          });
      }
    }
  };
  // Initialize google pay
  useEffect(() => {
    if (!isAndroid) {
      dispatch({
        type: SET_GPAY_STATE,
        payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
      });
      return;
    }
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      gpayRef.current = new google.payments.api.PaymentsClient({
        environment: "PRODUCTION", // Change to 'PRODUCTION' for production use
      });
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
      gpayRef.current
        .isReadyToPay(isReadyToPayRequest)
        .then(({ result }) => {
          if (result) {
            dispatch({
              type: SET_GPAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE,
            });
            if (acquiringType?.availableAcquiringTypes?.GOOGLE_PAY) {
              dispatch({
                type: SET_PAYMENT_TYPE,
                payload: AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY,
              });
            }
          } else {
            throw new Error("Gpay not available");
          }
        })
        .catch((err) => {
          dispatch({
            type: SET_GPAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [acquiringType]);

  const senderCommissionBool = commisonUsers || commisonMerchant;
  const totalWithCommision = useMemo(() => {
    if (!senderCommissionBool || !amount) {
      return;
    }
    // const comission = (amount * senderCommissionPercent) / 100;
    // const comissionRounded = Math.ceil(comission * 100) / 100;
    // const comissionApple = (amount * senderCommissionPercentApple) / 100;
    // const comissionRoundedApple =
    //   Math.ceil((comissionApple * 100).toFixed(2)) / 100;
    let comissionApple;
    let comissionRoundedApple;
    if (senderCommissionPercentApple?.rateType == "FIXED") {
      comissionApple = null;
      comissionRoundedApple = senderCommissionPercentApple?.senderCommission;
    } else {
      comissionApple =
        (amount * senderCommissionPercentApple?.senderCommission) / 100;
      comissionRoundedApple =
        Math.ceil((comissionApple * 100).toFixed(2)) / 100;
    }
    // const comissionGoogle = (amount * senderCommissionPercentGoogle) / 100;
    // const comissionRoundedGoogle =
    //   Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
    let comissionGoogle;
    let comissionRoundedGoogle;
    if (senderCommissionPercentGoogle?.rateType == "FIXED") {
      comissionGoogle = null;
      comissionRoundedGoogle = senderCommissionPercentGoogle?.senderCommission;
    } else {
      comissionGoogle =
        (amount * senderCommissionPercentGoogle?.senderCommission) / 100;
      comissionRoundedGoogle =
        Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
    }
    let comissionStandard;
    let comissionRoundedStandard;
    if (senderCommissionPercentStandard?.rateType == "FIXED") {
      comissionStandard = null;
      comissionRoundedStandard =
        senderCommissionPercentStandard?.senderCommission;
    } else {
      comissionStandard =
        (amount * senderCommissionPercentStandard?.senderCommission) / 100;
      comissionRoundedStandard =
        Math.ceil((comissionStandard * 100).toFixed(2)) / 100;
    }
    // const comissionCrypto = (amount * senderCommissionPercentCrypto) / 100;
    // const comissionRoundedCrypto =
    //   Math.ceil((comissionCrypto * 100).toFixed(2)) / 100;
    let comissionCrypto;
    let comissionRoundedCrypto;
    if (senderCommissionPercentCrypto?.rateType == "FIXED") {
      comissionCrypto = null;
      comissionRoundedCrypto = senderCommissionPercentCrypto?.senderCommission;
    } else {
      comissionCrypto =
        (amount * senderCommissionPercentCrypto?.senderCommission) / 100;
      comissionRoundedCrypto =
        Math.ceil((comissionCrypto * 100).toFixed(2)) / 100;
    }
    return {
      totalApple: (Number(amount) + Number(comissionRoundedApple)).toFixed(2),
      comissionApple: parseFloat(comissionRoundedApple),
      totalGoogle: (Number(amount) + Number(comissionRoundedGoogle)).toFixed(2),
      comissionGoogle: parseFloat(comissionRoundedGoogle),
      totalStandard: (
        Number(amount) + Number(comissionRoundedStandard)
      ).toFixed(2),
      comissionStandard: parseFloat(comissionRoundedStandard),
      totalCrypto: (Number(amount) + Number(comissionRoundedCrypto)).toFixed(2),
      comissionCrypto: parseFloat(comissionRoundedCrypto),
    };
  }, [
    amount,
    senderCommissionPercentApple,
    senderCommissionPercentCrypto,
    senderCommissionPercentGoogle,
    senderCommissionPercentStandard,
    senderCommissionBool,
  ]);

  // console.log(totalWithCommision, "totalWithCommison");

  // Initialize apple pay
  useEffect(() => {
    if (window.ApplePaySession) {
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          MERCHANT_IDENTIFIER
        );
      promise
        .then((canMakePayments) => {
          if (canMakePayments) {
            if (acquiringType?.availableAcquiringTypes?.APPLE_PAY) {
              // changePaymentType("apple");
              dispatch({
                type: SET_PAYMENT_TYPE,
                payload: AVAILABLE_PAYMENT_TYPES.APPLE_PAY,
              });
            }

            dispatch({
              type: SET_APPLE_PAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE,
            });
          } else {
            throw new Error("can't make payments");
          }
        })
        .catch(() => {
          dispatch({
            type: SET_APPLE_PAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    } else {
      dispatch({
        type: SET_APPLE_PAY_STATE,
        payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
      });
    }
  }, [acquiringType]);

  useEffect(() => {
    if (
      applePayState !== PAYMENT_SERVICE_STATE.LOADING &&
      gpayState !== PAYMENT_SERVICE_STATE.LOADING &&
      ((status && status.toString() === "FAIL") ||
        transactionStatus === "FAILED")
    ) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "ERROR",
        },
      });
    }
  }, [status, applePayState, transactionStatus, gpayState]);

  useEffect(() => {
    let amountSet =
      requestedAmount ||
      requestedAmount1 ||
      data?.amountForDefaultQR ||
      merchant?.amountForDefaultQR;
    if (amountSet && !isNaN(amountSet)) {
      dispatch({ type: SET_AMOUNT, payload: amountSet });
    }
  }, [
    dispatch,
    requestedAmount,
    requestedAmount1,
    merchant?.amountForDefaultQR,
    data?.amountForDefaultQR,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await paymentServiceInstance.get(
          `/api/v1/acquiring/choice/external?receiverId=${encodeURI(
            receiverId
          )}&receiverType=${encodeURI(receiverType)} `
        );

        setAcquiringType(data);

        return decrypt(data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (paymentType === "card" && amount) {
      if (senderCommissionPercentStandard?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalStandard);
      }
    } else if (paymentType === "gpay" && amount) {
      if (senderCommissionPercentGoogle?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalGoogle);
      }
    } else if (paymentType === "apple" && amount) {
      if (senderCommissionPercentApple?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalApple);
      }
    } else if (paymentType === "binance" && amount) {
      if (senderCommissionPercentCrypto?.senderCommission == 0) {
        setCommisionAmount(0);
      } else {
        setCommisionAmount(totalWithCommision?.totalCrypto);
      }
    }
  }, [amount, paymentType, totalWithCommision]);

  useEffect(() => {
    if (
      !acquiringType?.availableAcquiringTypes?.APPLE_PAY &&
      !acquiringType?.availableAcquiringTypes?.GOOGLE_PAY &&
      !acquiringType?.availableAcquiringTypes?.STANDARD &&
      acquiringType?.availableAcquiringTypes?.CRYPTO
    ) {
      changePaymentType("binance");
    }
  }, [acquiringType]);

  if (integratorCheckOrderId || integratorOrderId) {
    localStorage.setItem(
      "integratorOrderId",
      integratorCheckOrderId || integratorOrderId
    );
  }

  if (
    applePayState === PAYMENT_SERVICE_STATE.LOADING ||
    gpayState === PAYMENT_SERVICE_STATE.LOADING
  ) {
    return <Loader />;
  }

  if (
    userError?.response?.status === 404 ||
    merchantError?.response?.status === 404
  ) {
    return <UserNotFound />;
  }

  if (systemData?.systemStatus === "PASSIVE") {
    return <Navigate to="/downtime" />;
  }

  if (transactionStatus === "SUCCESS" || transactionStatus === "REFUND") {
    const redirectURL = `/success?receiverId=${receiverUserId}&acquiringTransactionId=${transactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
      (merchant || data)?.name
    )}&ratingEnabled=${!!(receiverType === "BRANCH")}`;
    return <Navigate to={redirectURL} />;
  }

  const orderNotFound = ["CONFLICT", "NOT_FOUND"].includes(paymentResult?.type);
  return (
    <>
      <main className="max-w-[700px] mx-auto h-full flex flex-col justify-between">
        <div style={{ justifySelf: "flex-start" }}>
          <TippingUser user={data || merchant} />
          <div className="h-[40px] rounded-t-[32px] bg-white mt-[-40px]"></div>
          <div className="flex flex-col w-full px-[25px] items-center  ">
            <form
              className="w-full text-center flex flex-col justify-between"
              onSubmit={(e) => e.preventDefault()}
              autoComplete="off"
            >
              {orderNotFound ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={fail} style={{ width: "50px" }} alt="fail" />
                  <p
                    style={{
                      color: "#3F2C65",
                      fontSize: "16px",
                      marginTop: "19px",
                      whiteSpace: "pre-line",
                    }}
                  >
                    {t("description.qrCheckError")}
                  </p>
                </div>
              ) : (
                <div className="relative">
                  {qrType === QR_TYPE.CHECK || qrType === QR_TYPE.REQUEST ? (
                    <p className="text-sm text-gray-500">
                      {t("requestedPayment")}
                    </p>
                  ) : (
                    <p className="text-sm text-black-400">{t("enterAmount")}</p>
                  )}
                  <div className="flex align-center justify-center">
                    <div className="flex items-center relative">
                      <span
                        className={`${
                          amount.length ? "text-blue-500" : "text-blue-100"
                        } text-[36px] absolute bottom-1 left-[-25px]`}
                      >
                        ₾
                      </span>
                    </div>
                    <div className="mt-1 relative after:content-[''] after:absolute after:bottom-0 after:left-[50%] after:translate-x-[-50%] after:w-[90px] after:h-[1px] after:bg-blue-100">
                      <input
                        style={{
                          width:
                            Math.max(amount ? String(amount).length : 4, 2) +
                            "ch",
                        }}
                        size="1"
                        className="main-input min-w-0 text-[48px] outline-none text-center   disabled:bg-transparent "
                        id="amount"
                        type="tel"
                        disabled={isAmountInputDisabled}
                        inputMode="decimal"
                        autoFocus
                        autoComplete="off"
                        value={amount}
                        onChange={handleInputChange}
                        placeholder="0.00"
                      />
                    </div>
                  </div>
                  {!inputError && commisonAmount ? (
                    <p
                      className="w-full mt-3 absolute bottom-[-2.5rem] left-[50%] translate-x-[-50%]"
                      style={{ color: "#6C63FF" }}
                    >
                      {t("description.totalPayable", {
                        total: commisonAmount,
                      })}
                    </p>
                  ) : null}
                  {inputError ? (
                    <p className="text-red w-full mt-3 absolute bottom-[-2.5rem] left-[50%] translate-x-[-50%]">
                      {t("description.enterAmount", { minValDB, maxValDB })}
                    </p>
                  ) : null}
                </div>
              )}
              {(data || merchant)?.descriptionRequired && (
                <CustomInput
                  placeholder="Description"
                  ref={descriptionRef}
                  onChange={(e) => {
                    setDescription(e);
                  }}
                  value={description}
                  backgroundColor="#FAFAFA"
                  width="100%"
                  margin="47px 0 0 0"
                  borderRadius="16px"
                />
              )}
              <div
                className={`mt-${
                  (data || merchant)?.descriptionRequired ? "5" : "[60px]"
                }`}
              >
                {/* {Object.keys(acquiringType?.availableAcquiringTypes)?.length > */}
                {/* 0 ? ( */}
                <Select
                  style={{
                    borderRadius: "13px",
                    display: orderNotFound && "none",
                  }}
                  onClick={() => dispatch({ type: SET_TOGGLE_PAYMENT_MODAL })}
                  type={paymentType}
                />
                {/* ) : ( */}
                {/* <p>Payment unavailable</p> */}
                {/* )} */}

                <div>
                  <button
                    className="inline-flex justify-center gap-2 w-full bg-[#6C63FF] p-4 text-[20px] text-white mt-4 rounded-2xl disabled:bg-[#6C63FF60] text-center items-center"
                    style={{
                      height: "50px",
                      borderRadius: "13px",
                      ...(orderNotFound && {
                        backgroundColor: "rgba(63, 44, 101, 0.2)",
                        color: "black",
                      }),
                    }}
                    type="submit"
                    disabled={
                      !orderNotFound &&
                      (!Number(amount) || inputError || loadingPayment)
                    }
                    onClick={handlePayment}
                  >
                    {orderNotFound ? t("retry") : t("description.pay")}
                    {loadingPayment ? <Spinner /> : null}
                  </button>
                </div>
              </div>

              <div className="mt-[15px] text-[11px]">
                <p className="text-gray-100">{t("description.agreetc")}</p>
                <Link className="text-black-200" to="/terms-and-conditions">
                  {t("description.termsAndCon")}
                </Link>
              </div>
            </form>
            <form ref={formRef} method="post" action="">
              <input type="hidden" name="creq" ref={inputRef} value="" />
              <noscript>
                <center>
                  <br />
                  Please click the submit button below.
                  <br />
                  <input type="submit" value="Submit" />
                </center>
              </noscript>
            </form>
          </div>
        </div>
        <div className="flex justify-center items-center min-h-[200px] h-2/3">
          <PoweredByKeepz />
        </div>
      </main>
      <Modal
        show={showPaymentModal}
        position="bottom"
        onClose={() => dispatch({ type: SET_TOGGLE_PAYMENT_MODAL })}
      >
        <SelectPaymentMethod
          changePaymentType={changePaymentType}
          availablePaymentOptions={availablePaymentOptions}
          handleClose={() => dispatch({ type: SET_TOGGLE_PAYMENT_MODAL })}
        />
      </Modal>
      {/* <Modal
        width="70%"
        show={paymentResult?.show && !orderNotFound}
        onClose={handlePaymentResulClose}
      >
        <PaymentFeedback
          type={paymentResult?.type}
          action={handlePaymentResulClose}
        />
      </Modal> */}
      {paymentResult?.show && !orderNotFound ? (
        <div
          className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-40"
          onClick={(e) => {
            if (e.currentTarget !== e.target) {
              return;
            }
            handlePaymentResulClose(paymentResult?.type);
          }}
        >
          <PaymentFeedback
            className={true}
            type={paymentResult?.type}
            action={() => handlePaymentResulClose(paymentResult?.type)}
          />
        </div>
      ) : null}
    </>
  );
};

export default BogPayment;
