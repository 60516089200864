import ApplePayIcon from "assets/icons/ApplePayIcon";
import ArrowRightIcon from "assets/icons/ArrowRightIcon";
import CardIcon from "assets/icons/CardIcon";
import GooglePay from "assets/icons/GooglePay";
import VisaMasterIcon from "assets/icons/VisaMasterIcon";
import {
  AVAILABLE_PAYMENT_TYPES,
  INITIAL_STATE,
  PAYMENT_SERVICE_STATE,
  QR_TYPE,
  baseCardPaymentMethod,
  baseRequest,
  cardPaymentMethod,
} from "./config";
import paymentReducer from "./reducer/paymentReducer";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useQueryString } from "hooks";
import JSEncrypt from "jsencrypt";
import {
  SET_AMOUNT,
  SET_APPLE_PAY_STATE,
  SET_GPAY_STATE,
  SET_LOADING_PAYMENT,
  SET_PAYMENT_RESULT,
  SET_PAYMENT_TYPE,
  SET_TOGGLE_PAYMENT_MODAL,
} from "./reducer/actions";
import {
  bogTransaction,
  eCommerceServiceInstance,
  orderCityPay,
  orderCityPayStatus,
  paymentServiceInstance,
  postTransactionAction,
  startApplePayment,
  tbcTransaction,
} from "api";
import useSWR from "swr";
import {
  LAST_ACTIVE_ORDER,
  MERCHANT_PROFILE_URL,
  PRODUCT_URL,
  PROFILE_IMAGE_URL,
  PROFILE_IMAGE_URL_DEV,
  TRANSACTION_STATUS,
  USER_PROFILE_URL,
} from "helpers/fetcher/fetcher.config";
import { decrypt, encrypt } from "utils/encryption";
import { MERCHANT_NAME } from "config";
import { GPAY_MERCHANT_ID } from "config";
import { useTranslation } from "react-i18next";
import { acceptGooglePayPayment, startGooglePayPayment } from "./api";
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { BOG_URL } from "config";
import axios from "axios";
import startApplePaySession from "features/apple/utils/startApplePaySession";
import EcommerceModal from "components/modals/EcommerceModal";
import UserNotFound from "components/UserNotFound";
import Loader from "components/Loader";
import PaymentFeedback from "components/PaymentFeedback";

import { ReactComponent as Hlogo } from "assets/images/logo.svg";
import QRCode from "qrcode.react";

import merchantIcon from "assets/images/icon/merchant-icon.svg";
import Svg from "../../assets/images/redesign/defaultAvatar.svg";
import APayIcon from "assets/icons/APayIcon";
import GpayIcon from "assets/icons/GpayIcon";
import OnlineBankIcon from "assets/icons/OnlineBankIcon";
import TbcBogIcon from "assets/icons/TbcBogIcon";
import BitCoinIcon from "assets/icons/BitCoinIcon";
import CryptosIcon from "assets/icons/CryptosIcon";
import { RATING_ALLOWED_STATUSES } from "constants";

const MERCHANT_IDENTIFIER = "merchant.bog.keepz";

const BogPaymentWeb = () => {
  const [
    {
      amount,
      loadingPayment,
      gpayState,
      applePayState,

      paymentResult,
    },
    dispatch,
  ] = useReducer(paymentReducer, INITIAL_STATE);

  const { pathname, search } = useLocation();

  const { parseQuery } = useQueryString();
  const [availableApple, setAvailableApple] = useState(false);
  const [availableGoogle, setAvailableGoogle] = useState(false);
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const [acquiringType, setAcquiringType] = useState({});
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const descriptionRef = useRef(null);

  localStorage.removeItem("integratorOrderId");

  const {
    toUserId = "",
    status = "",
    transactionId = "",
    amount: requestedAmount = "",
    qrType = "",
    receiverId = "",
    receiverType = "USER",
    integratorId = "",
    integratorOrderId: integratorCheckOrderId,
    productId,
  } = parseQuery;
  localStorage.setItem("url", pathname + search);

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const navigate = useNavigate();

  const sanitizeUUID = (uuid) =>
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
      uuid
    )
      ? uuid
      : null;
  const receiverUserId = sanitizeUUID(toUserId || receiverId);
  localStorage.setItem("receiverUserId", receiverUserId);

  const { data: { amount: requestedAmount1 } = {} } = useSWR(
    !!productId
      ? `${PRODUCT_URL}?ownerId=${receiverUserId}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}&productId=${productId}&ownerType=${receiverType}`
      : null,
    async (url) => {
      try {
        const {
          data: { encryptedData },
        } = await paymentServiceInstance.get(url);
        return decrypt(encryptedData);
      } catch (err) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: "",
          },
        });
      }
    }
  );

  const { data: { integratorOrderId = integratorCheckOrderId } = {} } = useSWR(
    useMemo(() => {
      if (integratorId.length && qrType !== QR_TYPE.REQUEST) {
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
        const encryptedData = encodeURIComponent(
          encrypt.encrypt(
            JSON.stringify({
              integratorId: integratorId,
              receiverId: receiverUserId,
            })
          )
        );

        return `${LAST_ACTIVE_ORDER}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}&encryptedData=${encryptedData}`;
      } else {
        return null;
      }
    }, [integratorId, qrType, receiverUserId]),
    async (url) => {
      try {
        const {
          data: { encryptedData },
        } = await eCommerceServiceInstance.get(url);
        const encrypt = new JSEncrypt();
        encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
        const decryptedData = JSON.parse(encrypt.decrypt(encryptedData));
        const { amount: orderedAmount } = decryptedData;
        dispatch({ type: SET_AMOUNT, payload: orderedAmount });
        return decryptedData;
      } catch (err) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.response?.data?.status,
          },
        });
      }
    }
  );

  const { data: { result: { status: transactionStatus } = {} } = {} } = useSWR(
    transactionId
      ? `${TRANSACTION_STATUS}?providerId=${transactionId}&fromMobile=false`
      : null
  );

  const { data, error: userError } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const { data: merchant, error: merchantError } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}?identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const isMerchant =
    (data?.userType || merchant?.userType || receiverType) === "BRANCH";
  const isBusiness =
    (data?.userType || merchant?.userType || receiverType) === "BUSINESS";

  const getImage = () => {
    const img =
      window.location.hostname == "www.web.appdev.keepz.me"
        ? PROFILE_IMAGE_URL_DEV
        : PROFILE_IMAGE_URL;
    if (merchant?.imagePath || data?.imagePath) {
      return img + (merchant?.imagePath || data?.imagePath);
    } else if (isMerchant || isBusiness) {
      return merchantIcon;
    } else {
      return Svg;
    }
  };

  const { data: commisonUsers, error: commisonUserError } = useSWR(
    receiverType === "USER"
      ? `${USER_PROFILE_URL}/${receiverUserId}/commissions?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  const { data: commisonMerchant, error: commisonMerhcantError } = useSWR(
    receiverType === "BRANCH"
      ? `${MERCHANT_PROFILE_URL}/${receiverUserId}/commission?identifier=${
          process.env.REACT_APP_INTEGRATOR_ID
        }${integratorId ? `&integratorId=${integratorId}` : ""}`
      : null,
    async (url) => {
      const {
        data: { encryptedData },
      } = await paymentServiceInstance.get(url);
      const crypt = new JSEncrypt();
      crypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const encrypted = JSON.parse(crypt.decrypt(encryptedData));
      return encrypted;
    }
  );

  // const senderCommissionPercentApple =
  //   commisonUsers?.find((item) => item.acquiringType === "APPLE_PAY")
  //     .senderCommission ??
  //   commisonMerchant?.find((item) => item.acquiringType === "APPLE_PAY")
  //     .senderCommission;

  const senderCommissionPercentApple =
    commisonUsers?.find((item) => item.acquiringType === "APPLE_PAY") ||
    commisonMerchant?.find((item) => item.acquiringType === "APPLE_PAY");

  // const senderCommissionPercentGoogle =
  //   commisonUsers?.find((item) => item.acquiringType === "GOOGLE_PAY")
  //     .senderCommission ??
  //   commisonMerchant?.find((item) => item.acquiringType === "GOOGLE_PAY")
  //     .senderCommission;

  const senderCommissionPercentGoogle =
    commisonUsers?.find((item) => item.acquiringType === "GOOGLE_PAY") ||
    commisonMerchant?.find((item) => item.acquiringType === "GOOGLE_PAY");

  // const senderCommissionPercentStandard =
  //   commisonUsers?.find((item) => item.acquiringType === "STANDARD")
  //     .senderCommission ??
  //   commisonMerchant?.find((item) => item.acquiringType === "STANDARD")
  //     .senderCommission;
  const senderCommissionPercentStandard =
    commisonUsers?.find((item) => item.acquiringType === "STANDARD") ||
    commisonMerchant?.find((item) => item.acquiringType === "STANDARD");

  // const senderCommissionPercentCrypto =
  //   commisonUsers?.find((item) => item.acquiringType === "CRYPTO")
  //     .senderCommission ??
  //   commisonMerchant?.find((item) => item.acquiringType === "CRYPTO")
  //     .senderCommission;

  const senderCommissionPercentCrypto =
    commisonUsers?.find((item) => item.acquiringType === "CRYPTO") ||
    commisonMerchant?.find((item) => item.acquiringType === "CRYPTO");

  function checkIfValuesAreEqual() {
    return (
      senderCommissionPercentApple?.senderCommission ===
        senderCommissionPercentGoogle?.senderCommission &&
      senderCommissionPercentGoogle ===
        senderCommissionPercentCrypto?.senderCommission &&
      senderCommissionPercentCrypto?.senderCommission ===
        senderCommissionPercentStandard?.senderCommission
    );
  }

  const senderCommissionBool = commisonUsers || commisonMerchant;
  const totalWithCommision = useMemo(() => {
    if (!senderCommissionBool || !amount) {
      return;
    }
    // const comission = (amount * senderCommissionPercent) / 100;
    // const comissionRounded = Math.ceil(comission * 100) / 100;
    // const comissionApple = (amount * senderCommissionPercentApple) / 100;
    // const comissionRoundedApple =
    //   Math.ceil((comissionApple * 100).toFixed(2)) / 100;
    let comissionApple;
    let comissionRoundedApple;
    if (senderCommissionPercentApple?.rateType == "FIXED") {
      comissionApple = null;
      comissionRoundedApple = senderCommissionPercentApple?.senderCommission;
    } else {
      comissionApple =
        (amount * senderCommissionPercentApple?.senderCommission) / 100;
      comissionRoundedApple =
        Math.ceil((comissionApple * 100).toFixed(2)) / 100;
    }
    //
    // const comissionGoogle = (amount * senderCommissionPercentGoogle) / 100;
    // const comissionRoundedGoogle =
    //   Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
    let comissionGoogle;
    let comissionRoundedGoogle;
    if (senderCommissionPercentGoogle?.rateType == "FIXED") {
      comissionGoogle = null;
      comissionRoundedGoogle = senderCommissionPercentGoogle?.senderCommission;
    } else {
      comissionGoogle =
        (amount * senderCommissionPercentGoogle?.senderCommission) / 100;
      comissionRoundedGoogle =
        Math.ceil((comissionGoogle * 100).toFixed(2)) / 100;
    }
    // const comissionStandard = (amount * senderCommissionPercentStandard) / 100;
    // const comissionRoundedStandard =
    //   Math.ceil((comissionStandard * 100).toFixed(2)) / 100;
    let comissionStandard;
    let comissionRoundedStandard;
    if (senderCommissionPercentStandard?.rateType == "FIXED") {
      comissionStandard = null;
      comissionRoundedStandard =
        senderCommissionPercentStandard?.senderCommission;
    } else {
      comissionStandard =
        (amount * senderCommissionPercentStandard?.senderCommission) / 100;
      comissionRoundedStandard =
        Math.ceil((comissionStandard * 100).toFixed(2)) / 100;
    }
    let comissionCrypto;
    let comissionRoundedCrypto;
    if (senderCommissionPercentCrypto?.rateType == "FIXED") {
      comissionCrypto = null;
      comissionRoundedCrypto = senderCommissionPercentCrypto?.senderCommission;
    } else {
      comissionCrypto =
        (amount * senderCommissionPercentCrypto?.senderCommission) / 100;
      comissionRoundedCrypto =
        Math.ceil((comissionCrypto * 100).toFixed(2)) / 100;
    }
    return {
      totalApple: (Number(amount) + Number(comissionRoundedApple)).toFixed(2),
      comissionApple: parseFloat(comissionRoundedApple),
      totalGoogle: (Number(amount) + Number(comissionRoundedGoogle)).toFixed(2),
      comissionGoogle: parseFloat(comissionRoundedGoogle),
      totalStandard: (
        Number(amount) + Number(comissionRoundedStandard)
      ).toFixed(2),
      comissionStandard: parseFloat(comissionRoundedStandard),
      totalCrypto: (Number(amount) + Number(comissionRoundedCrypto)).toFixed(2),
      comissionCrypto: parseFloat(comissionRoundedCrypto),
    };
  }, [
    amount,
    senderCommissionPercentApple,
    senderCommissionPercentCrypto,
    senderCommissionPercentGoogle,
    senderCommissionPercentStandard,
    senderCommissionBool,
  ]);

  const gpayRef = useRef();
  const inputRef = useRef();
  const formRef = useRef();
  const fixedAmount = useMemo(
    () =>
      amount
        ? Number(String(amount).replace(/,/g, ".")).toFixed(2)
        : requestedAmount ||
          requestedAmount1 ||
          data?.amountForDefaultQR ||
          merchant?.amountForDefaultQR,
    [amount, requestedAmount, requestedAmount1, data, merchant]
  );

  const changePaymentType = (type) => {
    dispatch({ type: SET_PAYMENT_TYPE, payload: type });
    dispatch({ type: SET_TOGGLE_PAYMENT_MODAL });
  };

  const handlePaymentResulClose = (type) => {
    if (status || transactionId) {
      navigate({
        pathname,
        search: window.location.search.replace("&status=FAIL", ""),
      });
    }
    dispatch({
      type: SET_PAYMENT_RESULT,
      payload: {
        show: false,
        type: type,
      },
    });
  };

  const standardPriorityArray =
    acquiringType?.availableAcquiringTypes?.STANDARD?.sort(
      (a, b) => a.priority - b.priority
    );

  function handleCryptoPayment() {
    if ((data || merchant)?.descriptionRequired && !description.length > 0) {
      setDescriptionError(true);
      return;
    }
    localStorage.setItem("acquiring", "bog");
    localStorage.setItem("paymentMethod", "card");
    const encryptedData = encrypt.encrypt(
      JSON.stringify({
        amount: Number(fixedAmount),
        toBenefitId: receiverUserId,
        beneficialType: receiverType,
        currency: "GEL",
        ...(totalWithCommision?.comissionCrypto && {
          senderCommissionAmount: totalWithCommision.comissionCrypto,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId: integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        qrType: qrType,
      })
    );
    orderCityPay({
      encryptedData: encryptedData,
      identifier: process.env.REACT_APP_INTEGRATOR_ID,
    })
      .then(({ encryptedData }) => {
        encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
        const { payment_url } = JSON.parse(encrypt.decrypt(encryptedData));
        localStorage.setItem("binance", payment_url);
        window.open(payment_url, "_self");
      })
      .catch(async (err) => {
        if (err?.response?.status == 425) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: 425,
            },
          });
          return;
        }
        if (err?.response?.status == 429) {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: 429,
            },
          });
          return;
        }
        if (err?.response?.data?.status == "PRECONDITION_FAILED") {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: "PRECONDITION_FAILED",
            },
          });
          return;
        }
        if (err?.response?.data?.status == "GONE") {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: "GONE",
            },
          });
          return;
        }

        if (integratorCheckOrderId || integratorOrderId) {
          const { redirectRequired, failRedirectUri } =
            await postTransactionAction(integratorOrderId);
          if (redirectRequired && failRedirectUri) {
            window.location.href = failRedirectUri;
            return;
          }
        }
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: err?.response?.data?.status,
          },
        });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
      });
  }

  function handleApplePayment() {
    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }

    if (!window.ApplePaySession) {
      setAvailableApple(true);
      return;
    }

    if ((data || merchant)?.descriptionRequired && !description.length > 0) {
      setDescriptionError(true);
      return;
    }

    if (!fixedAmount) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
        },
      });
      return;
    }
    localStorage.setItem("paymentMethod", "applePay");
    if (fixedAmount) {
      dispatch({ type: SET_LOADING_PAYMENT, payload: true });
    }

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
    const lang = language === "ge" ? "KA" : "EN";
    localStorage.setItem("acquiring", "bog");
    try {
      const startApplePayload = {
        amount: Number(fixedAmount),
        receiverType: receiverType || "User",
        ...(totalWithCommision?.comissionApple && {
          senderCommissionAmount: totalWithCommision.comissionApple,
        }),
        ...(integratorId && { integratorId }),
        ...((integratorOrderId || productId) && {
          integratorOrderId: integratorOrderId || productId,
        }),
        ...(!!description.length && { description }),
        qrType: qrType,
        currency: "GEL",
        fromMobile: false,
        receiverId: receiverUserId,
        lang,
      };
      const result = encrypt.encrypt(JSON.stringify(startApplePayload));
      const transactionIdPromise = startApplePayment({
        encryptedData: result,
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
      });
      startApplePaySession(
        {
          amount: Number(totalWithCommision?.total || fixedAmount),
          transactionIdPromise: transactionIdPromise,
        },
        (res) => {
          if (res?.status === 0) {
            const url = `/success?acquiringTransactionId=${
              res.transactionId
            }&amount=${fixedAmount}&receiverType=${receiverType}&receiverName=${encodeURIComponent(
              (merchant || data)?.name
            )}&ratingEnabled=${!!(
              merchant?.reviewRequired || data?.reviewRequired
            )}&tipsEnabled=${!!(merchant?.tipsEnabled || data?.tipsEnabled)}`;
            navigate(url);
          } else {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
            if (res?.status == "PRECONDITION_FAILED") {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: "PRECONDITION_FAILED",
                },
              });
              return;
            }
            if (res?.status == "GONE") {
              dispatch({
                type: SET_PAYMENT_RESULT,
                payload: {
                  show: true,
                  type: "GONE",
                },
              });
              return;
            }
            async function failRedirct() {
              if (integratorCheckOrderId || integratorOrderId) {
                const { redirectRequired, failRedirectUri } =
                  await postTransactionAction(integratorOrderId);
                if (redirectRequired && failRedirectUri) {
                  window.location.href = failRedirectUri;
                  return;
                }
              }
            }
            failRedirct();
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: "",
              },
            });
          }
        }
      );
    } catch (err) {
      async function failRedirct() {
        if (integratorCheckOrderId || integratorOrderId) {
          const { redirectRequired, failRedirectUri } =
            await postTransactionAction(integratorOrderId);
          if (redirectRequired && failRedirectUri) {
            window.location.href = failRedirectUri;
            return;
          }
        }
      }
      failRedirct();
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: err?.response?.data?.status,
        },
      });
    }
  }

  function handleCardPayment() {
    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }

    if ((data || merchant)?.descriptionRequired && !description.length > 0) {
      setDescriptionError(true);
      return;
    }

    localStorage.setItem("paymentMethod", "card");
    if (fixedAmount) {
      dispatch({ type: SET_LOADING_PAYMENT, payload: true });
    }

    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
    const payload = {
      amount: fixedAmount,
      receiverId: receiverUserId,
      receiverType,
      qrType: qrType,
      ...(totalWithCommision?.comissionStandard && {
        senderCommissionAmount: totalWithCommision.comissionStandard,
      }),
      ...(integratorId && { integratorId }),
      ...((integratorOrderId || productId) && {
        integratorOrderId: integratorOrderId || productId,
      }),
      ...(!!description.length && { description }),
    };
    const result = encrypt.encrypt(JSON.stringify(payload));

    if (standardPriorityArray[0]?.acquiringName == "BOG") {
      localStorage.setItem("acquiring", "bog");
      bogTransaction({
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
        encryptedData: result,
      })
        .then(({ _links: links, id }) => {
          const { redirect: { href = "" } = {} } = links || {};
          window.open(href, "_self");
        })
        .catch(async (err) => {
          if (err?.response?.status == 425) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 425,
              },
            });
            return;
          }
          if (err?.response?.status == 429) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 429,
              },
            });
            return;
          }
          if (err?.response?.data?.status === "PRECONDITION_FAILED") {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: "PRECONDITION_FAILED",
              },
            });
            return;
          }
          if (err?.response?.data?.status === "GONE") {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: "GONE",
              },
            });
            return;
          }
          if (integratorCheckOrderId || integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
          if (
            err?.response?.data?.message?.includes("Amount should be in range")
          ) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 409,
              },
            });
            return;
          }
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.response?.data?.status,
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    } else if (standardPriorityArray[0]?.acquiringName == "TBC") {
      localStorage.setItem("acquiring", "tbc");
      tbcTransaction({
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
        encryptedData: result,
      })
        .then((data) => {
          console.log(data, "dataaa");
          const { links } = data;
          const [, secondElement] = links;
          console.log(secondElement, "second elemetn");
          const { uri } = secondElement;
          console.log(uri, "url redircer");
          window.open(uri, "_self");
        })
        .catch(async (err) => {
          if (err?.response?.status == 425) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 425,
              },
            });
            return;
          }
          if (err?.respons?.status == 429) {
            dispatch({
              type: SET_PAYMENT_RESULT,
              payload: {
                show: true,
                type: 429,
              },
            });
            return;
          }
          if (integratorCheckOrderId || integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.response?.data?.status,
            },
          });
        })
        .finally(() => {
          setTimeout(() => {
            dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          }, 3000);
        });
    }
  }

  const copyToClipboard = () => {
    // Get the current domain

    // Copy the current domain to the clipboard
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        setLinkIsCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
      });
  };

  async function handleGooglePayPaymenta() {
    if (!navigator.onLine) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "INTERNET",
        },
      });
      return;
    }

    if ((data || merchant)?.descriptionRequired && !description.length > 0) {
      setDescriptionError(true);
      return;
    }

    if (window.ApplePaySession) {
      setAvailableGoogle(true);
      return;
    }

    localStorage.setItem("paymentMethod", "googlePay");

    if (fixedAmount) {
      dispatch({ type: SET_LOADING_PAYMENT, payload: true });
    }
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);

    const lang = language === "ge" ? "KA" : "EN";
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = {
      totalPriceStatus: "FINAL",
      totalPrice: fixedAmount,
      currencyCode: "GEL",
      countryCode: "GE",
    };
    paymentDataRequest.merchantInfo = {
      merchantName: MERCHANT_NAME,
      merchantId: GPAY_MERCHANT_ID,
    };
    const payload = {
      amount: Number(fixedAmount) || 20,
      receiverType: receiverType || "User",
      ...(totalWithCommision?.comissionGoogle && {
        senderCommissionAmount: totalWithCommision.comissionGoogle,
      }),
      ...(integratorId && { integratorId }),
      ...((integratorOrderId || productId) && {
        integratorOrderId: integratorOrderId || productId,
      }),
      ...(!!description.length && { description }),
      qrType: qrType,
      currency: "GEL",
      fromMobile: false,
      receiverId: receiverUserId,
      lang,
    };
    localStorage.setItem("acquiring", "bog");

    const result = encrypt.encrypt(JSON.stringify(payload));
    let googlePayResponse;
    try {
      googlePayResponse = await startGooglePayPayment({
        encryptedData: result,
        identifier: process.env.REACT_APP_INTEGRATOR_ID,
      });
    } catch (err) {
      if (err?.response?.data?.status === "PRECONDITION_FAILED") {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: "PRECONDITION_FAILED",
          },
        });
        return;
      }
      if (err?.response?.data?.status === "GONE") {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: "GONE",
          },
        });
        return;
      }
      if (err?.response?.status == 425) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: 425,
          },
        });
        return;
      }
      if (err?.response?.status == 429) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: 429,
          },
        });
        return;
      }
      if (err?.response?.data?.message?.includes("Amount should be in range")) {
        dispatch({
          type: SET_PAYMENT_RESULT,
          payload: {
            show: true,
            type: 409,
          },
        });
        dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        return;
      }
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: err?.response?.data?.status,
        },
      });

      dispatch({ type: SET_LOADING_PAYMENT, payload: false });
      return;
    }

    gpayRef.current
      .loadPaymentData(paymentDataRequest)
      .then(async (paymentData) => {
        try {
          const token = paymentData.paymentMethodData.tokenizationData.token;
          const cardNetwork = paymentData.paymentMethodData.info.cardNetwork;
          const {
            sessionId,
            params: { transaction_id: merchantTransactionId } = {},
          } = googlePayResponse;

          const {
            state,
            url = "",
            result: { status = "" } = {},
          } = await acceptGooglePayPayment(token, {
            sessionId,
            merchantTransactionId,
            cardBrand: cardNetwork,
          });
          if (status === "SUCCESS") {
            const redirectURL = `/success?acquiringTransactionId=${merchantTransactionId}&receiverType=${receiverType}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
              (merchant || data)?.name
            )}&ratingEnabled=${!!(
              merchant?.reviewRequired || data?.reviewRequired
            )}&tipsEnabled=${!!(merchant?.tipsEnabled || data?.tipsEnabled)}`;
            navigate(redirectURL);
            return;
          } else if (state === "3ds2_prepare") {
            const version = cardNetwork === "MASTERCARD" ? "2.2.0" : "2.1.0";
            // const iframeReturnUrl = `https://app.keepz.me/success?acquiringTransactionId=${merchantTransactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
            //   (merchant || data)?.name
            // )}&ratingEnabled=${!!(
            //   merchant?.reviewRequired || data?.reviewRequired
            // )}`;
            const options = {
              method: "POST",
              url: `${BOG_URL}/payment/${sessionId}/3ds2-prepare/accept`,
              params: {
                version,
                "device.channel": "BRW",
                ...(version === "2.1.0" && {
                  "device.browserAcceptHeader":
                    "application/json, text/javascript, /; q=0.01",
                }),
                "device.browserIP": "127.0.0.1",
                "device.browserLanguage": version === "2.1.0" ? "en-US" : "EN",
                ...(version === "2.1.0" && {
                  "device.browserJavaEnabled": false,
                }),
                "device.browserColorDepth": version === "2.2.0" ? "32" : "24",
                "device.browserScreenHeight": "800",
                "device.browserScreenWidth": "480",
                "device.browserTZ": "180",
                "device.browserUserAgent":
                  version === "2.1.0"
                    ? "Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Mobile Safari/537.36"
                    : "Gecko",
                ...(version === "2.2.0" && {
                  "device.browserJavascriptEnabled": false,
                }),
                challengeWindowSize: version === "2.2.0" ? "02" : "03",
                // iframeReturnUrl,
              },
            };

            const {
              data: { url, post = {} },
            } = await axios.request(options);
            if (!url) {
              throw new Error("Something went wrong");
            }

            formRef.current.action = url;
            if ("threeDSMethodData" in post) {
              localStorage.setItem("sessionId", sessionId);
              inputRef.current.name = "threeDSMethodData";
              inputRef.current.value = post.threeDSMethodData;
            } else {
              inputRef.current.name = "creq";
              inputRef.current.value = post.creq;
            }

            formRef.current.submit();
          } else if (state === "redirect") {
            window.open(url, "_self");
          } else {
            throw new Error("Unknow state");
          }
        } catch (err) {
          if (integratorCheckOrderId || integratorOrderId) {
            const { redirectRequired, failRedirectUri } =
              await postTransactionAction(integratorOrderId);
            if (redirectRequired && failRedirectUri) {
              window.location.href = failRedirectUri;
              return;
            }
          }
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
              type: err?.response?.data?.status,
            },
          });
        }
      })
      .catch(() => {
        setTimeout(() => {
          dispatch({ type: SET_LOADING_PAYMENT, payload: false });
        }, 3000);
      });
  }

  useEffect(() => {
    localStorage.removeItem("binance");
  }, []);

  useEffect(() => {
    const handlePopState = async () => {
      if (!localStorage.getItem("binance")) {
        return;
      }

      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(process.env.REACT_APP_PUBLIC_ENCRIPTION_KEY);
      const payment_url = localStorage.getItem("binance");
      const payload = {
        externalId: payment_url,
      };
      const encryptedData = encodeURIComponent(
        encrypt.encrypt(JSON.stringify(payload))
      );
      const { encryptedData: res } = await orderCityPayStatus(
        `encryptedData=${encryptedData}&identifier=${process.env.REACT_APP_INTEGRATOR_ID}`
      );
      encrypt.setPrivateKey(process.env.REACT_APP_INTEGRATOR_SECRET);
      const { status, id } = JSON.parse(encrypt.decrypt(res));
      if (status) {
        if (RATING_ALLOWED_STATUSES.includes(status)) {
          const redirectURL = `/success?acquiringTransactionId=${id}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
            (merchant || data)?.name
          )}&ratingEnabled=${!!(
            merchant?.reviewRequired || data?.reviewRequired
          )}`;
          navigate(redirectURL);
        } else {
          dispatch({
            type: SET_PAYMENT_RESULT,
            payload: {
              show: true,
            },
          });
        }
      }
    };
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      localStorage.removeItem("binance");
    };
  }, [merchant, data]);

  // Initialize google pay
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://pay.google.com/gp/p/js/pay.js";
    script.onload = () => {
      // eslint-disable-next-line no-undef
      gpayRef.current = new google.payments.api.PaymentsClient({
        environment: "PRODUCTION", // Change to 'PRODUCTION' for production use
      });
      const isReadyToPayRequest = Object.assign({}, baseRequest);
      isReadyToPayRequest.allowedPaymentMethods = [baseCardPaymentMethod];
      gpayRef.current
        .isReadyToPay(isReadyToPayRequest)
        .then(({ result }) => {
          if (result) {
            dispatch({
              type: SET_GPAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE_PAYMENT_TYPES,
            });
            changePaymentType("gpay");
            dispatch({
              type: SET_PAYMENT_TYPE,
              payload: AVAILABLE_PAYMENT_TYPES.GOOGLE_PAY,
            });
          } else {
            throw new Error("Gpay not available");
          }
        })
        .catch((err) => {
          dispatch({
            type: SET_GPAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Initialize apple pay
  useEffect(() => {
    if (window.ApplePaySession) {
      const promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          MERCHANT_IDENTIFIER
        );
      promise
        .then((canMakePayments) => {
          if (canMakePayments) {
            changePaymentType("apple");
            dispatch({
              type: SET_PAYMENT_TYPE,
              payload: AVAILABLE_PAYMENT_TYPES.APPLE_PAY,
            });
            dispatch({
              type: SET_APPLE_PAY_STATE,
              payload: PAYMENT_SERVICE_STATE.AVAILABLE,
            });
          } else {
            throw new Error("can't make payments");
          }
        })
        .catch(() => {
          dispatch({
            type: SET_APPLE_PAY_STATE,
            payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
          });
        });
    } else {
      dispatch({
        type: SET_APPLE_PAY_STATE,
        payload: PAYMENT_SERVICE_STATE.NOT_AVAILABLE,
      });
    }
  }, []);

  useEffect(() => {
    if (
      applePayState !== PAYMENT_SERVICE_STATE.LOADING &&
      gpayState !== PAYMENT_SERVICE_STATE.LOADING &&
      ((status && status.toString() === "FAIL") ||
        transactionStatus === "FAILED")
    ) {
      dispatch({
        type: SET_PAYMENT_RESULT,
        payload: {
          show: true,
          type: "ERROR",
        },
      });
    }
  }, [status, applePayState, transactionStatus, gpayState]);

  console.log(transactionStatus);

  useEffect(() => {
    let amountSet =
      requestedAmount ||
      requestedAmount1 ||
      data?.amountForDefaultQR ||
      merchant?.amountForDefaultQR;
    if (amountSet && !isNaN(amountSet)) {
      dispatch({ type: SET_AMOUNT, payload: amountSet });
    }
  }, [
    dispatch,
    requestedAmount,
    requestedAmount1,
    merchant?.amountForDefaultQR,
    data?.amountForDefaultQR,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await paymentServiceInstance.get(
          `/api/v1/acquiring/choice/external?receiverId=${encodeURI(
            receiverId
          )}&receiverType=${encodeURI(receiverType)} `
        );

        setAcquiringType(data);

        return data;
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (integratorCheckOrderId || integratorOrderId) {
    localStorage.setItem(
      "integratorOrderId",
      integratorCheckOrderId || integratorOrderId
    );
  }

  if (
    applePayState === PAYMENT_SERVICE_STATE.LOADING ||
    gpayState === PAYMENT_SERVICE_STATE.LOADING
  ) {
    return <Loader />;
  }

  if (
    userError?.response?.status === 404 ||
    merchantError?.response?.status === 404
  ) {
    return <UserNotFound />;
  }

  // if (
  //   (data || merchant)?.descriptionRequired &&
  //   !description.length &&
  //   descriptionRef?.current
  // ) {
  //   return descriptionRef.current.focus();
  // }

  if (transactionStatus === "SUCCESS" || transactionStatus === "REFUND") {
    const redirectURL = `/success?receiverId=${receiverUserId}&acquiringTransactionId=${transactionId}&amount=${fixedAmount}&receiverName=${encodeURIComponent(
      (merchant || data)?.name
    )}&ratingEnabled=${!!(receiverType === "BRANCH")}`;
    return <Navigate to={redirectURL} />;
  }

  const decodedString = decodeURIComponent(merchant?.name || data?.name);
  const userName = decodedString.replace(/\+/g, " ");
  const orderNotFound = ["CONFLICT", "NOT_FOUND"].includes(paymentResult?.type);
  return (
    <>
      <div className="w-full h-full bg-[#F8F9FF] overflow-hidden ">
        {paymentResult?.show && orderNotFound ? (
          <div
            className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-40"
            onClick={(e) => {
              if (e.currentTarget !== e.target) {
                return;
              }
              handlePaymentResulClose(paymentResult?.type);
            }}
          >
            <PaymentFeedback
              className={true}
              type={paymentResult?.type}
              action={() => handlePaymentResulClose(paymentResult?.type)}
            />
          </div>
        ) : null}
        {paymentResult?.show && !orderNotFound ? (
          <div
            className=" flex justify-center    fixed  overflow-hidden top-0 w-[100vw] h-[100vh] bg-black-400 opacity-9 z-40"
            onClick={(e) => {
              if (e.currentTarget !== e.target) {
                return;
              }
              handlePaymentResulClose(paymentResult?.type);
            }}
          >
            <PaymentFeedback
              className={true}
              type={paymentResult?.type}
              action={() => handlePaymentResulClose(paymentResult?.type)}
            />
          </div>
        ) : null}
        {availableApple ? (
          <EcommerceModal
            Icon={APayIcon}
            desc={"Apple pay not available on your device"}
            subDesc={
              <>
                Please use Mac PC or laptop and Safari as browser <br /> to pay
                with apple pay
              </>
            }
            onClose={() => setAvailableApple(false)}
          />
        ) : null}
        {availableGoogle ? (
          <EcommerceModal
            Icon={GpayIcon}
            desc={"Google pay not available on your device"}
            subDesc={
              <>
                Please use Chrome as browser <br /> to pay with google pay
              </>
            }
            onClose={() => setAvailableGoogle(false)}
          />
        ) : null}
        {loadingPayment && <Loader />}

        <div
          className={`${
            (data || merchant)?.descriptionRequired ? "h-[600px]" : "h-[550px]"
          } w-[774px]  bg-white m-auto mt-[67px] p-[40px] rounded-[16px] z-10 relative`}
        >
          <div className="w-full flex justify-between items-center">
            <div className="flex gap-[10px] items-center">
              <div className="w-[70px] h-[70px] bg-gray-100 rounded-[50%]">
                <img
                  src={getImage()}
                  className="aspect-square rounded-full border-white object-cover"
                  width={"100"}
                  height={"100"}
                  alt="user icon"
                />
              </div>

              <p className="text-[#170738] text-[20px] font-[700] break-words">
                <p className="truncate  px-1">{userName}</p>
              </p>
            </div>
            <div>
              <p className="text-[#000000] text-[14px] opacity-[50%] text-right">
                Amount:
              </p>
              <p className="text-[#000000] font-[700] text-[24px] ">
                {checkIfValuesAreEqual() && totalWithCommision?.totalApple
                  ? totalWithCommision?.totalApple
                  : fixedAmount}{" "}
                ₾
              </p>
            </div>
          </div>

          {(data || merchant)?.descriptionRequired && (
            <>
              <div class="relative h-10 w-full min-w-[200px] mt-[20px]">
                <input
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionError(false);
                  }}
                  value={description}
                  // placeholder="Your text goes here"
                  className={`peer h-full w-full rounded-[12px] border ${
                    descriptionError
                      ? "border-red focus:border-red"
                      : "border-[#D0D5DD] focus:border-[#6C63FF]"
                  }   px-[14px] py-[10px] font-sans  font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border  f focus:border-gray-900  focus:outline-0   placeholder:opacity-0 focus:placeholder:opacity-100 placeholder:text-[16px] placeholder:text-[#101828]`}
                />
                <label
                  class={`before:content[' '] after:content[' '] pointer-events-none absolute peer-focus:-top-1.5 left-2 top-2 flex  select-none !overflow-visible truncate  font-normal leading-tight ${
                    descriptionError
                      ? "text-red peer-focus:text-red"
                      : "text-[#667085] peer-focus:text-[#6C63FF]"
                  }  transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500  peer-placeholder-shown:before:border-transparent peer-focus:bg-white peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2  peer-focus:after:!border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500   bg-none text-[16px] w-[70px]`}
                >
                  Description
                </label>
              </div>
            </>
          )}
          <div className="flex items-center justify-between gap-[40px] mt-[31px] h-[360px] ">
            <div className="w-[337px] h-[100%] flex flex-col gap-[20px]">
              {acquiringType?.availableAcquiringTypes?.APPLE_PAY && (
                <button
                  onClick={handleApplePayment}
                  className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px] "
                >
                  <div className="flex items-center gap-[10px]">
                    <ApplePayIcon className="w-[38px] h-[25px]" />
                    <div className="text-left">
                      <p className="text-[16px] text-[#170738]">Apple Pay</p>
                      {senderCommissionPercentGoogle?.senderCommission &&
                      !checkIfValuesAreEqual() ? (
                        <p className="text-[12px]  text-[#170738] opacity-30">
                          Total with fees:{totalWithCommision?.totalApple}₾
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <ArrowRightIcon />
                </button>
              )}
              {acquiringType?.availableAcquiringTypes?.GOOGLE_PAY && (
                <button
                  onClick={handleGooglePayPaymenta}
                  className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px]  "
                >
                  <div className="flex items-center gap-[10px]">
                    <GooglePay className="w-[38px] h-[25px]" />
                    <div className="text-left">
                      <p className="text-[16px] text-[#170738]">Google Pay</p>
                      {senderCommissionPercentGoogle?.senderCommission &&
                      !checkIfValuesAreEqual() ? (
                        <p className="text-[12px]  text-[#170738] opacity-30">
                          Total with fees:{totalWithCommision?.totalGoogle}₾
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <ArrowRightIcon />
                </button>
              )}
              {acquiringType?.availableAcquiringTypes?.STANDARD && (
                <button
                  className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px] "
                  onClick={handleCardPayment}
                >
                  <div className="flex items-center gap-[10px]">
                    <CardIcon className="w-[30px] h-[17px]" />
                    <div className="text-left">
                      <p className="text-[16px] text-[#170738]">Credit card</p>
                      {senderCommissionPercentStandard?.senderCommission &&
                      !checkIfValuesAreEqual() ? (
                        <p className="text-[12px] text-[#170738] opacity-30">
                          Total with fees:{totalWithCommision?.totalStandard}₾
                        </p>
                      ) : null}
                    </div>
                  </div>

                  <div className="flex gap-[20px]">
                    <VisaMasterIcon />
                    <ArrowRightIcon />
                  </div>
                </button>
              )}
              <button className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%] rounded-[12px] h-[60px] ">
                <div className="flex items-center  gap-[10px]">
                  <OnlineBankIcon className="w-[30px] h-[23px]" />
                  <div className="text-left">
                    <p className="text-[16px] text-[#170738]">Online bank</p>
                    <p className="text-[12px] mt-[-2px] opacity-50 text-[#170738]">
                      Coming soon
                    </p>
                  </div>
                </div>
                <div className="flex items-center gap-[20px]">
                  <TbcBogIcon />
                  <ArrowRightIcon />
                </div>
              </button>
              {acquiringType?.availableAcquiringTypes?.CRYPTO && (
                <button
                  onClick={() => handleCryptoPayment()}
                  className="flex items-center justify-between w-full cursor-pointer bg-[#6C63FF]/[5%]  pt-[10px] pr-[20px] pb-[10px] ps-[15px] border-[1px] border-[#6C63FF]/[20%] hover:border-[#6C63FF]/[50%]  rounded-[12px] h-[60px] "
                >
                  <div className="flex items-center  gap-[10px]">
                    <BitCoinIcon />
                    <div className="text-left">
                      <p className="text-[16px] text-[#170738]">Crypto</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-[20px]">
                    <CryptosIcon />
                    <ArrowRightIcon />
                  </div>
                </button>
              )}
            </div>
            <div className="w-[337px] h-[100%] border-[2px] rounded-[12px] flex flex-col justify-center  items-center gap-[15px]  ">
              <p className="opacity-50">Scan and pay with smartphone</p>
              <div className="w-[215px] h-[215px]">
                <QRCode
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "fill",
                  }}
                  value={window.location.href}
                />
              </div>

              {!linkIsCopied ? (
                <div className="flex items-center gap-[5px]">
                  <svg
                    width="17"
                    height="10"
                    viewBox="0 0 17 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4.63793 2.3C3.97946 2.3 3.34796 2.58446 2.88236 3.09081C2.41675 3.59716 2.15517 4.28392 2.15517 5C2.15517 5.71608 2.41675 6.40284 2.88236 6.90919C3.34796 7.41554 3.97946 7.7 4.63793 7.7H6.59172C7.04879 7.7 7.41931 8.10294 7.41931 8.6C7.41931 9.09706 7.04879 9.5 6.59172 9.5H4.63793C3.54048 9.5 2.48798 9.0259 1.71197 8.18198C0.935959 7.33807 0.5 6.19347 0.5 5C0.5 3.80653 0.93596 2.66193 1.71197 1.81802C2.48798 0.974106 3.54048 0.5 4.63793 0.5H6.54862C7.00568 0.5 7.37621 0.902944 7.37621 1.4C7.37621 1.89706 7.00568 2.3 6.54862 2.3H4.63793ZM9.62379 1.4C9.62379 0.902944 9.99432 0.5 10.4514 0.5H12.3621C13.4595 0.5 14.512 0.974106 15.288 1.81802C16.064 2.66193 16.5 3.80653 16.5 5C16.5 6.19347 16.064 7.33807 15.288 8.18198C14.512 9.0259 13.4595 9.5 12.3621 9.5H10.4083C9.95121 9.5 9.58069 9.09706 9.58069 8.6C9.58069 8.10294 9.95121 7.7 10.4083 7.7H12.3621C13.0205 7.7 13.652 7.41554 14.1176 6.90919C14.5833 6.40284 14.8448 5.71608 14.8448 5C14.8448 4.28392 14.5833 3.59716 14.1176 3.09081C13.652 2.58446 13.0205 2.3 12.3621 2.3H10.4514C9.99432 2.3 9.62379 1.89706 9.62379 1.4ZM4.67483 5C4.67483 4.50294 5.04535 4.1 5.50241 4.1H11.5666C12.0236 4.1 12.3941 4.50294 12.3941 5C12.3941 5.49706 12.0236 5.9 11.5666 5.9H5.50241C5.04535 5.9 4.67483 5.49706 4.67483 5Z"
                      fill="#6C63FF"
                    />
                  </svg>

                  <button
                    onClick={copyToClipboard}
                    className="text-[#6C63FF] text-[16px] font-sans"
                  >
                    Copy payment link
                  </button>
                </div>
              ) : (
                <div className="flex items-center gap-[5px]">
                  <svg
                    width="15"
                    height="10"
                    viewBox="0 0 15 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.5 4.33333C2.00526 4.86667 4.65789 7.66667 5.92105 9L13.5 1"
                      stroke="#8CC63F"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p className="text-green text-[16px] font-sans">
                    Link is copied
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className=" w-full gap-[11px] flex items-center justify-center -translate-x-[99px]  mt-[-55px] z-0 absolute ">
          <svg
            width="207"
            height="229"
            viewBox="0 0 207 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.1"
              d="M33.0359 228.969C21.6248 228.969 10.528 223.054 4.42954 212.452C-4.68676 196.658 0.720151 176.461 16.5008 167.337L107.978 114.483L16.5008 61.6288C0.720151 52.5051 -4.68676 32.3073 4.42954 16.514C13.5458 0.720729 33.7274 -4.69052 49.5081 4.4331L190.496 85.885C200.713 91.7996 207 102.685 207 114.483C207 126.281 200.713 137.229 190.496 143.112L49.5081 224.564C44.3212 227.553 38.6314 229 33.0359 229V228.969Z"
              fill="#6C63FF"
            />
          </svg>
          <p className="text-[#170738] text-[14px]">Powered by</p>

          <Hlogo width={"130px"} height={"30px"} />
        </div>

        <form ref={formRef} method="post" action="">
          <input type="hidden" name="creq" ref={inputRef} value="" />
          <noscript>
            <center>
              <br />
              Please click the submit button below.
              <br />
              <input type="submit" value="Submit" />
            </center>
          </noscript>
        </form>
        <div className="w-full h-[50px] flex justify-center gap-[20px] items-center bg-white  fixed bottom-0">
          <p className="text-center text-[14px]">&copy; Keepz.me - 2024</p>
          <Link
            to="/terms-and-conditions"
            className="text-[14px] cursor-pointer text-[#170738]"
          >
            Terms and conditions
          </Link>
          <Link
            className="text-[14px] cursor-pointer text-[#170738]"
            to="/privacy"
          >
            Privacy policy
          </Link>
          <a
            className="text-[14px] text-[#170738] "
            href={`${window.location.href}SupplementaryContractualProvisions.pdf`}
          >
            Contractual Provisions
          </a>
        </div>
      </div>
    </>
  );
};

export default BogPaymentWeb;
