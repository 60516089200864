import React from "react";
import ApplePayIcon from "assets/icons/ApplePayIcon";
import GooglePayIcon from "assets/icons/GooglePayIcon";
import BinanceIcon from "assets/icons/BinanceIcon";
import CardIcon from "assets/icons/CardIcon";
import DropdownIcon from "assets/icons/DropdownIcon";
import { useTranslation } from "react-i18next";


export default function Select({ onClick, type, style }) {
  let content = null;
  const { t } = useTranslation();
  switch (type) {
    case "apple":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <ApplePayIcon />
          <p>Apple Pay</p>
        </div>
      );
      break;
    case "gpay":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <GooglePayIcon />
          <p>Google Pay</p>
        </div>
      );
      break;
    case "binance":
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <BinanceIcon />
          <p>Binance Pay</p>
        </div>
      );
      break;
    default:
      content = (
        <div className="flex items-center w-full h-full gap-4">
          <CardIcon />
          <p>{t("description.payzePayWithCard")}</p>
        </div>
      );
      break;
  }
  return (
    <div
      className="border border-[eef0f3] px-[14px] w-full h-[40px] flex items-center justify-between"
      style={style}
      onClick={onClick}
    >
      <div className="[&_svg]:h-[32px] [&_svg]:w-[32px]">{content}</div>
      <DropdownIcon />
    </div>
  );
}
