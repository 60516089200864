import React from "react";
import { useTranslation } from "react-i18next";

const STATUSES = [
  "COMPLETED",
  "PRECONDITION_FAILED",
  "ERROR",
  "CANCELLED",
  "FAIL",
  "INTERNET",
  "FORBIDDEN",
  "METHOD_NOT_ALLOWED",
  "CONFLICT",
  "NOT_FOUND",
  424,
  409,
  425,
  429,
  "GONE",
  "UNPROCESSABLE_ENTITY",
];

const PaymentFeedback = ({ type, action, className }) => {
  const { t } = useTranslation();
  let msg = "";
  let title = "";
  let image = null;
  switch (type) {
    case STATUSES[0]:
      title = t("description.transactionComplete");
      msg = t("description.yourTipWasSentToServer");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Path_1451"
            data-name="Path 1451"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-2.245-3.273a1.913,1.913,0,0,1-1.493-.775l-4.12-4.964a1.633,1.633,0,0,1-.431-1.1,1.464,1.464,0,0,1,1.464-1.478,1.551,1.551,0,0,1,1.249.646l3.287,4.075,6.33-10.072a1.512,1.512,0,0,1,1.292-.832,1.464,1.464,0,0,1,1.536,1.392,2.186,2.186,0,0,1-.388,1.076L-.8-4.09A1.73,1.73,0,0,1-2.245-3.273Z"
            transform="translate(15.308 25.884)"
            fill="#9dd838"
          />
        </svg>
      );

      break;
    case STATUSES[1]:
      title = t("description.limitHeader");
      msg = t("description.limitBody");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="Group_12247"
            data-name="Group 12247"
            transform="translate(-212 -332)"
          >
            <circle
              id="Ellipse_610"
              data-name="Ellipse 610"
              cx="15"
              cy="15"
              r="15"
              transform="translate(212 332)"
              fill="#d53943"
              opacity="0.2"
            />
            <g
              id="Group_12247-2"
              data-name="Group 12247"
              transform="translate(-0.5)"
            >
              <line
                id="Line_36"
                data-name="Line 36"
                y2="9"
                transform="translate(227.5 339.5)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
              <line
                id="Line_37"
                data-name="Line 37"
                transform="translate(227.5 354.095)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
            </g>
          </g>
        </svg>
      );

      break;

    case STATUSES[2]:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;
    case STATUSES[4]:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[3]:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[5]:
      title = "Network Problem";
      msg = "Please check your internet connection!";
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;
    case STATUSES[6]:
      title = t("Your VAT number is blocked!");
      msg = t("Contact Keepz support");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;
    case STATUSES[7]:
      title = t("description.limitHeader");
      msg = "Beneficiary personal number reached limit of receiving payments!";
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <g
            id="Group_12247"
            data-name="Group 12247"
            transform="translate(-212 -332)"
          >
            <circle
              id="Ellipse_610"
              data-name="Ellipse 610"
              cx="15"
              cy="15"
              r="15"
              transform="translate(212 332)"
              fill="#d53943"
              opacity="0.2"
            />
            <g
              id="Group_12247-2"
              data-name="Group 12247"
              transform="translate(-0.5)"
            >
              <line
                id="Line_36"
                data-name="Line 36"
                y2="9"
                transform="translate(227.5 339.5)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
              <line
                id="Line_37"
                data-name="Line 37"
                transform="translate(227.5 354.095)"
                fill="none"
                stroke="#d53943"
                stroke-linecap="round"
                stroke-width="3"
              />
            </g>
          </g>
        </svg>
      );

      break;
    case STATUSES[8]:
      title = "დაფიქსირდა კონფლიქტი";
      msg = "თქვენ სარგებლობთ გამოყენებული ორდერით";
      image = image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[9]:
      title = "Order not found";
      msg = t("description.qrCheckError");
      image = image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[10]:
      title = t("description.transactionFailed");
      msg = t("description.gateError");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[11]:
      title = t("description.transactionFailed");
      msg = t("description.limitError");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[12]:
      title = t("description.paymentProgress");
      msg = t("description.tryAgain");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[13]:
      title = t("description.orderPaid");
      // msg = t("descript");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[14]:
      title = t("description.transactionFailed");
      msg = t("description.paymentProgressTwo");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    case STATUSES[15]:
      title = t("description.transactionFailed");
      msg = t("description.limitBodyTwo");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id="Fail"
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );

      break;

    default:
      title = t("description.transactionFailed");
      msg = t("description.pleaseCheckFunds");
      image = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
        >
          <path
            id=" "
            d="M-.308,4.116a15.073,15.073,0,0,0,15-14.993,15.1,15.1,0,0,0-15-15.007,15.085,15.085,0,0,0-15,15.007A15.073,15.073,0,0,0-.308,4.116ZM-5.159-4.549A1.485,1.485,0,0,1-6.652-6.056a1.422,1.422,0,0,1,.445-1.033l3.775-3.773L-6.207-14.65a1.422,1.422,0,0,1-.445-1.033,1.473,1.473,0,0,1,1.493-1.492,1.439,1.439,0,0,1,1.077.43l3.775,3.759,3.789-3.759a1.39,1.39,0,0,1,1.077-.445A1.473,1.473,0,0,1,6.051-15.7a1.369,1.369,0,0,1-.445,1.033l-3.789,3.8L5.592-7.1a1.454,1.454,0,0,1,.445,1.047A1.485,1.485,0,0,1,4.544-4.549a1.482,1.482,0,0,1-1.091-.445L-.308-8.739-4.068-4.994A1.408,1.408,0,0,1-5.159-4.549Z"
            transform="translate(15.308 25.884)"
            fill="#d53943"
          />
        </svg>
      );
      break;
  }

  return (
    <div
      className={`flex flex-col z-50 items-center  justify-center text-center pt-[0.8rem] pb-[0.3rem] ${
        className
          ? "bg-white rounded-[16px] h-[150px] p-[40px] mt-[200px]"
          : null
      }`}
    >
      {image}
      <h2 className="mt-[0.875rem] text-[1.0625rem] leading-[1.375rem] text-black font-bold">
        {title}
      </h2>
      <p className="text-[0.8125rem] mt-1">{msg}</p>
      <button
        className="text-black-500 uppercase font-bold mt-2"
        onClick={action}
      >
        OK
      </button>
    </div>
  );
};

export default PaymentFeedback;
